import React, {useEffect,useState,useContext} from 'react'
import { Box,Typography,Button, Snackbar} from "@material-ui/core";
import Divider from '@mui/material/Divider';
import { AlertDialog } from '../../../componentes/MUI/AlertDialog';
import {FormularioTramite} from '../../FormularioTramite'
import {TramiteContextProvider} from '../contexto/tramiteContext'
import {tramiteContext} from '../contexto/tramiteContext'
import {useTramite} from '../hooks/useTramite'
import { DropzoneArea } from "material-ui-dropzone";
import { SubirArchivo } from '../../../componentes/formulariosFormik/SubirArchivo';
import Axios from 'axios';
import {LoaderCircular} from '../../../componentes/LoaderCircular'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useAlumno} from '../../../Context/alumnoContext'
import { faWindowClose,faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Iglesias} from '../componentes/Iglesias'
import {Encargado} from '../componentes/Encargado'
import {UbicacionInicial} from '../componentes/UbicacionInicial'
import Alert from '@mui/material/Alert';
import moment from 'moment';

export const InicioTramite = ({finalizar})=>{
    const [loadingFile,setLoadingFile] = React.useState(false);
    const {usuario,snackbar} = useAlumno();
    const {campos_formulario,provincias,partidos,ministros} = useTramite();
    const [iglesiaSeleccionada,setIglesiaSeleccionada] = React.useState(null);
    const [iglesias,setIglesias] = React.useState(null);
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario,paso1:null});
    const [continuar,setContinuar]=useState(false);
    const [paso_ubicacion_inicial_ok,set_paso_ubicacion_inicial_ok]=useState(false);
    const [paso_encargado_inicial_ok,set_encargado_inicial_ok]=useState(false);
    const [infoIglesiaMadre,setInfoIglesiaMadre] = React.useState(null)
    const [encargado,setEncargado] = React.useState(null);

    React.useEffect(()=>{
        if(iglesiaSeleccionada){
            const iglesiaInfo = iglesias.filter(item=>item.id_iglesia==iglesiaSeleccionada)
            setInfoIglesiaMadre(iglesiaInfo[0])
        }else{
          setInfoIglesiaMadre(null)
        }
    },[iglesiaSeleccionada])

    const callbackPrevio = async ()=>{

    //setLoadingFile(true)
    
    /*
    // USE ESTE PATRON SI NECESITA VALIDAR ALGO ANTES
        if(!campo_aux)
        {
          setLoadingFile(false)
          return [false,'Falta el campo aux']
        }
    */ 

    /*
    // USE ESTE MODELO SI NECESITA EJECUTAR ALGUNA FUNCION

        const resultadoFuncionX = await funcionX(campo_aux)

        if(!resultadoFuncionX[0])
        {
          setLoadingFile(false)
          return [false,resultadoFuncionX[1]]
        }else{
            setALGO(resultadoFuncionX[1])
        }
      */ 
        
        // setLoadingFile(false)
      

       /*
        // USE ESTA OPCION SI ANTES EJECUTÓ ALGUNA FUNCIÓN QUE HAYA DEVUELTO INFO ADICIONAL
        return [true,{
          CAMPO X:resultadoFuncionX[1],
          //campoY:'Prueba testing',    // AQUI PUEDO ENVIAR TODO TIPO DE DATOS QUE NO ESTAN EN EL
          //campoZ:33                // FORMULARIO PERO QUE DESEAMOS QUE LLEGUEN A LA MUTACIÓN
                                          // O QUE ESTAN EN EL FORM PERO QUEREMOS QUE SE PISEN
        }]
        */

        const validacionEncargado = validarEncargado(encargado);
      
      if(validacionEncargado[0]==false){
          return[false,validacionEncargado[1]]
      }

       return [true,{
        iglesia_madre:infoIglesiaMadre.nombre_igl,
        iglesia_madre_id:infoIglesiaMadre.id_iglesia,
        encargadoEsUad:encargado.encargadoEsUad,
        nombreEncargado:encargado.nombreEncargado,
        id_pastor_uad:encargado.id_pastor_uad
      }]

      // por default devuelva true

       return [true]
    } 

   
    const validarCondicionesIniciales = ()=>{

      if(infoIglesiaMadre){
        if(infoIglesiaMadre?.ubicacion!==undefined){ // compararación estricta para comprobar que vino el campo
                return [true] // null o vacio avanzar porque todo es correcto
        }else{
          return [false,'Falta la ubicación de la iglesia madre']
        }
      }else{
          return [false,'Falta información de la iglesia madre']
      }
    }

    const validarCondicionesInicialesPaso2 = ()=>{

      if(objetoDinamico?.id_region==undefined){
        return [false,'Falta identificar la región del nuevo anexo']
      }

      if(objetoDinamico?.partido?.id==undefined){
        return [false,'Falta identificar el partido del nuevo anexo']
      }

      if(objetoDinamico?.distrito?.id==undefined){
        return [false,'Falta identificar el distrito del nuevo anexo']
      }

      if(objetoDinamico?.provincia?.id==undefined){
        return [false,'Falta identificar la provincia del nuevo anexo']
      }

      return [true]
    }

    const validarCondicionesInicialesPaso3 = ()=>{

      if(encargado?.encargadoEsUad===undefined){ // comparacion estricta (puede ser null)
        return [false,'Falta identificar el tipo de encargado']
      }

      if(encargado?.nombreEncargado==undefined){ // comparacion no extricta ==
        return [false,'Falta identificar el nombre del encargado']
      }

      if(encargado?.id_pastor_uad===undefined){ // comparacion estricta (puede ser null)
        return [false,'Falta identificar el ID del encargado']
      }

      if(encargado?.encargadoEsUad==true && encargado?.id_pastor_uad==undefined){ 
        return [false,'Falta identificar el ID del encargado siendo que tiene credenciales UAD']
      }

      return [true]
    }

    const iniciarContinuar = ()=>{
          
      if(!validarCondicionesIniciales()[0]){
        alert(validarCondicionesIniciales()[1])
        return
      }
      setContinuar(true)
    }

    const iniciarCancelar_paso3 = ()=>{
      setEncargado(null)
      set_paso_ubicacion_inicial_ok(false)
    }

    const iniciarCancelar_paso2 = ()=>{
      setObjetoDinamico({...objetoDinamico,id_region:null,partido:null,provincia:null,id_distrito:null})
      setContinuar(false)
    }

    const iniciarContinuar_paso2 = ()=>{
          
      if(!validarCondicionesInicialesPaso2()[0]){
        alert(validarCondicionesInicialesPaso2()[1])
        return
      }
      set_paso_ubicacion_inicial_ok(true)
    }

    const iniciarContinuar_paso3 = ()=>{
          
      if(!validarCondicionesInicialesPaso3()[0]){
        alert(validarCondicionesInicialesPaso2()[1])
        return
      }
      set_encargado_inicial_ok(true)
    }



    const callbackValidate = async (values)=>{
/*
      if(!campoAdicional)
      {
        return [false,'Mensaje']
      }

      const {data} = await consulteAlgoAlBackend()

      let validado = true;

      const campox = data.campox;

      if(!campox){
        validado = false
      }

      if(!validado){
          return [false,`Verifique...${JSON.stringify(values)}`]
      }
*/


      const validacionEncargado = validarEncargado(encargado);

      if(validacionEncargado[0]==false){
          return[false,validacionEncargado[1]]
      }

      const validacionUbicacion = validarUbicacion(values)

      if(validacionUbicacion[0]==false){
        return[false,validacionUbicacion[1]]
      }

      return [true]

    }

    // esto es para ver los datos del usuario
    /*return <Box>
          {Object.entries(usuario).map(item=>{
              return <p>{item[0]} ---- {item[1]}</p>
          })}
    </Box>
    */

    if (Number(usuario.iglesias)<1){
      return <Box sx={{width:'100%',height:'150px',display:'flex',justifyContent:'center',flexDirection:'column'}}>
          <Cabecera finalizar={finalizar}/>
          <h4>No se encontraron iglesias autónomas a su nombre</h4>
          <p>Se requiere al menos una iglesia autónoma a la cual vincular el nuevo anexo</p>
          <Button style={{marginTop:'15px'}} variant="outlined" color='primary' onClick={finalizar}>Entendido</Button>
      </Box>  
    }

    if (Number(usuario.iglesias)>0 && !iglesiaSeleccionada){
      return <Box sx={{width:'100',display:'flex',justifyContent:'center',flexDirection:'column'}}>
      <Cabecera finalizar={finalizar}/>
      <Typography style={{marginTop:'25px'}} variant="h6">1  - Seleccione la iglesia madre </Typography>
      <Iglesias usuario={usuario} 
                    iglesiaSeleccionada = {iglesiaSeleccionada} 
                    setIglesiaSeleccionada={setIglesiaSeleccionada}
                    setIglesias={setIglesias}/>
      {/*iglesiaSeleccionada && <Button style={{marginTop:'15px'}} variant="outlined" color='primary' onClick={()=>setMinistroSolicitud(dependienteSeleccionado)}>PARA EL DEPENDIENTE SELECCIONADO</Button>*/}

    </Box>  
    }

    /*
    FUE NECESARIO agregar un flag para que luego de seleccionar el ministroSolicitud
    no renderice inmediatamente el componente FormularioTramite.
    Sucedía que apenas se seleccionaba un ministroSolicitud se renderizadaba el formik
    antes de que se actualice el objeto objetoDinamico con la información dinámica que se necesita
    del contexto para poder cargar correctamente los selects de status, region, distrito y ministro.
    Se usa un flag de "Continuar" para que renderice algo antes y de tiempo a que se ejecuten los
    useEffects de éste componente y se actualice el objetoDinmico antes de renderizar el formik.
    El flujo es, primero se renderiza, luego se ejecutan los useEffects entonces hay situaciones en que
    hay que implementar lógica para que cada acción sea en el momento adecuado.
    En este caso 1) seleccionar un ministroSolicitud, luego actualizar objetiDinamico luego renderizar
    el formulario formik con el contexto dinámico correcto.
    Sucedía que primero montaba el formulario, renderizaba el mismo, ejecutaba un useEffect del formik
    y luego procesaba los useEffects del componente InicioTramite y quedaba mal sincronizado el proceso.
    Pensaba que era un problema de closures con las funciones allowedValues de campos_formuularios
    pero no era un closure sino un problema de renderizado antes de tiempo del componente.
    Se resolvió con un flag y un componente de confirmación previo.
    */
    if(iglesiaSeleccionada && continuar==false){

        if(!infoIglesiaMadre){
            return <Box>
                <Cabecera finalizar={finalizar}/>
                <Alert variant="filled" severity="warning">
                    No se encontraron los datos de la iglesia madre.
                </Alert>
                <Button style={{background:'tomato'}} variant="outlined" onClick={()=>setIglesiaSeleccionada(null)}>
                          cancelar
                </Button>
            </Box>
        }

        return <Box>
            <Cabecera finalizar={finalizar}/>
            <Typography style={{marginTop:'25px'}} variant="h6">2  - Confirme la iglesia madre </Typography>
            <Box sx={{margin:'5px',padding:'5px'}}>
                <h4>Iglesia seleccionada: <span className='fw-400'>{infoIglesiaMadre.nombre_igl} ubicada en {infoIglesiaMadre.ubicacion}</span></h4>
            </Box>
            {/*<Alert variant="filled" severity="info" sx={{background:'#0288d1'}}>
                Recuerde que la iglesia madre debe estar al día con sus diezmos y balances
            </Alert>*/}
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                <Button variant='outlined' color='primary' onClick={iniciarContinuar}>Confirmar</Button>
                <Button style={{background:'tomato'}} variant="outlined" onClick={()=>setIglesiaSeleccionada(null)}>
                      cancelar
                </Button>
            </Box>
        </Box>
    }

    /*if(continuar1==false){
      return <>
      <UbicacionInicial usuario={usuario} 
        finalizar={finalizar} 
        objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
      />
       <Button variant='outlined' color='primary' onClick={()=>setContinuar1(true)}>Continuar</Button>
                <Button style={{background:'tomato'}} variant="outlined" onClick={()=>setContinuar(false)}>
                      cancelar
                </Button>
      </>
    }*/

    /*
    if(continuar2==false){
      return <div>
                <Encargado 
        setEncargado={setEncargado} 
        finalizar={finalizar}/>
                <Button variant='outlined' color='primary' onClick={()=>setContinuar2(true)}>Continuar</Button>
                <Button style={{background:'tomato'}} variant="outlined" onClick={()=>setContinuar(false)}>
                      cancelar
                </Button>
      </div>  
      
    }
    */

   /* if(objetoDinamico?.paso1==true && encargado?.nombreEncargado!=null){
      return <div>
                {JSON.stringify(encargado)}
                {JSON.stringify(objetoDinamico)}
      </div>  
      
    }*/
/*
    if (encargado?.nombreEncargado!=null && objetoDinamico?.paso1==true){
      return <div>
                <p> {JSON.stringify(encargado)}</p>
                <p> {JSON.stringify(objetoDinamico)}</p>

      </div>
      }
      */

     if (!paso_ubicacion_inicial_ok){
        return <>
            <Cabecera finalizar={finalizar}/>
            <Typography style={{marginTop:'25px'}} variant="h6">3  - Seleccione la provincia y el partido en donde se ubica el anexo</Typography>
             <UbicacionInicial 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  provincias={provincias}
                  partidos={partidos}
                  paso_ubicacion_inicial_ok={paso_ubicacion_inicial_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
              <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                {validarCondicionesInicialesPaso2()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso2}>Continuar</Button>}
                <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso2}>
                      cancelar
                </Button>
            </Box>
        </>
     }

     if (!paso_encargado_inicial_ok){
      return <>
           <Cabecera finalizar={finalizar}/>
           <Typography style={{marginTop:'25px'}} variant="h6">4  - Identifique quién estará a cargo del anexo </Typography>
           <Encargado
                  setEncargado={setEncargado} 
                  finalizar={finalizar}
                  ministros={ministros}
                  paso_encargado_inicial_ok = {paso_encargado_inicial_ok}
                  encargado={encargado}/>
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
            {validarCondicionesInicialesPaso3()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso3}>Continuar</Button>}
              <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso3}>
                      cancelar
              </Button>
          </Box>
      </>
   }

     if(continuar==true && paso_ubicacion_inicial_ok && paso_encargado_inicial_ok){
      return <TramiteContextProvider>
      <Box className='form-body-tram' sxxxxxx={{marginBottom:'25px',background:'#b3b0b0', padding:'20px', marginTop:'20px'}}>
           <Box>
           <Cabecera finalizar={finalizar}/>
           <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
            {/*<h1>UBICAR AQUÍ ALGUN formulario auxiliar al formik</h1>*/}
            {loadingFile && <LoaderCircular/>}
            <Box>
                <h4>Iglesia madre: <span className='fw-400'>{infoIglesiaMadre.nombre_igl} ({infoIglesiaMadre.ubicacion})</span></h4>
            </Box>
              {/* La condición objetoDinamico?.paso_1 es para que se dispare primero la ubicación y luego el encargado */}
              <UbicacionInicial 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  provincias={provincias}
                  paso_ubicacion_inicial_ok={paso_ubicacion_inicial_ok}
                  partidos={partidos}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
              <Encargado
                  setEncargado={setEncargado} 
                  finalizar={finalizar}
                  paso_encargado_inicial_ok = {paso_encargado_inicial_ok}
                  ministros={ministros}
                  encargado={encargado}/>

              {<FormularioTramite finalizar={finalizar}  // función que se ejecuta al cancelar o terminar de guardar
                                 callbackPrevio = {callbackPrevio} // callback que se ejecuta antes de guardar y puede modificar o agregar campos que se envían a la mutation. Si están en el formik se van a pisar, si no se agregan
                                 objetoDinamico={objetoDinamico} // este objeto sirve para pasar valores por default a campos del formulario y también para pasar un contexto particular del trámite para que llegue esa info a los inputs que se van creando según la configuración del objeto json de campos del formulario
                                 campos_formulario={campos_formulario}
                                 textoSumbit="Enviar solicitud de nuevo anexo"
                                 callbackValidatePlus={callbackValidate} // callback de validación adicional custom
                                 useTramite = {useTramite}/>}
          </Box>
      </Box>
</TramiteContextProvider>
     }

   
}




const createId = ()=>{
  const d = new Date();
  let dy = d.getDate()< 10 ? `0${d.getDate()}`: d.getDate();
  let mt = d.getMonth() < 10 ? `0${d.getMonth()}`: d.getMonth();
  let yr = d.getFullYear();
  let hs = d.getHours() < 10 ? `0${d.getHours()}`: d.getHours();
  let mn = d.getMinutes() < 10 ? `0${d.getMinutes()}`: d.getMinutes();
  let sc = d.getSeconds() < 10 ? `0${d.getSeconds()}`: d.getSeconds() ;

  const id = `${yr}${mt}${dy}${hs}${mn}${sc}`
  return id
}

const consulteAlgoAlBackend = ()=>{
    return new Promise((exito,rechazo)=>{

      setTimeout(() => {
        if(1<2){
          exito({data:{campox:59}})
        }else{
          rechazo()
        }
      }, 2000);
       
    })
}


const SeleccioneAlgo = ({setAlgoSeleccionado})=>{
    return <select onChange={(e)=>setAlgoSeleccionado(e.target.value)}>
        <option value="algo">algo</option>
        <option value="algo2">algo 2</option>
        <option value="algo3">algo 3</option>
    </select>
}

const validarEncargado = (encargado)=>{

  if(!encargado){
      return [false,`Falta ingresar el encargado del anexo`]
  }else{
    if(encargado?.esUad==true && !encargado?.id_pastor_uad){
        return [false,`Falta identificar el ID del ministro UAD encargado del anexo`]
    }
    if(!encargado?.nombreEncargado){
      return [false,`Falta ingresar el nombre del encargado del anexo`]
    }
  }
  return true
}

const validarUbicacion = (values)=>{

  if(!values?.partido?.id){
      return [false,`Falta identificar el partido`]
  }

  if(!values?.provincia?.id){
    return [false,`Falta identificar la provincia`]
  }

  if(!values?.distrito?.id){
    return [false,`Falta identificar el distrito`]
  }

  if(!values?.id_region){
    return [false,`Falta identificar la región`]
  }

  return true
}

const Cabecera = ({finalizar})=>{
  return <>
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'25px'}}>
      <Typography variant="h5">Solicitud de un nuevo anexo</Typography>
      <div className='cursor-pointer text-center ml-2' title='Cerrar' onClick={finalizar}>
            <FontAwesomeIcon className="color-red text-xlarge"  icon={faTimesCircle}/>
      </div>
    </Box>

    <div style={{position:'absolute',top:'0',left:'0',width:'100%'}}>
      
  </div>
 </>
}