import React, {useState, useEffect,useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Main from '../componentes/Main';
import {Link, json} from 'react-router-dom'
import Qrcode from 'qrcode.react';
import Axios from 'axios';
import Logo from '../componentes/Logo'
import Loading from '../componentes/Loading';
import {useAlumno} from '../Context/alumnoContext';
import {estatutos,compromiso,doctrinas} from '../Helpers/jsons'
import {FormGroup,FormControlLabel , Grid, Box ,Typography,Button,Checkbox,makeStyles,Card,Chip} from "@material-ui/core";
import {hacerScroll} from '../Helpers/utilidades-globales'
import {CssBaseline,AppBar,Toolbar,Drawer,Divider,List,ListItem,ListItemIcon,ListItemText} from "@material-ui/core";
import MailIcon  from '@mui/icons-material/Mail';
import InboxIcon  from '@mui/icons-material/Inbox';
import PrintIcon  from '@mui/icons-material/Print';
import {ListItemButton} from '@mui/material'
import {Alert} from '@mui/material'
//import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2';
import { imprimir } from '../impresiones/compromiso'
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const drawerWidth = 240;


export default function Aceptaciones1raCredencial ({tipo,
                                                    instancia,
                                                    estado,
                                                    update,
                                                    finalizar,
                                                    nombreDocumento,objLecturas}){

const [url,setUrl] = useState(null);
const [archivos, setArchivos]= useState([])
const [status, setStatus]= useState(false)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const {confirmarCargandoVista,verMenu,parametros,obtenerObrero} = useAlumno()
//const [ultimaLectura,setUltimaLectura] = useState(1)
const [ultimaLectura,setUltimaLectura] = useState(objLecturas?.lecturas[`${instancia}-${tipo}`] || 1 )
const [statuscmin,setStatuscmin] = useState({})
const [contadorOperaciones,setContadorOperaciones] = useState(null)
const [compromisoActualConfirmado,setCompromisoActualConfirmado] = useState(false)
const [documento,setDocumento] = React.useState(null)
//const history = useHistory()
const navigate = useNavigate()


const useStyle = makeStyles({
    tituloGrande: {
        marginTop: '2rem',
        marginBottom:'2rem'
    },
    titulo:{
        marginTop:'1.5rem',
        marginBottom:'1.5rem'
    },
    punto:{
        marginTop:'1rem',
        marginBottom:'1rem'
    }
  });
  const classes = useStyle();

useEffect(()=>{
    setDocumento(identificarDocumento())
    setCompromisoActualConfirmado(false)
},[contadorOperaciones])

useEffect(()=>{
   
   const objaux = {...objLecturas?.lecturas};
   objaux[`${instancia}-${tipo}`] = ultimaLectura;
   const funcionUpdate = objLecturas?.setLecturas;
   if(funcionUpdate){
        funcionUpdate({...objaux})
   }

},[ultimaLectura])

const confirmarestatutos = async ()=>{
    update(tipo,true)
    finalizar()
}

const identificarDocumento = ()=>{
    switch (tipo){
        case 'compromiso':
            return compromiso
        case 'doctrina':
            return doctrinas;
        case 'estatutos':
            return estatutos;
        default : return null
    }
}


    if(!documento){
        return <Main center><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    }

    if(buscandoDatos || !statuscmin){
        return <Main center><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    }


/*
    if(estatutos.version!=statuscmin.id_ultimo_cm)
    {
        return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={3}>
            <Alert variant="filled" severity="warning">
               {`La versión (${versionFomarmateada(estatutos.version)}) del presente compromiso ministerial no coincide con la última versión publicada por la sede central (${statuscmin.id_ultimo_cm}) `}
            </Alert>
        </Grid>   
         
      </Grid> 
    }
*/
return <Grid container>
    {/*<Grid item xs={12} sm={2}>
        <Box sx={{position:'sticky',top:'0',maxWidth:'150px',display: { xs: "none", sm: "none", md:'block' }}}>
                <p style={{marginBottom:'1rem'}}>Índice:</p>
                {estatutos.secciones
                    .map((item,index)=><>
                        <p style={{marginTop:'1rem',cursor:'pointer'}} onClick={()=>hacerScroll(`tit-0${index}`)} >
                            {item.titulo.slice(item.titulo.indexOf('.',0)+1).replace(':','')}
                        </p>
                    </>)}
                    <BotonImprimir compromiso={estatutos}/>
        </Box>
    </Grid>*/}
    <Grid item xs={12} >
    <Box sx={{padding: document.documentElement.clientWidth < 482 ? '0.2rem':'2rem'}}>
        {documento && <BotonImprimir documento = {documento}/>}

        {/*
            <> 
        <p>Ultima versión publicada: {versionFomarmateada(statuscmin.id_ultimo_cm)}</p>
        <p>Ultima versión aceptada: {statuscmin.id_ultimo_cm_aceptado ? `${statuscmin.id_ultimo_cm_aceptado}.0` : 'Ninguna' }</p>            
            </>
        */}

        <Box sx={{textAlign:'center'}} />
            {!compromisoActualConfirmado && <div className='mt-4'>
            {instancia == 'lectura' && <Alert variant="outlined" severity="error">
                El presente documento es de solo lectura. El mismo se encuentra a su disposición para que pueda leerlo y/o imprimirlo. En esta instancia no se pedirá que lo acepte sino solamente que tome conocimiento de su contenido.
            </Alert>}
            {instancia == 'aceptacion' && <Alert variant="outlined" severity="error">
                El presente documento debe ser leído hasta el final y aceptado marcando los casilleros correspondientes a cada sección al final del documento. Para presentar la solicitud de credenciales es requisito haber leído y aceptado el contenido del presente documento. 
            </Alert>}
            </div>}
           <Typography className={classes.titulo} variant='h5'>{documento.titulo}</Typography>
           <Typography className={classes.titulo} variant='body1'>{documento.observaciones}</Typography>
           <Typography variant='body1'>Versión: {versionFomarmateada(documento.version)}</Typography>
           <Typography variant='body1'>Válido a partir del {documento.fecha}</Typography>
           <Typography variant='h6'>{documento.subtitulo}</Typography>
        <Box />
            {documento.secciones
                    .filter((item,index)=>index<ultimaLectura || estado!=undefined || tipo=='estatutos' || tipo=='doctrina')
                    .map((item,index)=><Card key={uuidv4()} style={{background:'#F5F5F5',borderRadius:'10px',padding:'0.5rem',marginBottom:'0.5rem'}} variant="outlined">
                        <Typography variant="h5" className={classes.tituloGrande}>{item.tituloGrande}</Typography>
                        <Typography id={`tit-0${index}`} variant='h5' className={classes.titulo}>{item.titulo}</Typography>
                        <Typography variant='h6'>{item.subtitulo}</Typography>
                        {item.puntos.map(punto=><div key={uuidv4()}>
                            <Typography  className={classes.punto} variant='body1'><span style={{fontWeight:'700'}}>{punto.id ? `${punto.id} - ` : ''} </span><span style={{whiteSpace:'break-spaces'}}>{punto.texto}</span></Typography>
                        </div>)}
                    </Card>)}

        {estado==undefined && ultimaLectura<documento.secciones.length && tipo=='compromiso' && <Box sx={{marginTop:'2rem',display:'flex', justifyContent:'center',flexDirection:''}}> 
        <div>
                {documento.secciones.length - ultimaLectura != 1 && <p style={{textAlign:'center',padding:'5px'}}>Restan ({documento.secciones.length - ultimaLectura}) secciones</p>}
                {documento.secciones.length - ultimaLectura == 1 && <p style={{textAlign:'center',padding:'5px'}}>Resta (1) sección</p>}
                <Button variant="contained" color='primary' size='large'  onClick={()=>setUltimaLectura(ultimaLectura+1)}>
                    <Box>
                        <Typography variant='body1'>Continuar a la sección:</Typography>
                        <Typography variant='body1'>{documento.secciones[ultimaLectura].titulo}</Typography>
                    </Box>                            
                </Button>   
        </div>
         
        </Box>}
    </Box>
        {(instancia=='aceptacion' && (estado!=undefined || ultimaLectura==documento.secciones.length || tipo!='compromiso')) 
        && <Confirmacion tipo={tipo} update={update} estado={estado} 
                secciones={documento.secciones} confirmar={confirmarestatutos}
                nombreDocumento={nombreDocumento}/>
        }
    </Grid>
</Grid>

    return     <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar
      position="fixed"
      sx={{ width: "100px", ml: `${drawerWidth}px` }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Permanent drawer
        </Typography>
      </Toolbar>
    </AppBar>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
    >
      <Toolbar />
      <Typography paragraph>
      Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.      </Typography>
      <Typography paragraph>
      Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.      </Typography>
    </Box>
  </Box>




}

const Confirmacion = ({secciones,confirmar,estado,update,tipo,nombreDocumento})=>{

    const [elementosSeleccionados, setElementosSeleccionados]= useState({})
    const [aceptado, setAceptado]= useState(null)
    const [flagMounted, setFlagMounted]= useState(null)

    useEffect(()=>{
        if(estado==true){ // si el documento viene aceptado desde el formulario entonces marco todos los casilleros por medio del objeto elementoSeleccionados que está mapeado con los checkboxes 
            let objaux = {}

            secciones.map((item,index)=>{
                objaux[`sec-${index}`]=true;
            })

            setElementosSeleccionados(objaux)
            setAceptado(true)
        }

        setFlagMounted(true)
    },[])

    useEffect(()=>{

        const arrayObjetosSeleccionados = Object.entries(elementosSeleccionados)

        const arrayVerificador = arrayObjetosSeleccionados.filter(item=>item[1]==true)

        const resultado = arrayVerificador.length == secciones.length;

        // si la cantidad de secciones no es igual a la cantidad de casilleros marcados entonces se cambia el estado del documento en el formulario pasándolo a falso
        // Fue necesario utilizar un flag porque no debe hacerse la validación cuando se monta el componente
        // dado que en el evento inicial se modifica el objeto elementosSeleccionados para dejar marcados
        // todos los casilleros cuando el documento ha sido aprobado.
        // Si no utilizara el flag en el evento inicial cuando se carga el objeto elementosSeleccionados 
        // detectaría que en los primeros casos no se cumple que sean igual los casilleros marcados y las secciones
        // por eso debe ejecutarse el update a false del documento solo cuando ya han sido cargados
        // todos los casilleros y es el usuario que desmarca alguno. Si desmarca al menos uno ya queda como no aceptado (false)
        if(flagMounted){
            if(resultado!=true){
                 setAceptado(null)
                 update(tipo,false)
             }
        }

    },[elementosSeleccionados])

    const handleCheck = (e)=>{
        // cada vez que se marca o desmarca un casillero se actualiza el objeto elementosSeleccionados para saber si se marcaron todas las secciones o no
        if(e.target.checked){
            setElementosSeleccionados({...elementosSeleccionados,[e.target.name]:true})
        }else{
            setElementosSeleccionados({...elementosSeleccionados,[e.target.name]:false})
        }
    }

    const nroElementosOK = ()=>{

        if (tipo=='compromiso'){
            // Cuando la cantidad de casilleros marcados es igual a la cantidad de secciones entonces se habilita el boton de aceptar
            const arrayObjetosSeleccionados = Object.entries(elementosSeleccionados)

            const arrayVerificador = arrayObjetosSeleccionados.filter(item=>item[1]==true)

            const resultado = arrayVerificador.length == secciones.length;

            return resultado
        }else{
            return true
        }
       

    }

    return <Box sx={{marginTop:'2rem',display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center '}}> 
    {tipo=='compromiso' && <h4>Para finalizar por favor confirme que ha leido todas las secciones del documento marcando la casilla correspondiente:</h4>}
    {tipo!='compromiso' && <h4>Para finalizar por favor confirme que ha leido todas las secciones del documento y que acepta las declaraciones del mismo:</h4>}
    <Grid container style={{maxWidth:'500px'}}>
        {tipo=='compromiso' && <Grid item xs={12} >
            <Box>
                {secciones.map((item,index)=><Box key={uuidv4()}><FormGroup>
                    <FormControlLabel control={<Checkbox checked={elementosSeleccionados[`sec-${index}`]==true} onChange={handleCheck} name={`sec-${index}`}/>} label={item.titulo}/>
                </FormGroup> 
                </Box>)}
            </Box>
        </Grid>}
        <Grid item xs={12}>
           
            {aceptado==true && <Alert variant="outlined" severity="info">
                El documento se encuentra aceptado
            </Alert>}

            {aceptado!=true && <Alert variant="outlined" severity="error">
                Falta aceptar el presente documento. {tipo=='compromiso' && <span>Debe marcar todos los casilleros para habilitar el botón ACEPTAR</span>}
            </Alert>}

            {aceptado==undefined && <Button onClick={confirmar} style={{marginTop:'1rem',marginBottom:'1rem'}} disabled={!nroElementosOK()} variant="contained" color='primary' size='large'>
                 He leído y acepto el presente documento de {nombreDocumento}
            </Button>}
        </Grid>
    </Grid>
</Box>
}

const versionFomarmateada = (version)=>{
    return `${version}.0`
}

const BotonImprimir = ({documento})=>{
    return  <Box  sx={{margin:'1rem'}}><Button onClick={()=>imprimir(documento,`Compromiso ministerial versión ${documento.version}-0`,`Compromiso ministerial versión ${documento.version}-0`)} variant="outlined" startIcon={<PrintIcon />}>
    Imprimir
</Button></Box>
}