import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle,faChartBar } from '@fortawesome/free-regular-svg-icons';
import {Link} from 'react-router-dom'
import Axios from 'axios';
import Grafico from './Grafico';
import Loading from '../componentes/Loading';
import Main from './Main';

export default function Diezmos ({id_iglesia}){

const [datos,setDatos] = useState([])
const [buscandoDatos,setBuscandoDatos] = useState(false)
const [verGrafico,setVerGrafico] = useState(false)

useEffect(()=>{
    const obtenerDatos = async ()=>{
        setBuscandoDatos(true)
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/mesesdiezmados/${id_iglesia}`)

            setDatos(data)
            setBuscandoDatos(false)

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }
    obtenerDatos()               
},[])

    if (buscandoDatos){
        return <Main center><div><Loading/><span className="cargando">Buscando diezmos...</span></div></Main>
    };    

    return (
        <div>
            <div className="flex f-col mb-4 items-center">
                <div className="flex f-row items-center">
                    <p className="mb-4 mt-4">DIEZMOS</p>
                    {/*<span onClick={()=>{setVerGrafico(!verGrafico)}} title={`Ver el gráfico de diezmos`} className="cursor-pointer ml-4" >
                                            <FontAwesomeIcon className="cursor-pointer"  icon={faChartBar}/> <span className="text-xxsmall">Ver gráfico</span>
                    </span>*/}
                </div>
                <div className="flex f-col">
                    {[...datos].reverse().map(item=><span className={item.diezmo ==0 ? 'bal-np' : 'bal-pr'}>{item.periodo}</span>)}
                </div>
            </div>
            {verGrafico && <Grafico vector={[...datos]}/> }
        </div>
    )
}


 