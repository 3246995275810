import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import {useAlumno} from '../../Context/alumnoContext';
import {hacerFoco} from '../../Helpers/utilidades-globales';

export const CustomSnackBar = ()=> {
  const {mostrarSnackBar,setMostrarSnackBar} = useAlumno()

  const handleClose = () => {
    setMostrarSnackBar(null);
    if(typeof(mostrarSnackBar[3])=='string'){
        hacerFoco(mostrarSnackBar[3])
    }
  };

  const message = !mostrarSnackBar ? '' : mostrarSnackBar[1];
  let string = false;
  let array = false;

  if (typeof message === 'string'){
    string = true;
  }else{
    if(Array.isArray(message)){
        if(message.length>0){
          array = true
        }
    }
  }

  if (!mostrarSnackBar) return null

  return <Backdrop
    style={{ background: 'white',color:'white', zIndex: 20000}} // el z-index alto es para que quede encima del backdrop del modal del dialog, lo mismo sucede con los modales de sweet alert
    open={mostrarSnackBar[0]}>
        <div id="snackbarprincipal" className='snackbarcustomx' style={{background:'tomato',border:'solid 1px white'}}>
              <Snackbar autoHideDuration={8000} onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                style={{top:'45%',minWidth:'300px'}}
                id="mi-snackbar"
                open={true}
                //open={mostrarSnackBar[0]}
                message="I love snacks"
              > 
                <Alert variant="filled" onClose={handleClose} severity='warning' sx={{ width: '100%',background:'tomato' }}>
                    {string && mostrarSnackBar[1]}
                    {array && message.map(item=><div>{item}</div>)}
                </Alert>
              
              </Snackbar>
            </div>
    </Backdrop>
    
    return (
      <div id="snackbarprincipal" className='snackbarcustomx' style={{background:'tomato'}}>
        <Snackbar autoHideDuration={8000} onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
          id="mi-snackbar"
          open={mostrarSnackBar[0]}
          message="I love snacks"
        >
          <Alert variant="filled" onClose={handleClose} severity={mostrarSnackBar[2] || 'warning'} sxx={{ width: '100%',background:'tomato' }}>
            {string && mostrarSnackBar[1]}
            {array && message.map(item=><div>{item}</div>)}
          </Alert>
        </Snackbar>
      </div>
    );
  
}