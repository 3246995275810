import React, {useContext,useEffect,useState} from 'react'
import {TextField, FormHelperText, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from "@mui/material/Autocomplete";

/*
Ejemplo de autocomplete.
El array de opciones espera tener un id y un label

 <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={[{id:1,label:'pepe'},{id:2,label:'chocho'}]}
      onChange={(event, value) =>handleChangeTipoSalida(event)}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Movie" />}
    />
*/

export const MuiAutocomplete = ({item,
                                props,
                                matrizDatos,
                                setMatrizDatos,
                                eliminar,
                                esAtributoOpcional,
                                setFieldValue,
                                objetoDinamico})=>{
    const {values,errors,handleChange,dirty} = props;

    const [datos,setDatos]=useState([])

    React.useEffect(()=>{

        const matrizDatosPorName = matrizDatos.filter(row=>row.key==item.name)

   // En matrizDatos guardamos el vector correspondiente a cada campo select de un formulario
   // por ejemplo colores, categorias...etc.
   // El propósito es no cargarlo 2 veces. 
   // Si ya se cargó no lo traemos del back otra vez (para evitar un request cada vez que hay un render)

     
        if(matrizDatosPorName.length==0){
         // Si el campo no se encontró en matrizDatos lo buscamos del backend si item.allowed_values es una función o si es un array cargamos directamente los valores del array 
             if(typeof(item.allowed_values)=='function'){
               item.allowed_values(props,objetoDinamico) // le paso props porque es posible que la funcion necesite consultar alguna prop del formulario
               .then(listado=>{
                       if(item?.dependeDe==undefined){ // si el campo tiene alguna dependencia se debe cargar cada vez que cambia su dependiente por eso no almacenamos en "memoria" 
                           setMatrizDatos([...matrizDatos,{key:item.name,data:listado}])
                       }
                   setDatos(listado)
               })
             }else if(Array.isArray(item.allowed_values)){
                 const listado = item.allowed_values.map(item=>Object.values(item))

                 setMatrizDatos([...matrizDatos,{key:item.name,data:listado}])
                 setDatos(listado)
             }
        }else{
             // Si el campo se encontró en matrizDatos usamos los datos que se habían traído del back la primera vez 
             setDatos(matrizDatosPorName[0].data)
        }
       
   },[item.name,props.values[item.dependeDe]]) // algunos campos del formulario están configurados con algún otro campo dependiente, si cambia su valor en el formulario debe volver a cargarse


    const filterOptions = createFilterOptions({
        stringify: (option) => quitaracentos(option.label)
    });

    function quitaracentos(cadena)
{
	var chars={
		"á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
		"à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
		"Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
		"À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
	var expr=/[áàéèíìóòúùñ]/ig;
	var res=cadena.replace(expr,function(e){return chars[e]});
	return res;
}

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    const customHandleChange = (e,value)=>{
        if(value){
            props.setFieldValue(item.name,value.id)

            if(item.guardarTextoEnCampo){ // Si el campo del formulario tiene configurado un campo texto en donde guardar su descripcion
                // no siempre es necesario pero hay casos en los que hace falta guardar en el objeto a enviar al backend la descripcion de cada campo seleccionado no solo el id
            const filaSeleccionada = datos.filter(item=>item[0]==value.id)
            const descripcion = filaSeleccionada[0][1] // tomo la descripcion del campo 1 de la fila cero
            props.setFieldValue(item.guardarTextoEnCampo,descripcion)
            }
        }else{
            props.setFieldValue(item.name,'')
            props.setFieldValue(item.guardarTextoEnCampo,'')
        }
    }

    if(datos.length==0){
        return <p>Buscando ministros...</p>
    }

 
    return    <Box sx={{display:'flex',flexDirection:'column',alignItems:'centerx',textAlign:'left' }}>
     <Autocomplete
    disablePortal
    fullWidth
    filterOptions={filterOptions} // se utiliza para manipular los campos de búsqueda
//    value = {values[item.name]!='' ? datos.filter(item=>item[0]==values[item.name]).map(item=>item[1])[0] : ''}
    value = {datos.filter(item=>item[0]==Number(values[item.name])).map(item=>item[1])[0]}
    //value={egreso.id_tipo_salida>0 ? tiposSalida.filter(item=>item.id_tipo_salida==egreso.id_tipo_salida)[0].nombre : ''}
    options={datos.map(item=>{return {id:item[0],label:item[1]}})}
    onChange={(event, value) => customHandleChange(event,value)}
    sx={{ width: 300 }}
    renderInput={(params) => <TextField {...params} label={values[item.name]==''? `${item.label}`:item.label} />}
    />
    {dirty && <FormHelperText className="Mui-error">{ errors[item.name] ? errors[item.name] : ""}</FormHelperText>}

</Box>

 }

