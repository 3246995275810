
import React from 'react'
import {TextField } from "@material-ui/core";


export const MuiDate = ({item,props,snackbar,objetoDinamico,usuario})=>{

    const {values,touched,errors,setFieldValue,handleChange,handleBlur} = props;
  
    return <React.Fragment>
          <br></br>
          <br></br>
          <TextField variant="outlined" label={item.label} 
          type="date"
        // onKeyPress={(e)=>{e.preventDefault();return false}}
          onChange={(event) => {
            if (item.disabled) return  
            if (event.target.value === '' || ((item.REG_EX && item.REG_EX.test(event.target.value)|| !item.REG_EX))) {
                item.onChange ? item.onChange(event,handleChange,setFieldValue,values) : handleChange(event);
            }
          }}
          key={`date-${item.name}`}
          onBlur={(event)=>{
                item.onBlur ? item.onBlur(event,handleBlur,snackbar) : handleBlur(event)
          }}
          value={values[item.name]} // siempre debe venir en formato yyyy-mm-dd
          style={{marginRight: '20px'}}
          InputLabelProps={{
            shrink: true,
          }}
          name={item.name}
          error={errors[item.name] && touched[item.name]}
          helperText={errors[item.name] && touched[item.name] ? errors[item.name] : ""}
          />
    </React.Fragment>

}