import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {useAlumno} from '../../../Context/alumnoContext'
import REG_EX from '../../../Helpers/regex'
import { TryRounded } from '@mui/icons-material';
import {Fecha} from '../../../componentes/MUI/Fecha';

export const Cuestionario2 = ({objetoModificacion,paso_cumplido,validacion,validar})=>{
    
    //objetoModificacion es {obj,set}

    const [cargando,setCargando] = useState(true);
    const {snackbar} = useAlumno();
    const [avisar,setAvisar] =useState({});
    const [fecha_conversion,set_fecha_conversion]=useState(objetoModificacion?.obj?.fecha_conversion || null)
    const [fecha_bautismo_a,set_fecha_bautismo_a]=useState(objetoModificacion?.obj?.fecha_bautismo_a || null)
    //const [fecha_bautismo_es,set_fecha_bautismo_es]=useState(objetoModificacion?.obj?.fecha_bautismo_es || null)

    
const checkvalidacion = (campo,validacion)=>{

    if (!validar) return ''

    const objetoValidacion = validacion(validar)
    const resultado = objetoValidacion[0];
    const texto = objetoValidacion[1]
    const campos = objetoValidacion[2]

    if(resultado==true || !(campos instanceof Array) || campos.length==0){
        return ''
    }
    if(campos.some(item=>item.campo==campo)){
        return campos.filter(item=>item.campo==campo)[0]?.texto || ''
    }else{
        return ''
    }
}

return <Box sx={{marginTop:'20px'}}>
        <Grid container>
            <Grid item xs={12}  style={{textAlign:'center'}}>
                    <FormControl>
                        <FormLabel style={{marginTop:'30px',marginBottom:'20px'}}>¿Confirma que ha leido atentamente y descargado el archivo de la declaración de afiliación? <span className='c-obl'></span></FormLabel>
                        <RadioGroup
                            value={objetoModificacion.obj.descarga}
                            onChange={(e)=>{
                                    objetoModificacion.set({...objetoModificacion.obj,descarga:e.target.value})
                            }}>
                                <Box sx={{display:'flex',justifyContent:'center'}}>
                                    <FormControlLabel value="SI" control={<Radio />} label="Si" />
                                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                                </Box>
                        </RadioGroup>
                    </FormControl>
                    <FormHelperText className="Mui-error">{checkvalidacion('descarga',validacion)}</FormHelperText>
            </Grid>
        </Grid>
</Box>

}






