import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {useAlumno} from '../../../Context/alumnoContext'
import REG_EX from '../../../Helpers/regex'
import { TryRounded } from '@mui/icons-material';
import {Fecha} from '../../../componentes/MUI/Fecha';
import DeleteIcon from '@mui/icons-material/Delete';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircleIcon from '@mui/icons-material/Circle';

export const Referencias = ({objetoModificacion,paso_cumplido,validacion,validar})=>{
    
    //objetoModificacion es {obj,set}

    const [cargando,setCargando] = useState(true);
    const {snackbar} = useAlumno();
    const [avisar,setAvisar] =useState({});
    const [fecha_conversion,set_fecha_conversion]=useState(objetoModificacion?.obj?.fecha_conversion || null)
    const [fecha_bautismo_a,set_fecha_bautismo_a]=useState(objetoModificacion?.obj?.fecha_bautismo_a || null)
    const [pastores,setPastores]=useState(objetoModificacion?.obj?.pastores || [])
    const [agregarNombre,setAgregarNombre] = useState(false)

    const handleChange = (e,regex,noupper)=>{

        if(e.target.value === '' || ((regex && regex?.test(e.target.value)|| !regex))){
            const obj_aux = {...objetoModificacion.obj}

            if(noupper){
                obj_aux[e.target.name]=e.target.value;
            }else{
                obj_aux[e.target.name]=e.target.value.toUpperCase();
            }
    
            objetoModificacion.set({...obj_aux})
        }
    }

    const checkvalidacion = (campo,validacion)=>{

        if (!validar) return ''

        const objetoValidacion = validacion(validar)
        const resultado = objetoValidacion[0];
        const texto = objetoValidacion[1]
        const campos = objetoValidacion[2]
    
        if(resultado==true || !(campos instanceof Array) || campos.length==0){
            return ''
        }
        if(campos.some(item=>item.campo==campo)){
            return campos.filter(item=>item.campo==campo)[0]?.texto || ''
        }else{
            return ''
        }
    }


return <Box sx={{marginTop:'20px'}}>
        <h3>Referencias</h3>

        <table id="l-tramites-pastores" style={{width:'100%'}}>
            <tbody>
            <tr>
                <td>
                    <div className='form-card'><div>Nombre del pastor:<span className='c-obl'></span></div>
                    <TextField 
                                //label={'Nombre del pastor'} 
                                //disabled={ministroSeleccionado ? true : false} 
                                fullWidth={true} 
                                //autoFocus={true}
                                type='text' 
                                id='ub-nombre-pastor'
                                name='nombrepastor'
                                inputProps={{
                                    maxLength:100
                                }}
                                value={objetoModificacion.obj?.nombrepastor || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChange(e,REG_EX.ALPHABETIC)
                                }}
                        />
                        <FormHelperText className="Mui-error">{checkvalidacion('nombrepastor',validacion)}</FormHelperText>
                    </div>
                </td>
                <td>
                    <div className='form-card'><div>E-mail del pastor:<span className='c-obl'></span></div>
                            <TextField 
                                    //label={'E-mail del pastor'} 
                                    //disabled={ministroSeleccionado ? true : false} 
                                    fullWidth={true} 
                                    type='text' 
                                    id='ub-email-pastor'
                                    name='emailpastor'
                                    inputProps={{
                                        maxLength:100
                                    }}
                                    value={objetoModificacion.obj?.emailpastor || ''} 
                                    placeholder='Tu respuesta' 
                                    onChange={(e)=>{
                                        handleChange(e,REG_EX.EMAILCHARS,'no-uppercase')
                                    }}
                            />
                        <FormHelperText className="Mui-error">{checkvalidacion('emailpastor',validacion)}</FormHelperText>
                   </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div className='form-card'><div>Nombre de un anciano/diácono:<span className='c-obl'></span></div>
                    <TextField 
                                    //label={'Nombre del anciano/diácono'} 
                                    //disabled={ministroSeleccionado ? true : false} 
                                    fullWidth={true} 
                                    type='text' 
                                    id='ub-nombre-pastor'
                                    name='nombreanciano'
                                    inputProps={{
                                        maxLength:100
                                    }}
                                    value={objetoModificacion.obj?.nombreanciano || ''} 
                                    placeholder='Tu respuesta' 
                                    onChange={(e)=>{
                                        handleChange(e,REG_EX.ALPHABETIC)
                                    }}
                            />
                       <FormHelperText className="Mui-error">{checkvalidacion('nombreanciano',validacion)}</FormHelperText>
                    </div>
                </td>
                <td>
                    <div className='form-card'><div>E-mail del anciano/diácono:<span className='c-obl'></span></div>
                    <TextField 
                                //label={'E-mail del anciano/diácono'} 
                                //disabled={ministroSeleccionado ? true : false} 
                                fullWidth={true} 
                                type='text' 
                                id='ub-email-anciano'
                                name='emailanciano'
                                inputProps={{
                                    maxLength:100
                                }}
                                value={objetoModificacion.obj?.emailanciano || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChange(e,REG_EX.EMAILCHARS,'no-uppercase')
                                }}
                        />
                       <FormHelperText className="Mui-error">{checkvalidacion('emailanciano',validacion)}</FormHelperText>
                    </div>
                </td>
            </tr>
            { ((objetoModificacion.obj?.estado_civil?.id == 3 || objetoModificacion.obj?.estado_civil?.id == 6) && objetoModificacion.obj?.conyuge!='')  && <tr>
                <td>
                    <div className='form-card'>Nombre del conyuge:<span className='c-obl'></span>
                    <TextField 
                                    //label={'Nombre del conyuge'} 
                                    //disabled={ministroSeleccionado ? true : false} 
                                    fullWidth={true} 
                                    disabled
                                    type='text' 
                                    id='ub-nombre-conyuge'
                                    name='nombreconyuge'
                                    inputProps={{
                                        maxLength:100
                                    }}
//                                    value={objetoModificacion.obj?.nombreconyuge || ''} 
                                    value={objetoModificacion.obj?.conyuge || ''} 
                                    // DESHABILITO LA EDICION YA QUE SE TOMA EL MISMO NOMBRE INGREADO EN DATOS PERSONALES
                                    placeholder='' 
                                    /*onChange={(e)=>{
                                        handleChange(e,REG_EX.ALPHABETIC)
                                    }}*/
                            />
                           <FormHelperText className="Mui-error">{checkvalidacion('nombreconyuge',validacion)}</FormHelperText>
                    </div>
                </td>
                <td>
                    <div className='form-card'>E-mail del conyuge:<span className='c-obl'></span>
                    <TextField 
                                //label={'E-mail del conyuge'} 
                                //disabled={ministroSeleccionado ? true : false} 
                                fullWidth={true} 
                                type='text' 
                                id='ub-email-conyuge'
                                name='emailconyuge'
                                inputProps={{
                                    maxLength:100
                                }}
                                value={objetoModificacion.obj?.emailconyuge || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChange(e,REG_EX.EMAILCHARS,'no-uppercase')
                                }}
                        />
                       <FormHelperText className="Mui-error">{checkvalidacion('emailconyuge',validacion)}</FormHelperText>
                    </div>
                </td>
            </tr>}
            </tbody>
        </table>

</Box>

}






