import React from 'react'

export default function Grafico({vector,revertir}){

    if (revertir){
        return (
            <div className="flex f-row mb-6">
                {vector.reverse().map(item=>
                    <div className="flex f-col"><Spinner item={item} todos={vector}/>
                        <p className="pgf-dm">{item.periodo}</p>
                    </div>)}   
            </div>
        )
    }else{
        return(
            <div className="flex f-row mb-6">
                {vector.reverse().map(item=>
                    <div className="flex f-col"><Spinner item={item} todos={vector}/>
                        <p className="pgf-dm">{item.periodo}</p>
                    </div>)}   
            </div>
        )
    }
}

function Spinner({item, todos}) {

     let valor = Number(item.diezmo)
 
     const total = todos.reduce((ac,item)=>{return ac + Number(item.diezmo) },0)
     const porcentaje = ((Number(valor)/Number(total))*100).toFixed(1)
     const maximo = todos.map(item=>item.cantidad).sort((a,b)=>b-a)[0]
     
     return (
         <div>
       <svg width="50" height={110} fontFamily="sans-serif" fontSize="10" textAnchor="end">
   
     { valor > 0 && <rect  x="0" y={100-porcentaje * 3} fill={valor==1 ? "red" : "steelblue"} width="45" height={porcentaje * 3}></rect>}
     { valor > 0 && <text fill="black" x="50" y={50}>{valor.toFixed(2)}</text>}

     { valor == 0 && <rect  x="0" y={108} fill="red" width="40" height="20"></rect>}
     { valor == 0 && <text fill="black" x="30" y={50}>{valor.toFixed(2)}</text>}

    </svg>
         </div>
 
     );
   } 