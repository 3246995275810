import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {useAlumno} from '../../../Context/alumnoContext'
import REG_EX from '../../../Helpers/regex'
import {hacerFoco} from '../../../Helpers/utilidades-globales';
import { TryRounded } from '@mui/icons-material';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber,isValidPhoneNumber  } from 'react-phone-number-input'
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'

export const Contacto = ({objetoModificacion,paso_cumplido,validacion,validar})=>{
    
    //objetoModificacion es {obj,set}
    const [value, setValue] = useState()
    const [celular, setCelular] = useState(objetoModificacion.obj.celular || '')
    const [telefono, setTelefono] = useState(objetoModificacion.obj.telefono || '')

    const [cargando,setCargando] = useState(true);
    const {snackbar} = useAlumno();
    const [avisar,setAvisar] =useState({});

    /*useEffect(()=>{
        if(!paso_cumplido){
            hacerFoco('con-telefono')
        }
    },[])
    */
    const handleChange = (e,regex,noupper)=>{

        if(e.target.value === '' || ((regex && regex?.test(e.target.value)|| !regex))){
            const obj_aux = {...objetoModificacion.obj}

            if(noupper){
                obj_aux[e.target.name]=e.target.value;
            }else{
                obj_aux[e.target.name]=e.target.value.toUpperCase();
            }
    
            objetoModificacion.set({...obj_aux})
        }
    }
    
    const changeOnblur = (e)=>{
        if(!e.target.value){
            const aux = {...avisar};
            aux[e.target.name] = true
            setAvisar(aux)
        }else{
            const aux = {...avisar};
            aux[e.target.name] = false
            setAvisar(aux)
        }
    }

useEffect(()=>{
    objetoModificacion.set({...objetoModificacion.obj,celular:celular})
},[celular])

useEffect(()=>{
    objetoModificacion.set({...objetoModificacion.obj,telefono:telefono})
},[telefono])

const checkvalidacion = (campo,validacion)=>{
  
    if (!validar) return ''

    const objetoValidacion = validacion(validar)
    
    const resultado = objetoValidacion[0];
    const texto = objetoValidacion[1]
    const campos = objetoValidacion[2]

    if(resultado==true || !(campos instanceof Array) || campos.length==0){
        return ''
    }
    if(campos.some(item=>item.campo==campo)){
        return campos.filter(item=>item.campo==campo)[0]?.texto || ''
    }else{
        return ''
    }
}

return <Box sx={{marginTop:'20px'}}>
        <h3>Información de contacto</h3>
       {/*<PhoneInput
            placeholder="Número de teléfono"
            value={value}
            id="pablostolarczuk"
            countrySelectProps={{ unicodeFlags: false }}
            defaultCountry="AR"
            onChange={setValue}/>*/}
     {/*<>
        <p>
            telefono National: {telefono && formatPhoneNumber(telefono)}
        </p>
        <p>
            telefono International: {telefono && formatPhoneNumberIntl(telefono)}
        </p>
        <p>
            celular National: {telefono && formatPhoneNumber(celular)}
        </p>
        <p>
        celular International: {telefono && formatPhoneNumberIntl(celular)}
        </p>
      </>*/}
      <Grid container>
            <Grid item xs={12}>
                <div className='form-card'><div>Teléfono:<span className='c-obl'></span></div>
                    <Input
                        defaultCountry="AR"
                        id="con-telefono"
                        value={telefono}
                        placeholder="Tu respuesta"
                        maxLength="15"
                        onChange={setTelefono}/>
                    <FormHelperText className="Mui-error">{checkvalidacion('telefono',validacion)}</FormHelperText>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className='form-card'><div>Celular:<span className='c-obl'></span></div>
                    <Input
                        defaultCountry="AR"
                        id="con-celular"
                        value={celular} 
                        maxLength="15"
                        placeholder="Tu respuesta"
                        onChange={setCelular}/>
                    <FormHelperText className="Mui-error">{checkvalidacion('celular',validacion)}</FormHelperText>
                </div>
            </Grid>
        </Grid>
        {/*<Grid container>
            <Grid item xs={6}>
                <TextField 
                    onBlur={changeOnblur} 
                    label={'Teléfono fijo'} 
                    //disabled={ministroSeleccionado ? true : false} 
                    fullWidth={true} 
                    autoFocus={true}
                    type='text' 
                    id='ub-telefono'
                    name='telefono'
                    inputProps={{
                        maxLength:100
                    }}
                    value={objetoModificacion.obj?.celular || ''} 
                    placeholder='Teléfono fijo' 
                    onChange={(e)=>{
                        handleChange(e,REG_EX.NUMERIC)
                    }}
                    />
                    {(paso_cumplido == true || avisar['telefono']) && !objetoModificacion.obj?.telefono && <FormHelperText className="Mui-error">Falta ingresar el teléfono</FormHelperText>}
            </Grid>
            <Grid item xs={6}>
                <TextField 
                    onBlur={changeOnblur} 
                    label={'Celular'} 
                    //disabled={ministroSeleccionado ? true : false} 
                    fullWidth={true} 
                    type='text' 
                    id='ub-celular'
                    name='celular'
                    inputProps={{
                        maxLength:100
                    }}
                    value={objetoModificacion.obj?.celular || ''} 
                    placeholder='Celular' 
                    onChange={(e)=>{
                        handleChange(e,REG_EX.NUMERIC)
                    }}
                    />
                    {(paso_cumplido == true || avisar['celular']) && !objetoModificacion.obj?.celular && <FormHelperText className="Mui-error">Falta ingresar el celular</FormHelperText>}
               </Grid>
        </Grid>*/}
        <Grid container>
            <Grid item xs={12}>
                    <div className='form-card'><div>E-mail:<span className='c-obl'></span></div>
                        <TextField 
                                    onBlur={changeOnblur} 
                                    //label={'E-mail'} 
                                    //disabled={ministroSeleccionado ? true : false} 
                                    fullWidth={true} 
                                    type='text' 
                                    id='ub-email'
                                    name='email'
                                    disabled
                                    inputProps={{
                                        maxLength:100
                                    }}
                                    value={objetoModificacion.obj?.email || ''} 
                                    placeholder='Tu respuesta' 
                                    // NO PERMITO QUE MODIFIQUE EL EMAIL PORQUE LO COMPLETÓ EL PATROCINANTE
                                    //onChange={(e)=>{
                                    //    handleChange(e,REG_EX.EMAILCHARS,'no-uppercase')
                                    //}}
                            />
                            <FormHelperText className="Mui-error">{checkvalidacion('email',validacion)}</FormHelperText>
                    {/*(paso_cumplido == true || avisar['email']) && !objetoModificacion.obj?.email && <FormHelperText className="Mui-error">Falta ingresar el email</FormHelperText>*/}
                </div>
            </Grid>
        </Grid>
</Box>

}






