import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {Link} from 'react-router-dom'
import Axios from 'axios';
import Grafico from './Grafico';
import Loading from '../componentes/Loading';
import Main from './Main';

export default function Balances ({id_iglesia}){

const [datos,setDatos] = useState([])
const [buscandoDatos,setBuscandoDatos] = useState(false)

useEffect(()=>{
    const obtenerDatos = async ()=>{
        setBuscandoDatos(true)
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/balances/${id_iglesia}`)

            setDatos(data)
            setBuscandoDatos(false)

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }
    obtenerDatos()               
},[])

    if (buscandoDatos){
        return <Main center><div><Loading/><span className="cargando">Buscando balances...</span></div></Main>
    };    

    return (
        <div className="flex f-col mb-4 items-center">
            <p className="mb-4 mt-4">BALANCES</p>
            <div className="flex f-col">
                {datos.reverse().map(item=><span className={item.estado ==0 ? 'bal-np' : 'bal-pr'}>{item.periodo}</span>)}
            </div>
        </div>
    )
}


 