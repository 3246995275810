import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {Alert,Grid,Button} from '@mui/material'

//const estiloDefault = {width:'500px'}
export const Alerta = ({close,iraCompromiso,style})=>{
    return <div className='mt-4' style={style}>
    <Alert variant="filled" severity="warning"
    onClose={()=>{close(false)}}>
        A partir del 01/08/2022 deberá confirmar el compromiso ministerial para utilizar la credencial
    </Alert>
    <Button onClick={iraCompromiso} color="primary" size="small">
        Ir al compromiso ministerial
    </Button>
</div>
}


