import React,{useState,useEffect} from 'react'
import Axios from 'axios'
import Swal from 'sweetalert2';
import {useAlumno} from '../../../Context/alumnoContext'
import {gql,useQuery,useMutation,useLazyQuery} from '@apollo/client'
import REG_EX from '../../../Helpers/regex';

export const useTramite = ()=>{
    const {usuario} = useAlumno()
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario})
    const [provincias,setProvincias]=useState([])
    const [partidos,setPartidos]=useState([])
    const [ministros,setMinistros]=useState([])

    React.useEffect(()=>{
        buscarTablas()
    },[])

    const actualizarObjetoDinamico = (campoNuevo)=>{
        setObjetoDinamico({...objetoDinamico,...campoNuevo})
    }

    const CONSULTAR_TRAMITES_POR_TIPO_MINISTRO = gql`
        query consultaTramites($tipo:String!,$usuario_id:Int!){
            tramitesTipoMinistro(tipo:$tipo,id_ministro:$usuario_id){
                id,
                codigo_web,
                fecha,
                status{
                    texto
                }
            }
        }
    `

const CONSULTAR_ASCENSOS_POR_MINISTRO = gql`
query consultaAscensos($ministro_id:Int!){
    ascensosPorMinistro(ministro_id:$ministro_id){
        status_actual,
        status,
        id_tramite{
            codigo_web
        }
    }
}
`

    const CREAR_ASCENSO = gql`
mutation nuevoAnexo($nombre:String!,
                      $direccion:String!,
                      $localidad:String!,
                      $barrio:String,
                      $cod_postal:String!,
                      $telefono:String!,
                      $celular:String!,
                      $encargadoEsUad:Boolean!,
                      $nombreEncargado:String!,
                      $id_pastor_uad:Int, 
                      $iglesia_madre:String!,
                      $iglesia_madre_id:Int!,
                      $region:Int!,
                      $observaciones:String,
                      $usuario: String!,
                      $usuario_id:Int!,
                      $descripcion:String!,
                      $provincia:propTypeInput!,
                      $distrito:propTypeInput!,
                      $partido:propTypeInput!)
                      {
                        nuevoAnexo(
                                nombre:$nombre,
                                direccion:$direccion,
                                localidad:$localidad,
                                barrio:$barrio,
                                cod_postal:$cod_postal,
                                telefono:$telefono,
                                celular:$celular,
                                iglesia_madre:$iglesia_madre,
                                iglesia_madre_id:$iglesia_madre_id,
                                encargadoEsUad:$encargadoEsUad,
                                nombreEncargado:$nombreEncargado,
                                id_pastor_uad:$id_pastor_uad,
                                region:$region,
                                solicitante:$usuario,
                                solicitante_id:$usuario_id,
                                descripcion:$descripcion,
                                observaciones:$observaciones,
                                partido:$partido,
                                provincia:$provincia,
                                distrito:$distrito
                            )}`;

const callback = (values)=>{
        const variables = {
            region:Number(values.id_region),
            //distrito:values.distrito,
            //distrito_id:Number(values.id_distrito),
            usuario:usuario.nombre,
            descripcion:`Solicita nuevo anexo ${values.nombre} en partido ${values.partido.nombre} (${values.provincia.nombre})`,
            usuario_id:Number(usuario.id_obrero),
            iglesia_madre:values.iglesia_madre, // viene del callbackPrevio
            iglesia_madre_id:values.iglesia_madre_id, // viene del callbackPrevio
            nombre:values.nombre,
            direccion:values.direccion,
            localidad:values.localidad,
            barrio:values.barrio,
            cod_postal:values.cod_postal,
            //provincia:values.provincia,
            //id_provincia:Number(values.id_provincia),
            observaciones:values.observaciones,
            telefono:values.telefono,
            celular:values.celular,
            encargadoEsUad:values.encargadoEsUad,
            nombreEncargado:values.nombreEncargado,
            id_pastor_uad:values.encargadoEsUad ? Number(values.id_pastor_uad) : null,
            partido:{id:Number(values.partido.id),nombre:values.partido.nombre},
            provincia:{id:Number(values.provincia.id),nombre:values.provincia.nombre},
            distrito:{id:Number(values.distrito.id),nombre:values.distrito.nombre}
        }
 return variables
}

const buscarTablas = async ()=>{
    try{
        const data = await Promise.all([Axios.get(`api/tablasgenerales/provincias`),
                                        Axios.get(`api/tablasgenerales/partidos`),
                                        Axios.get(`/api/tablasgenerales/ministros/todos`)])
        
        setProvincias(data[0].data.filter(item=>item.id_provincia>0).map(item=>{
            return {id:item.id_provincia,nombre:item.nombre}
        }))

        setPartidos(data[1].data.partidos)

        const data_aux = data[2].data.filter(item=>item.id_obrero>0)
        .map(item=>{return {id:item.id_obrero,nombre:item.nombre_obrero}})
        setMinistros(data_aux)

    }catch(err){
        alert(err)
    }
}

/*const {loading,error,data,refetch} = useQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario.id_obrero,tipo:'ascenso'},
//    pollInterval: 5000000
})
*/
const [listadoTramitesporMinistro,{error,data,loading}] = useLazyQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario.id_obrero,tipo:'ascenso'}})

const [ascensosPorMinistroDetalle] = useLazyQuery(CONSULTAR_ASCENSOS_POR_MINISTRO) // las variables las paso como un objeto 
                                                                                    // al ejecutar

    const campos_formulario = [
         {   
            name:'nombre',
            required:true,
            visible:true,
            disabled:false,
            initial_value:'',
            length:100,
            type:'text',
            allowed_values:[],
            label:'Nombre del anexo',
            place:null,
            grupo:'Datos generales',
            onChange:(event,handleChange,setFieldValue,values)=>{
                setFieldValue('nombre',event.target.value.toUpperCase())
            },
            REG_EX:REG_EX.ALPHABETIC
        },
        {   
            name:'direccion',
            required:true,
            visible:true,
            disabled:false,
            initial_value:'',
            length:500,
            type:'text',
            allowed_values:[],
            label:'Dirección',
            place:null,
            grupo:'Domicilio',
            onChange:(event,handleChange,setFieldValue,values)=>{
                setFieldValue('direccion',event.target.value.toUpperCase())
            },
            REG_EX:REG_EX.ADDRESS
        },
        {   
            name:'barrio',
            required:false,
            visible:true,
            disabled:false,
            initial_value:'',
            length:500,
            type:'text',
            allowed_values:[],
            label:'Barrio',
            place:null,
            grupo:'Domicilio',
            onChange:(event,handleChange,setFieldValue,values)=>{
                setFieldValue('barrio',event.target.value.toUpperCase())
            },
            REG_EX:REG_EX.ADDRESS
         },
          {   
            name:'cod_postal',
            required:true,
            visible:true,
            disabled:false,
            initial_value:'',
            length:10,
            type:'text',
            allowed_values:[],
            label:'Código postal',
            place:null,
            grupo:'Domicilio',
            REG_EX:REG_EX.NUMERIC
        },
        {   
            name:'localidad',
            required:true,
            visible:true,
            disabled:false,
            initial_value:'',
            length:500,
            type:'text',
            allowed_values:[],
            label:'Localidad',
            place:null,
            grupo:'Domicilio',
            onChange:(event,handleChange,setFieldValue,values)=>{
                setFieldValue('localidad',event.target.value.toUpperCase())
            },
            REG_EX:REG_EX.ADDRESS
        },
       /* {   
            name:'id_provincia',
            required:true,
            visible:true,
            disabled:false,
            initial_value:'',
            length:100,
            type:'select',
            guardarTextoEnCampo:'provincia',
            allowed_values:async ()=>{
                try{
                    const {data} = await Axios.get('/api/tablasgenerales/provincias') 
                    return data
                    .map(item=>{return {id:item.id_provincia,nombre:item.nombre}})
                    .map(item=>{
                        return Object.values(item)
                    })
                }catch(err){
                    console.log(err)
                    alert('Se produjo un error al cargar las provincias')
                }
            },
            label:'Provincia',
            place:null,
            grupo:'Ubicación',
            REG_EX:REG_EX.NUMERIC
        },*/
        /*{   
            name:'provincia',
            visible:false,
            required:true, // Es requerido pero no visible, se carga cada vez que cambia el select de distrito
            initial_value:'', 
        },*/
        {   
            name:'telefono',
            required:true,
            visible:true,
            disabled:false,
            initial_value:'',
            length:15,
            type:'text',
            allowed_values:[],
            label:'Teléfono',
            place:null,
            grupo:'Datos de contacto',
            REG_EX:REG_EX.PHONE
        },
        {   
            name:'celular',
            required:true,
            visible:true,
            disabled:false,
            initial_value:'',
            length:15,
            type:'text',
            allowed_values:[],
            label:'Celular',
            place:null,
            grupo:'Datos de contacto',
            REG_EX:REG_EX.PHONE
        },
        {   
            name:'observaciones',
            required:false,
            multiple:true,
            visible:true,
            disabled:false,
            initial_value:'',
            length:1000,
            type:'text',
            allowed_values:[],
            label:null,
            place:null,
            grupo:'Observaciones',
            onChange:(event,handleChange,setFieldValue,values)=>{
                setFieldValue('observaciones',event.target.value.toUpperCase())
            },
            REG_EX:REG_EX.DESCRIPTION
        }
]

    return {
        usuario,
        mutationPadre:CREAR_ASCENSO,
        variables:callback,
        listadoTramitesporMinistro,
        objetoDinamico,
        actualizarObjetoDinamico,
        campos_formulario,
        ascensosPorMinistroDetalle,
        provincias,
        partidos,
        ministros
    }
}

const callbackFilterDinamicoRegiones = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_region==objetoDinamico.otroMinistro.id_region
    }else{
        return (item)=>item.id_region==objetoDinamico.usuario.id_region
    }
}

const callbackFilterDinamicoDistrito = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_distrito==objetoDinamico.otroMinistro.id_distrito
    }else{
        return (item)=>item.id_distrito==objetoDinamico.usuario.id_distrito
    }
}

const vectorStatusDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.rango,value:objetoDinamico.otroMinistro.rango}]
    }else{
        return [{id:objetoDinamico.usuario.rango,value:objetoDinamico.usuario.rango}]
    }
}

const vectorMinistroDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.id_ministro,value:objetoDinamico.otroMinistro.ministro}]
    }else{
        return [{id:objetoDinamico.usuario.id_obrero,value:objetoDinamico.usuario.nombre}]
    }
}
