import React from 'react';
import logo from '../logouad.png'; // Tell webpack this JS file uses this image

console.log(logo); // /logo.84287d09.png

function Logo({width,nocentrar}) {
  // Import result is the URL of your image
  return <img className={nocentrar ? '' : 'centrado'} src={logo} alt="Logo" width={width ? width : '150'}/>;
}

export default Logo;