import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {useAlumno} from '../../../Context/alumnoContext'
import REG_EX from '../../../Helpers/regex'
import { TryRounded } from '@mui/icons-material';
import {Fecha} from '../../../componentes/MUI/Fecha';

export const Cuestionario = ({objetoModificacion,paso_cumplido,validacion,validar})=>{
    
    //objetoModificacion es {obj,set}

    const [cargando,setCargando] = useState(true);
    const {snackbar} = useAlumno();
    const [avisar,setAvisar] =useState({});
    const [fecha_conversion,set_fecha_conversion]=useState(objetoModificacion?.obj?.fecha_conversion || null)
    const [fecha_bautismo_a,set_fecha_bautismo_a]=useState(objetoModificacion?.obj?.fecha_bautismo_a || null)
    //const [fecha_bautismo_es,set_fecha_bautismo_es]=useState(objetoModificacion?.obj?.fecha_bautismo_es || null)

    const handleChange = (e,regex,noupper)=>{

        if(e.target.value === '' || ((regex && regex?.test(e.target.value)|| !regex))){
            const obj_aux = {...objetoModificacion.obj}

            if(noupper){
                obj_aux[e.target.name]=e.target.value;
            }else{
                obj_aux[e.target.name]=e.target.value.toUpperCase();
            }
    
            objetoModificacion.set({...obj_aux})
        }
    }
    
    const changeOnblur = (e)=>{
        if(!e.target.value){
            const aux = {...avisar};
            aux[e.target.name] = true
            setAvisar(aux)
        }else{
            const aux = {...avisar};
            aux[e.target.name] = false
            setAvisar(aux)
        }
    }

React.useEffect(()=>{
       
        objetoModificacion.set({...objetoModificacion.obj,
                                fecha_conversion:fecha_conversion})
},[fecha_conversion])

React.useEffect(()=>{
       
    objetoModificacion.set({...objetoModificacion.obj,
        fecha_bautismo_a:fecha_bautismo_a})
},[fecha_bautismo_a])

/*
React.useEffect(()=>{
       
    objetoModificacion.set({...objetoModificacion.obj,
         fecha_bautismo_es:fecha_bautismo_es})
},[fecha_bautismo_es])
*/
const checkvalidacion = (campo,validacion)=>{

    if (!validar) return ''

    const objetoValidacion = validacion(validar)
    const resultado = objetoValidacion[0];
    const texto = objetoValidacion[1]
    const campos = objetoValidacion[2]

    if(resultado==true || !(campos instanceof Array) || campos.length==0){
        return ''
    }
    if(campos.some(item=>item.campo==campo)){
        return campos.filter(item=>item.campo==campo)[0]?.texto || ''
    }else{
        return ''
    }
}

return <Box sx={{marginTop:'20px'}}>
        <h3>Información sobre conversión y bautismos</h3>
        {/*<Grid container>
            <Grid item xs={12}>
                <div className='form-card'><div>Fecha de conversión:<span className='c-obl'></span></div>
                    <Fecha  
                                        //titulo='Fecha de conversión' 
                                        fecha={fecha_conversion}
                                        //autoFocus={true}
                                        style={{marginTop:0}}
                                        setFecha={(fecha)=>set_fecha_conversion(fecha)}/> 
                    <FormHelperText className="Mui-error">{checkvalidacion('fecha_conversion',validacion)}</FormHelperText>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className='form-card'><div>Fecha de bautismo en aguas:<span className='c-obl'></span></div>
                    <Fecha 
                                    //titulo='Fecha de bautismo en agua' 
                                    fecha={fecha_bautismo_a}
                                    style={{marginTop:0}}
                                    setFecha={(fecha)=>set_fecha_bautismo_a(fecha)}/> 
                    <FormHelperText className="Mui-error">{checkvalidacion('fecha_bautismo_a',validacion)}</FormHelperText>
                </div>
            </Grid>
        </Grid>*/}
        <Grid item xs={12}>
                <div className='form-card'><div>Año de conversión: <span className='c-obl'></span></div>
                    <TextField 
                                onBlur={changeOnblur} 
                                fullWidth={true} 
                                type='text' 
                                id='ub-year-conv'
                                name='year_conversion'
                                inputProps={{
                                    maxLength:4
                                }}
                                value={objetoModificacion.obj?.year_conversion || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChange(e,REG_EX.NUMERIC)
                                }}
                        />
                    <FormHelperText className="Mui-error">{checkvalidacion('year_conversion',validacion)}</FormHelperText>
                </div>
        </Grid>
        <Grid item xs={12}>
                <div className='form-card'><div>Año del bautismo en agua: <span className='c-obl'></span></div>
                    <TextField 
                                onBlur={changeOnblur} 
                                fullWidth={true} 
                                type='text' 
                                id='ub-year-baut'
                                name='year_bautismo'
                                inputProps={{
                                    maxLength:4
                                }}
                                value={objetoModificacion.obj?.year_bautismo || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChange(e,REG_EX.NUMERIC)
                                }}
                        />
                    <FormHelperText className="Mui-error">{checkvalidacion('year_bautismo',validacion)}</FormHelperText>
                </div>
            </Grid>
        <Grid container>
            {/*<Grid item xs={12}>
                <FormControlLabel sx={{marginTop:'10px'}}
                                control={
                                <Checkbox 
                                    checked={objetoModificacion.obj.bautismo_es}
                                    onChange={(e)=>objetoModificacion.set({...objetoModificacion.obj,bautismo_es:e.target.checked})}
                                    color="primary"
                                />
                                }
                                label="Ha tenido una experiencia real del bautismo en el Espíritu Santo con la señal de hablar en nuevas lenguas según Hechos 24"
                            />
            </Grid>*/}
            {<Grid item xs={12} style={{textAlign:'center'}}>
                    <FormControl>
                        <FormLabel style={{marginTop:'30px',marginBottom:'20px'}}>¿Ha tenido una experiencia real del bautismo en el Espíritu Santo con la señal de hablar en nuevas lenguas según Hechos 2:4? <span className='c-obl'></span></FormLabel>
                        <RadioGroup
                            value={objetoModificacion.obj.bautismo_es}
                            onChange={(e)=>{
                                    objetoModificacion.set({...objetoModificacion.obj,bautismo_es:e.target.value,fecha_bautismo_es:null})
                            }}>
                                <Box sx={{display:'flex',justifyContent:'center'}}>
                                    <FormControlLabel value="SI" control={<Radio />} label="Si" />
                                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                                </Box>
                        </RadioGroup>
                    </FormControl>
                    <FormHelperText className="Mui-error">{checkvalidacion('bautismo_es',validacion)}</FormHelperText>
            </Grid>}
        </Grid>
        
        {/*objetoModificacion.obj.bautismo_es=='SI' && <Grid container>
            <Grid item xs={12}>
                <Box sx={{display:'flex',justifyContent:'center'}}>
                        <div className='form-card'>Fecha de bautismo en el Espíritu Santo:<span className='c-obl'></span>
                        <Fecha 
                            //titulo='Bautismo en el Esp. Santo - Fecha' 
                            fecha={fecha_bautismo_es}
                            style={{marginTop:0}}
                            setFecha={(fecha)=>set_fecha_bautismo_es(fecha)}/> 
                        <FormHelperText className="Mui-error">{checkvalidacion('fecha_bautismo_es',validacion)}</FormHelperText>
                        </div>
                </Box>
            </Grid>
        </Grid>*/}
</Box>

}






