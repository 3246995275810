import React, {useContext,useEffect,useState,useRef} from 'react'
import {Typography, Button, Box ,Grid,IconButton,Select,makeStyles ,TableBody,MenuItem,FormControl,TableContainer,Table,TableHead,TableRow,TableCell, Backdrop} from "@material-ui/core";
import {gql,useQuery,useMutation,useLazyQuery} from '@apollo/client'
import {Loader} from './Loader'
import { useParams } from 'react-router-dom';
import Main from './Main';
import Logo from './Logo';
import {InicioTramite as Ministro} from '../Tramites/primera_credencial/vistas/InicioTramite'
import {Alert} from '@mui/material'
import {useAlumno} from '../Context/alumnoContext'
import { ErrorWrapper } from './ErrorWrapper';

export const Temporal = ({})=>{

    const [finalizado,setFinalizado] = React.useState(false)
    const [continuar,setContinuar] = React.useState(false)
    const {esCelular} = useAlumno();

    const params = useParams();

    const finalizar = ()=>{
        setFinalizado(true)
    }

    const GET_PATROCINADO = gql`
    query patrocinado ($id:ID!){
        patrocinadoInfoExterno(id:$id){
            _id,
            nombre,
            apellido,
            sexo,
            email,
            activo,
            provincia{id,nombre},
            partido{id,nombre},
            iglesia{id,nombre},
            id_tramite{
                ministro{
                    nombre,
                    id
                },
                eliminado,
                codigo_web,
                region,
                distrito{id,nombre}
            }
        }
    }
`

const {loading,error,data,refetch} = useQuery(GET_PATROCINADO,{variables:{id:params?.id_tramite},
    onError:(err)=>console.log(err.message)})

    if(esCelular){
        return <Main center alinear={true}>
            <Logo width="100" nocentrar/> 
            <br />  
            <h3 className='text-center'>No es posible abrir la solicitud desde un dispositivo móvil</h3>
            <br />  
        </Main>
    }

    if(loading){
        return <Main center alinear={true}>
            <Loader open={true} 
                texto='Cargando...'
                children={<Logo width='300px'/>}/>
        </Main>
    }

    if(!data || !(data?.patrocinadoInfoExterno) || error || data?.patrocinadoInfoExterno.id_tramite.eliminado==true){
        return <Main center alinear={true}>
            <Logo width="100" nocentrar/> 
            <br />  
            <br />  
            <h1>El link es inválido</h1>
            <br />  
            <br />
            <p style={{maxWidth:'500px'}}>Por favor contacte a la oficina administrativa UAD indicando su Nombre, Apellido y el código web que figura en el mail que recibió con el link.</p>
            <br />
            <p>Muchas gracias</p>
    </Main>
    }

    if(finalizado){
        return <Main center alinear={true}>
            <br />
            <Logo width="100" nocentrar/> 
            <br />  
            <br />  
            <h1>Gracias por completar el formulario</h1>
            <br />  
            <br />  
            <p style={{maxWidth:'500px'}}>Se enviaron por mail los pedidos de referencias correspondientes a las personas que incluyó en su solicitud.</p>
            <br />
            <p style={{maxWidth:'500px'}}>Le contactarémos en breve para realizar una entrevista personal con una autoridad de la secretaría regional correspondiente a su distrito.</p>
            <br />
    </Main>
    }

    if(data && data?.patrocinadoInfoExterno.activo==false){
        return <Main center alinear={true}>
            <br />
            <Logo width="100" nocentrar/> 
            <br />  
            <br />  
            <h1>El link fue desactivado</h1>
            <br />  
            <br />  
            <p style={{maxWidth:'500px'}}>Si completó el formulario de solicitud de credenciales le contactarémos en breve para realizar una entrevista personal con una autoridad de la secretaría regional correspondiente a su distrito.</p>
            <br />
            <p style={{maxWidth:'500px'}}>Si aún no ha completado el formulario por favor contacte a la oficina administrativa UAD para verificar el motivo de la desactivación del link.</p>
            <br />
            <p>Muchas gracias</p>
    </Main>
    }

    if(data && problemasUbicacion(data) ){
        return <Main center alinear={true}>
            <br />
            <Logo width="100" nocentrar/> 
            <br />  
            <br />  
            <h1>Encontramos un problema</h1>
            <br />  
            <Alert severity="info" variant='outlined'>
                <p>El código web de la solicitud es {data?.patrocinadoInfoExterno.id_tramite.codigo_web}</p>
                <br />
                {analisisError(data)}
            </Alert>
            <br />
            <p style={{maxWidth:'500px'}}>Por favor comunique el problema al pastor solicitante o a la oficina administrativa de la UAD</p>
            <br />
            <p>Muchas gracias</p>
    </Main>
    }

    if(continuar){
        return <Main><div className="text-center"><Box style={{background:'',padding:'',width:'100%',marginLeft:'auto',marginRight:'auto'}}>
        <br />
        <Logo width="100" nocentrar/> 
        <ErrorWrapper>
            <Box style={{marginTop:'50px',textAlign:'left'}}>
                <Ministro finalizar={finalizar} cancelar={()=>setContinuar(false)} datosPatrocinante={data.patrocinadoInfoExterno}/>
            </Box>            
        </ErrorWrapper>
    </Box>
  </div>
  </Main>
    }

    return <Main><div className="text-center"><Box style={{background:'',padding:'',width:'100%',marginLeft:'auto',marginRight:'auto'}}>
        <br />
        <Logo width="100" nocentrar/> 
        {<Box className='form-body-tram' style={{marginTop:'50px',textAlign:'left'}}>
            <Box style={{padding:'5px',marginLeft:'auto',marginRight:'auto',width:'640px'}}>
                <h2>Hola {data.patrocinadoInfoExterno.nombre}</h2>
                <br />  
                <p>Hemos recibido una solicitud para otorgarle credenciales en nuestra organización</p>
                <br />
                <p>La petición fue iniciada por <b>{data.patrocinadoInfoExterno.id_tramite.ministro.nombre}</b></p>
                <br />
                <p>En la siguiente sección encontrará los requisitos necesarios para continuar con el proceso.</p>
                <br />
                <p>Luego, tendrá acceso a un formulario para completar con la información y documentación que necesitamos para procesar su solicitud.</p>
                <br />
                <p>Finalmente, luego de la recepción y evaluación de la información y documentación que nos ha brindado le contactarémos para realizar una entrevista personal con una autoridad de la secretaría regional correspondiente a su distrito.</p>
                <Box style={{display:'flex',marginTop:'25px',justifyContent:'right'}}>
                    <Button variant='outlined' color='primary' onClick={()=>setContinuar(true)}>Continuar</Button>
                </Box>
            </Box>
        </Box>}
    </Box>
  </div>
  </Main>
}

const analisisError = (data)=>{

    const aux = data?.patrocinadoInfoExterno; 
    if(aux?.provincia==undefined || aux?.provincia?.id==undefined || aux?.provincia?.nombre==undefined){
        return 'La provincia asociada a la iglesia es inválida'
    }else if (aux?.partido==undefined || aux?.partido?.id==undefined || aux?.partido?.nombre==undefined){
        return 'El partido asociado a la iglesia es inválido'
    }else if (aux?.iglesia==undefined || aux?.iglesia?.id==undefined || aux?.iglesia?.nombre==undefined){
        return 'La iglesia es inválida'
    }else{
        return 'No se pudo identificar el error'
    }
}

const problemasUbicacion = (data)=>{
    return data?.patrocinadoInfoExterno.provincia==undefined || 
            data?.patrocinadoInfoExterno.partido==undefined || 
            data?.patrocinadoInfoExterno.iglesia==undefined ||
            data?.patrocinadoInfoExterno?.iglesia?.nombre==undefined ||
            data?.patrocinadoInfoExterno?.iglesia?.id==undefined ||
            data?.patrocinadoInfoExterno?.partido?.nombre==undefined ||
            data?.patrocinadoInfoExterno?.partido?.id==undefined ||
            data?.patrocinadoInfoExterno?.provincia?.nombre==undefined ||
            data?.patrocinadoInfoExterno?.provincia?.id==undefined
}