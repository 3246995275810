import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {useAlumno} from '../../../Context/alumnoContext'
import REG_EX from '../../../Helpers/regex'
import { TryRounded } from '@mui/icons-material';
import {Fecha} from '../../../componentes/MUI/Fecha';
import DeleteIcon from '@mui/icons-material/Delete';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import {hacerFoco} from '../../../Helpers/utilidades-globales';

export const Pastores = ({objetoModificacion,paso_cumplido,validacion,validar})=>{
    
    //objetoModificacion es {obj,set}

    const [cargando,setCargando] = useState(true);
    const {snackbar} = useAlumno();
    const [avisar,setAvisar] =useState({});
    const [fecha_conversion,set_fecha_conversion]=useState(objetoModificacion?.obj?.fecha_conversion || null)
    const [fecha_bautismo_a,set_fecha_bautismo_a]=useState(objetoModificacion?.obj?.fecha_bautismo_a || null)
    const [pastores,setPastores]=useState(objetoModificacion?.obj?.pastores || [])
    const [agregarNombre,setAgregarNombre] = useState(false)

useEffect(()=>{
    objetoModificacion.set({...objetoModificacion.obj,pastores:pastores})
},[pastores])

const addPastor = (value)=>{
    setPastores([...pastores,value])
}

const deletePastor = (index)=>{
    setPastores(pastores.filter((i,indice)=>indice!=index))
}

const agregarPastor = ()=>{
    setAgregarNombre(true)
    setTimeout(() => {
        hacerFoco('input-pastor-t')
    }, 200);
}
const checkvalidacion = (campo,validacion)=>{

    if (!validar) return ''

    const objetoValidacion = validacion(validar)
    const resultado = objetoValidacion[0];
    const texto = objetoValidacion[1]
    const campos = objetoValidacion[2]

    if(resultado==true || !(campos instanceof Array) || campos.length==0){
        return ''
    }
    if(campos.some(item=>item.campo==campo)){
        return campos.filter(item=>item.campo==campo)[0]?.texto || ''
    }else{
        return ''
    }
}

return <Box sx={{marginTop:'20px'}}>
        <h3>Nombre de cada uno de los pastores con quienes ha estado trabajando</h3>
        <div>Ingrese nombre y apellido del al menos un pastor:<span className='c-obl'></span></div>
        <table id="l-tramites-pastores">
            <tbody>
            {pastores.map((item,index)=><tr>
                <td>
                    <CircleIcon sx={{ fontSize: 5 }} />
                </td>
                <td>
                    {item}
                </td>
                <td>
                    <IconButton title='Eliminar' color="primary" onClick={()=>deletePastor(index)} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </td>
            </tr>
            )}
            </tbody>
        </table>


        <Box>
            {(!agregarNombre && pastores.length>0) && <Button  startIcon={<PlusOneIcon/>} variant='text' color='primary' onClick={agregarPastor}>
                    Agregar otro pastor
            </Button>}
            {(agregarNombre || pastores.length==0) && <InputPastor esPrimero={pastores.length==0} addPastor={addPastor} finalizar={()=>setAgregarNombre(false)}/>}
        </Box>

        <FormHelperText className="Mui-error">{checkvalidacion('pastores',validacion)}</FormHelperText>
</Box>

}

const InputPastor = ({addPastor,finalizar,esPrimero})=>{

    const [nombre,setNombre] = useState('')

    const handleChange = (e)=>{
        setNombre(e.target.value.toUpperCase())
    }

    const guardar = ()=>{
        if(nombre.length>=10){
            addPastor(nombre)
            finalizar()
        }else{
            alert('Agregue un nombre de al menos 10 caracteres')
        }
    }

    return <Box sx={{display:'flex'}}>
                <TextField 
                        label={'Nombre y apellido del pastor'} 
                        //autoFocus={true}
                        type='text' 
                        inputProps={{
                            maxLength:100
                        }}
                        fullWidth={false}
                        style={{width:'300px'}}
                        id='input-pastor-t'
                        value={nombre} 
//                        placeholder='Nombre y apellido del pastor' 
                        onChange={handleChange}
                />
            
            {/*nombre.length > 5 && <IconButton title='Agregar' color="primary" onClick={guardar}>
                        <AddCircleIcon /> <span>Agregar</span>
            </IconButton>*/}
            {nombre.length > 1 && <Button size='small' startIcon={<AddCircleIcon/>} variant='contained' color='primary' onClick={guardar}>
                <p className='blink'>Agregar</p>
            </Button>}
            {!esPrimero && <Button style={{marginLeft:'25px'}} size='small' title='Agregar' startIcon={<CloseOutlinedIcon/>} variant='text' color='primary' onClick={finalizar}>
                Cancelar
            </Button>}
            {/*!esPrimero && <IconButton title='Cancelar' color="secondary" onClick={finalizar}>
                        <CloseOutlinedIcon />
            </IconButton>*/}
        </Box>
}





