import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'

export const Iglesias = ({usuario,
                            iglesiaSeleccionada,
                            setIglesiaSeleccionada,
                            setIglesias
                        })=>{
    const [datos,setDatos]=useState([])
    const [seleccionIn,setSeleccionIn] = useState(null)

    React.useEffect(()=>{
        buscarIglesias()
    },[])

    const buscarIglesias = async ()=>{
        try{
            const {data} = await Axios.get(`api/tablasgenerales/iglesias/${usuario.id_obrero}`)
            setDatos(data.map(item=>{
                return {id:item.id_iglesia,nombre:item.nombre_igl}
            }))
            setIglesias(data)
        }catch(err){
            alert(err)
        }
    }

return <Box sx={{marginTop:'20px'}}>
        <MuiSelect datos={datos} 
        titulo={'Iglesias autónomas'}
        value={seleccionIn}
        tituloDefault={'Seleccione una iglesia'}
        setValue={setSeleccionIn}
        />
        {seleccionIn && <Button style={{marginTop:'15px'}} variant="outlined" color='primary' onClick={()=>setIglesiaSeleccionada(seleccionIn)}>Confirme la iglesia madre</Button>}
</Box>

}


