import React, {useContext,useEffect,useState} from 'react'
import {TextField, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from "@mui/material/Autocomplete";
import Alert from '@mui/material/Alert';

export const MuiSelect = ({datos,titulo,setValue,value,disabled,noDefault,tituloDefault,noAdvertir,obligatorio})=>{
    const [seleccionado,setSeleccionado] = React.useState(-1)


    useEffect(()=>{
//        if(datos.length>0){ 
        if(!noDefault){
                if(datos.length>0 && value!=null){ //cambio 22-7-2023 si el value inicial es null no asigne el primer valor de data asi es -1 y muestra texto "Seleccione una opción"
                    setValue(datos[0].id)
                }
                if(datos.length==1){ 
                    setValue(datos[0].id)
                }
        }

    },[datos])

    const onchangeselect = (event)=>{
        setValue(event.target.value)
    }

    if(!noAdvertir){
        if(!datos instanceof Array || !datos){
            return <Box>
                <Alert severity="error">No se encontraron los datos de su lista</Alert>
            </Box>
        }
    
        if(datos instanceof Array && datos.length==0){
            return <Box>
                <Alert severity="error">No se encontraron los datos de su lista</Alert>
            </Box>
        }
    }
   

    return    <Box>
                {obligatorio && <span className='c-obl'></span>}
                <TextField id="selectx" 
                        label={titulo} 
                        value={value || -1} 
                        select 
                        fullWidth
                        disabled={disabled || false}
                        onChange={(e)=>onchangeselect(e)}>
                        <MenuItem value={-1} disabled>
                            {tituloDefault || 'Seleccione una opción'}
                        </MenuItem>
                        {datos?.map(item=><MenuItem value={item.id}>
                            {item.nombre}
                    </MenuItem>)}
                </TextField>
    </Box>

 }

