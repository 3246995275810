import React from 'react'

export default function Main({children, center, eliminarClases, alinear,sinpadding,estilo}){
   // let classes = `Main ${center ? alinear ? 'Main--center items-center' : 'Main--center' : ''}`
    let classes = `Main ${center ? alinear ? 'Main--center items-center' : 'Main--center' : ''}`

        return (
            <main style={estilo ? estilo : sinpadding ? {padding:'0px'} : null} className={ eliminarClases ? '' : classes}>
                {children}
            </main>
        )
}