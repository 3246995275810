import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Main from '../componentes/Main';
import {Link} from 'react-router-dom'
import Qrcode from 'qrcode.react';
import Axios from 'axios';
import Logo from '../componentes/Logo'
import Loading from '../componentes/Loading';
import {useAlumno} from '../Context/alumnoContext';

export default function Qr ({ocultar,usuario}){

const [url,setUrl] = useState(null);
const [archivos, setArchivos]= useState([])
const [status, setStatus]= useState(false)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const {confirmarCargandoVista} = useAlumno()

useEffect(()=>{
    const obtenerArchivos = async ()=>{

        setBuscandoDatos(true);
        confirmarCargandoVista(true);

        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/documentos`)]) 
            setArchivos(vectorResultado[0].data.documentos)
            setBuscandoDatos(false);
            confirmarCargandoVista(false)
        }catch(err){
            console.log(err)
            setBuscandoDatos(false);
            confirmarCargandoVista(false)
        }
    }

    obtenerArchivos()       

},[])


    if(buscandoDatos){
        return <Main center><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    }

    return (
        <div className="flex justify-center p-2">
                {archivos.length > 0 && <>
                    <table>
                        <tbody>
                            {archivos.map(item=><tr className="h-16">
                                <td><FontAwesomeIcon className="text-large color-tomato ml-4" icon={faFileAlt}/></td>
                                <td><a target="_blank" className="ml-2 text-large color-black" href={item.ruta}>{item.nombre}</a></td>
                            </tr>)}
                        </tbody>
                    </table>
                </>}
        </div>
    )
}

