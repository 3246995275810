
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { Box  } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import FormControl from '@mui/material/FormControl';

export const Fecha = ({titulo,fecha,setFecha,disabled,ocultar,style,noBorrar,autoFocus})=>{
    const [value, setValue] = React.useState();

       /*
    Cuando se usa el TEXTFIELD type date el formato de fecha esperado debe ser el formato de fecha ISO 8601, es decir, "YYYY-MM-DD"
    */
    return <Box style={style} sx={{marginTop:'1rem',textAlign:'left',display:ocultar ? 'none':'flex',alignItems:'center'}} >
        <FormControl>
            <TextField label={titulo} 
            type="date"
            autoFocus={autoFocus || false}
            title={titulo}
            
            disabled={disabled}
            onChange={(e)=>setFecha(e.target.value)} 
            value={fecha || ''}
            InputLabelProps={{
                shrink: true,
            }} />
    </FormControl>
  {!noBorrar && fecha && <IconButton color="primary" disabled={disabled} onClick={()=>setFecha(null)} aria-label="delete">
  <DeleteIcon />
</IconButton>}
    </Box>
}