import React, {useEffect,useState,useContext} from 'react'
import Divider from '@mui/material/Divider';
import { AlertDialog } from '../MUI/AlertDialog';
import { MuiAccordion } from '../MUI/Accordion';
import {Button,Grid,Card,CardContent,Box} from "@material-ui/core";
import {Formik} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import {useAlumno} from '../../Context/alumnoContext'
import Axios from 'axios';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const useStyle = makeStyles({
    selectpais: {
        background:'gray',
        color:'black'
    },
    selectprovincia: {
        background: 'gray',
        color:'black'
    },
  });
//                                            <InfoOutlinedIcon sx={{ fontSize: 25,color:'yellow' }} /> 

export const SubirArchivo = ({texto,titulo,file,setFile,tiposFiles,maximo})=>{
    const classes = useStyle();
    const [dataFile, setDataFile] = React.useState('');
    //const [file, setFile] = React.useState();
    const {snackbar} = useAlumno()

    const onImageDrop = async (e) => {
//        setDataFile(e[0]);
        console.log(e[0])
        setFile(e[0]);
      }
    
      const onImageChange = (event) => {
        try {
          setFile(event[0]);
          console.log(event[0])
        } catch (error) {
        }
      };

      const subirArchivo = async (values)=>{
        try{
            if (!file){
                snackbar.showMessage("Por favor selecciona un archivo", "error");
            }else{
                
                await doPostFile(file)
                setFile(null)
            }
        }catch(err){
            snackbar.showMessage(err.message, "error");
        }
           
      }

      const doPostFile = async (file) => {

        let form = new FormData()
         form.append('upload', file, file.name);
        
         const uploadFile = await loadDataload(form);

       }

       const loadDataload = async (form) => {
        try {
            const respuesta = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/upload`,form,{headers: { 'Content-Type': 'multipart/form-data' }})
            console.log('respuesta',respuesta)
            return respuesta.data;
        } catch (error) {
          return error;
        }
      };

return <React.Fragment>
    <Grid
        //justify="space-between" // Add it here :)
        container
        style={{display:'flex',justifyContent:'flex-start',marginTop:'25px',padding:'0',fontSize:'large'}}
        direction="row"
      >
        <Grid item>
            <Box sx={{display:'flex',alignItems:'center',border:'solid 1px white',padding:'3px',borderRadius:'4px'}}>
                <p>{titulo} <span className='c-obl'></span></p> 
                {/*file && <CheckBoxIcon sx={{ fontSize: 30,color:'green' }}/>*/}
                {/*!file && <CheckBoxOutlineBlankIcon sx={{ fontSize: 30,color:'red' }}/>*/}
                {/*file && <p>{file.path}</p>*/}
                {file && false && <DeleteIcon sx={{ fontSize: 30 }}/>}
            </Box>
            {file && <Box sx={{display:'flex',alignItems:'center',marginBottom:'25px'}}>
                    <AttachFileIcon sx={{ fontSize: 30 }}/>
                    <p>{file.path} </p>
                    <Button startIcon={<DeleteIcon/>} title="Eliminar el archivo adjunto" onClick={()=>setFile(null)}></Button>
                </Box>}
        </Grid>
      </Grid>
      {!file && <Grid
        //justify="space-between" // Add it here :)
        container
        style={{display:'flex',justifyContent:'center'}}
        direction="row"
      >

            <Grid item>
                    <Formik
                    initialValues={{ "dato": "" }}
                    enableReinitialize={true}
                    /* validate={validate} */
                    onSubmit={subirArchivo}
                    >

                    {props => {
                        const {
                        values,
                        touched,
                        errors,
                        status,
                        isSubmitting,
                        validate,
                        handleChange,
                        handleSubmit,
                        handleBlur
                        } = props;

                        return (<form className={classes.form} onSubmit={handleSubmit}>
                                <Card style={{display:'flex',justifyContent:'center',background:'#b3aaaa'}}>
                                        <CardContent>
                                            <DropzoneArea
                                                key={()=>Math.random().toString}
                                                onChange={onImageChange}
                                                onDrop={onImageDrop}
                                                clearOnUnmount={true}
                                                dropzoneText={texto}
                                                acceptedFiles={tiposFiles || ['.pdf']}
                                                //variant="square"
                                                id="dropzonearea-file"
                                                //getFileLimitExceedMessage={(filesLimit) => `El tamaño del archivo supera el máximo permitido`}
                                                getDropRejectMessage={() => `El tamaño del archivo supera el máximo permitido`}
                                                filesLimit={1}
                                                fullWidth
                                                showFileNames={true}
                                                //maxFileSize={5000000}
                                                maxFileSize={maximo}
                                                getFileAddedMessage={()=>"Se seleccionó un archivo para adjuntar"}
                                                getFileRemovedMessage={()=>"Se eliminó el archivo"}
                                                alertSnackbarProps={{ autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'center' } }}
                                            />
                                        </CardContent>
                                        {/*<CardActions>
                                        <Button type="submit" className={classes.submit} color="primary" variant="contained">
                                            Subir archivo
                                        </Button>

                                        <Button color="primary" onClick={() => { alert(25); }}>
                                            Cancelar
                                        </Button>
                                        </CardActions>*/}
                                </Card>
                                <p className='color-red'>Máximo: {textoMaximo(maximo)}</p>
                            </form>)
                            
                        }
                    }
                </Formik>
            </Grid>
      </Grid>}


          </React.Fragment>
    
    } 

const textoMaximo = (maximo)=>{
    if(maximo>=1000000){
        return `${maximo/1000000} Mb`
    }else{
        return `${maximo/1000} Kb`
    }
}