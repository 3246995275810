import React from 'react'

export class ErrorWrapper extends React.Component {
    state = {hasError:false,message:''}

    componentDidCatch(error){
        this.setState({hasError:true,message:error.message})
    }

    render (){
        if(this.state.hasError){
            return (
                <div className='mt-4 w-300 text-center mr-auto ml-auto'>
                    <h4>Se produjo un error interno. </h4>
                    <p>Por favor no cierre la página y comuníquese con la administración informando el mensaje de error.</p>
                    <div style={{marginTop:'50px',padding:'10px',borderRadius:'10px',border:'solid 1px gray', background:'#aca6a6'}}>
                        {this.state.message}
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}
