import React, {useContext} from 'react'
import {TextField,Button,DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from "@material-ui/core";
import LinearProgress from '@mui/material/LinearProgress';

export const Loader = ({open,
                          children,
                          texto})=>{
  
      const  [autoclose,setAutoclose] = React.useState(true)

      const handleClose = ()=>{
          setAutoclose(false)
      }

    return     <div>
    <Dialog fullScreen open={open && autoclose} style={{opacity:'0.85'}}>
      <DialogContent onDoubleClick={handleClose} style={{display:'flex',background:'steelblue',flexDirection:'column', justifyContent:'center',height:'100%'}}>
        <DialogContentText>
            <p style={{textAlign:'center'}}>{texto}</p>
        </DialogContentText>
        <LinearProgress/>
            {children}
      </DialogContent>
    </Dialog>
  </div>
}