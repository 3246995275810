import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Logo from '../Logo'
import {useAlumno} from '../../Context/alumnoContext'
import Alert from '@mui/material/Alert';
import MessageIcon from '@mui/icons-material/Message';
import Badge from '@mui/material/Badge';
import {Notificaciones} from '../../componentes/Notificaciones';

export const MenuAppBar = ({usuario, logout})=> {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {mostrarBusquedaAlumnos, 
    habilitarBusquedaAlumnos,
    desHabilitarBusquedaAlumnos,
    infoObrero,
    esCelular,
    ocultarMenu,
    verMenu,vistaCargada} = useAlumno()

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (esCelular){
      return null
  }

  return (
    <Box>
      <AppBar position="static">
        <Toolbar sx={{justifyContent:'space-between'}}>
          <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
            <Logo width={70}/>
            <Typography variant="body" component="div" sx={{ flexGrow: 1 }}>
                {infoObrero.sexo=='M' ? `Bienvenido ${infoObrero.nombre}` : `Bienvenida ${infoObrero.nombre}`}
            </Typography>
          </Box>
          <Notificaciones usuario={usuario}/>
            <div>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={logout}
                color="inherit"
              >
                  SALIR
              </IconButton>
            
            </div>
        </Toolbar>
      </AppBar>
      {usuario.id_iglesia_dependiente && <Alert variant="outlined" severity="info">
          {obtenerAsociacion(infoObrero,usuario)}
      </Alert>}
    </Box>
  );
}

function obtenerAsociacion(infoObrero,usuario){

  if (usuario.id_iglesia_dependiente){
      if (usuario.id_iglesia_dependiente > 0){ // depende de una iglesia si el id es positivo
          if (infoObrero.sexo=='M'){
               return `Asociado a la iglesia ${usuario.dependiente}`
          }else{
              return `Asociada a la iglesia ${usuario.dependiente}`
          }
      }else{  // depende de un ministerio si el id es negativo
          if (infoObrero.sexo=='M'){
              return `Asociado al ministerio de ${usuario.dependiente}`
          }else{
              return `Asociada al ministerio de ${usuario.dependiente}`
          }
      }
  }else{
      return ''
  }

}
