import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'

export const Dependientes = ({usuario,
                            dependienteSeleccionado,
                            setDependienteSeleccionado,
                            setDependientes
                        })=>{
    const [datos,setDatos]=useState([])

    React.useEffect(()=>{
        buscarDependientes()
    },[])

    React.useEffect(()=>{
        if(datos.length>0){
            setDependienteSeleccionado(datos[0]?.id)
        }
    },[datos])

    const buscarDependientes = async ()=>{
        try{
            const {data} = await Axios.get(`api/tablasgenerales/obrero/dependientes/${usuario.id_obrero}`)
            setDatos(data.map(item=>{
                return {id:item.id,nombre:item.nombre}
            }))
            setDependientes(data)
        }catch(err){
            alert(err)
        }
    }

return    <Box sx={{marginTop:'20px'}}>
        <MuiSelect datos={datos} 
        titulo={'Ministros dependientes'}
        value={dependienteSeleccionado}
        setValue={setDependienteSeleccionado}
        />
</Box>

}


