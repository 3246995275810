import React from "react";
import '../Assets/css/cssloader.css'

export const LoaderCircular = (props) => {
  return (

    <div id="loader-wrapper" className="centrarCirculo">
      <div id="loader1" className="loaderbox"></div>
    </div>

  )
}