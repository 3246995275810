import React, {createContext} from 'react'
import {useTramite} from '../hooks/useTramite'
import REG_EX from '../../../Helpers/regex';
import Axios from 'axios'

export const tramiteContext = createContext()

const Provider = tramiteContext.Provider

export const TramiteContextProvider = ({children})=>{ // Exporto el Provider

    const {usuario,objetoDinamico,actualizarObjetoDinamico} = useTramite()

        const campos_formulario = [
            {   
                 name:'texto',
                 required:true,
                 visible:true,
                 disabled:false,
                 initial_value:'',
                 length:100,
                 type:'text',
                 //guardarTextoEnCampo:'status_actual',
                 //selectFirst:true,
                // allowed_values:async(props,objetoDinamico)=>[{id:objetoDinamico.usuario.rango,value:objetoDinamico.usuario.rango}].map(item=>Object.values(item)),
//                    label:'Rango actual',
                 place:null,
                 grupo:'Texto',
                 REG_EX:REG_EX.ALPHABETIC,
                 //style:{display:'none'}
             },  
            /* {
                 name:'ministro_id',
                 required:true,
                 visible:true,
                 initial_value:'',
                 type:'copy',
                 REG_EX:REG_EX.NUMERIC,
                 label:'ministro_id',
                 grupo:'Ministro'
             },
             {
                 name:'ministro',
                 required:true,
                 visible:true,
                 initial_value:'',
                 type:'copy',
                 REG_EX:REG_EX.ALPHABETIC,
                 label:'Ministro',
                 grupo:'Ministro'
             },*/
             {   
                 name:'id_region',
                 required:true,
                 visible:true,
                 disabled:false,
                 initial_value:'',
                 length:100,
                 type:'select',
                 selectFirst:true,
                 allowed_values:async (props)=>{
                     try{
                         console.log('objetoDinamico',objetoDinamico)
                         const {data} = await Axios.get('${process.env.REACT_APP_ROOT}/api/tablasgenerales/regiones') 
                         return data.filter(callbackFilterDinamicoRegiones(objetoDinamico))
                         .map(item=>{return {id:item.id_region,nombre:item.nombre}})
                         .map(item=>{
                             return Object.values(item)
                         })
                     }catch(err){
                         console.log(err)
                         alert('Se produjo un error al cargar las regiones')
                     }
                 },
                 label:'Región',
                 place:null,
                 grupo:'Región y distrito actuales',
                 REG_EX:REG_EX.NUMERIC
             },   
             {   
                 name:'id_distrito',
                 required:true,
                 visible:true,
                 disabled:false,
                 initial_value:'',
                 allowedIf:'id_region',
                 length:100,
                 dependeDe:'id_region', // ATENCION: como dependeDe esta id_region este atributo se lee en el SELECT para volver a llamar a allowed_values
                 type:'select',
                 guardarTextoEnCampo:'distrito',
                 allowed_values:async (props,objetoDinamico)=>{

                     try{
                         const {data} = await Axios.get(`${process.env.REACT_APP_ROOT}/api/tablasgenerales/distritos/${props.values.id_region || 1}`)
                         return data.filter(item=>item.id_distrito==objetoDinamico.usuario.id_distrito)
                         .map(item=>{return {id:item.id_distrito,nombre:item.nombre}})
                         .map(item=>{
                             return Object.values(item)
                         })
                     }catch(err){
                         console.log(err)
                         alert('Se produjo un error al cargar las regiones')
                     }
                 },
                 label:'Distrito',
                 place:null,
                 grupo:'Región y distrito actuales',
                 REG_EX:REG_EX.NUMERIC
             },  
             {   
                 name:'distrito',
                 visible:false,
                 required:true, // Es requerido pero no visible, se carga cada vez que cambia el select de distrito
                 initial_value:'', 
             }

]

    // En el value de Provider va todo lo que deseo compartir con los children
    return <Provider value={{usuario,campos_formulario,objetoDinamico,actualizarObjetoDinamico}}> 
        <div>
            {children}
        </div>
    </Provider>
}

const callbackFilterDinamicoRegiones = (objetoDinamico)=>{
    if(objetoDinamico?.otraRegion){
        return (item)=>item.id_region==objetoDinamico.otraRegion.id_region
    }else{
        return (item)=>item.id_region==objetoDinamico.usuario.id_region
    }
}