import React, {useState, useEffect,useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Main from '../componentes/Main';
import {Link} from 'react-router-dom'
import Qrcode from 'qrcode.react';
import Axios from 'axios';
import Logo from '../componentes/Logo'
import Loading from '../componentes/Loading';
import {useAlumno} from '../Context/alumnoContext';
import {estatutos} from '../Helpers/jsons'
import {FormGroup,FormControlLabel , Grid, Box ,Typography,Button,Checkbox,makeStyles,Card,Chip} from "@material-ui/core";
import {hacerScroll} from '../Helpers/utilidades-globales'
import {CssBaseline,AppBar,Toolbar,Drawer,Divider,List,ListItem,ListItemIcon,ListItemText} from "@material-ui/core";
import MailIcon  from '@mui/icons-material/Mail';
import InboxIcon  from '@mui/icons-material/Inbox';
import PrintIcon  from '@mui/icons-material/Print';
import {ListItemButton} from '@mui/material'
import {Alert} from '@mui/material'
//import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2';
import { imprimir } from '../impresiones/compromiso'
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;


export default function Estatutos ({ocultar,usuario}){

const [url,setUrl] = useState(null);
const [archivos, setArchivos]= useState([])
const [status, setStatus]= useState(false)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const {confirmarCargandoVista,verMenu,parametros,obtenerObrero} = useAlumno()
const [ultimaLectura,setUltimaLectura] = useState(1)
const [statuscmin,setStatuscmin] = useState({})
const [contadorOperaciones,setContadorOperaciones] = useState(null)
const [compromisoActualConfirmado,setCompromisoActualConfirmado] = useState(false)

//const history = useHistory()
const navigate = useNavigate()


const useStyle = makeStyles({
    tituloGrande: {
        marginTop: '2rem',
        marginBottom:'2rem'
    },
    titulo:{
        marginTop:'1.5rem',
        marginBottom:'1.5rem'
    },
    punto:{
        marginTop:'1rem',
        marginBottom:'1rem'
    }
  });
  const classes = useStyle();

useEffect(()=>{
    const buscarStatus = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/statuscmin/${usuario.id_obrero}`)
            setCompromisoActualConfirmado(data.id_ultimo_cm_aceptado  && (Number(data.id_ultimo_cm_aceptado)==Number(data.id_ultimo_cm)))
            setStatuscmin(data)
        }catch(err){
            console.log(err)
        }
    }

   // buscarArchivo() // NO FUNCIONA
   // buscarStatus()
    setCompromisoActualConfirmado(false)
},[contadorOperaciones])

const confirmarestatutos = async ()=>{

   alert('hacer algo cuando confirme')

}

    if(buscandoDatos || !statuscmin){
        return <Main center><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    }
/*
    if(estatutos.version!=statuscmin.id_ultimo_cm)
    {
        return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={3}>
            <Alert variant="filled" severity="warning">
               {`La versión (${versionFomarmateada(estatutos.version)}) del presente compromiso ministerial no coincide con la última versión publicada por la sede central (${statuscmin.id_ultimo_cm}) `}
            </Alert>
        </Grid>   
         
      </Grid> 
    }
*/
return <Grid container>
    {/*<Grid item xs={12} sm={2}>
        <Box sx={{position:'sticky',top:'0',maxWidth:'150px',display: { xs: "none", sm: "none", md:'block' }}}>
                <p style={{marginBottom:'1rem'}}>Índice:</p>
                {estatutos.secciones
                    .map((item,index)=><>
                        <p style={{marginTop:'1rem',cursor:'pointer'}} onClick={()=>hacerScroll(`tit-0${index}`)} >
                            {item.titulo.slice(item.titulo.indexOf('.',0)+1).replace(':','')}
                        </p>
                    </>)}
                    <BotonImprimir compromiso={estatutos}/>
        </Box>
    </Grid>*/}
    <Grid item xs={12} sm={10} >
    <Box sx={{padding: document.documentElement.clientWidth < 482 ? '0.2rem':'2rem'}}>
       
        <BotonImprimir compromiso = {estatutos}/>

        {/*
            <> 
        <p>Ultima versión publicada: {versionFomarmateada(statuscmin.id_ultimo_cm)}</p>
        <p>Ultima versión aceptada: {statuscmin.id_ultimo_cm_aceptado ? `${statuscmin.id_ultimo_cm_aceptado}.0` : 'Ninguna' }</p>            
            </>
        */}


        <Box sx={{textAlign:'center'}} />
            {!compromisoActualConfirmado && <div className='mt-4'>
            <Alert variant="outlined" severity="error">
                Aún no ha confirmado el compromiso ministerial actual. A partir del 01/08/2022 no podrá utilizar la credencial.
            </Alert>
            </div>}
            {compromisoActualConfirmado && <div className='mt-4'>
            <Alert variant="outlined" severity="info">
                El compromiso ministerial actual ya ha sido confirmado. Puede utilizar la credencial.
            </Alert>
            </div>}
           <Typography className={classes.titulo} variant='h5'>{estatutos.titulo}</Typography>
           <Typography className={classes.titulo} variant='body1'>{estatutos.observaciones}</Typography>
           <Typography variant='body1'>Versión: {versionFomarmateada(estatutos.version)}</Typography>
           <Typography variant='body1'>Válido a partir del {estatutos.fecha}</Typography>
           <Typography variant='h6'>{estatutos.subtitulo}</Typography>
        <Box />
            {estatutos.secciones
                    .filter((item,index)=>index<ultimaLectura || compromisoActualConfirmado)
                    .map((item,index)=><Card  style={{background:'#F5F5F5',borderRadius:'10px',padding:'0.5rem',marginBottom:'0.5rem'}} variant="outlined">
                        <Typography variant="h5" className={classes.tituloGrande}>{item.tituloGrande}</Typography>
                        <Typography id={`tit-0${index}`} variant='h5' className={classes.titulo}>{item.titulo}</Typography>
                        <Typography variant='h6'>{item.subtitulo}</Typography>
                        {item.puntos.map(punto=><div>
                            <Typography  className={classes.punto} variant='body1'><span style={{fontWeight:'700'}}>{punto.id ? `${punto.id} - ` : ''} </span><span style={{whiteSpace:'break-spaces'}}>{punto.texto}</span></Typography>
                        </div>)}
                    </Card>)}

        {!compromisoActualConfirmado && ultimaLectura<estatutos.secciones.length &&<Box sx={{marginTop:'2rem',display:'flex', justifyContent:'center'}}> 
            <Button variant="contained" color='primary' size='large'  onClick={()=>setUltimaLectura(ultimaLectura+1)}>
                <Box>
                    <Typography variant='body1'>Continuar a la sección:</Typography>
                    <Typography variant='body1'>{estatutos.secciones[ultimaLectura].titulo}</Typography>
                </Box>                            
            </Button>    
        </Box>}
    </Box>
                            
        {!compromisoActualConfirmado && (ultimaLectura==estatutos.secciones.length) && <Confirmacion secciones={estatutos.secciones} confirmar={confirmarestatutos}/>}
    </Grid>
</Grid>

    return     <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar
      position="fixed"
      sx={{ width: "100px", ml: `${drawerWidth}px` }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Permanent drawer
        </Typography>
      </Toolbar>
    </AppBar>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
    >
      <Toolbar />
      <Typography paragraph>
      Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.      </Typography>
      <Typography paragraph>
      Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.      </Typography>
    </Box>
  </Box>




}

const Confirmacion = ({secciones,confirmar})=>{

    const [elementosSeleccionados, setElementosSeleccionados]= useState({})

    useEffect(()=>{
        const arrayObjetosSeleccionados = Object.entries(elementosSeleccionados)

        const arrayVerificador = arrayObjetosSeleccionados.filter(item=>item[1]==true)

    },[elementosSeleccionados])

    const handleCheck = (e)=>{
       
        if(e.target.checked){
            setElementosSeleccionados({...elementosSeleccionados,[e.target.name]:true})
        }else{
            setElementosSeleccionados({...elementosSeleccionados,[e.target.name]:false})
        }
    }

    const nroElementosOK = ()=>{
        const arrayObjetosSeleccionados = Object.entries(elementosSeleccionados)

        const arrayVerificador = arrayObjetosSeleccionados.filter(item=>item[1]==true)

        return arrayVerificador.length == secciones.length

    }

    return <Box sx={{marginTop:'2rem',display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center '}}> 
    <h4>Para finalizar por favor confirme que ha leido todas las secciones del documento marcando la casilla correspondiente</h4>
    <Grid container style={{maxWidth:'500px'}}>
        <Grid item xs={12} >
            <Box>
                {secciones.map((item,index)=><Box><FormGroup>
                    <FormControlLabel control={<Checkbox onChange={handleCheck} name={`sec-${index}`}/>} label={item.titulo}/>
                </FormGroup> 
                </Box>)}
            </Box>
        </Grid>
        <Grid item xs={12}>
             <Button onClick={confirmar} style={{marginTop:'1rem',marginBottom:'1rem'}} disabled={!nroElementosOK()} variant="contained" color='primary' size='large'>
                 Acepto los estatutos DE LA UNIÓN DE LAS ASAMBLEAS DE DIOS
            </Button>
        </Grid>
    </Grid>
</Box>
}

const versionFomarmateada = (version)=>{
    return `${version}.0`
}

const BotonImprimir = ({compromiso})=>{
    return  <Box  sx={{margin:'1rem'}}><Button onClick={()=>imprimir(compromiso,`Compromiso ministerial versión ${estatutos.version}-0`,`Compromiso ministerial versión ${estatutos.version}-0`)} variant="outlined" startIcon={<PrintIcon />}>
    Imprimir
</Button></Box>
}