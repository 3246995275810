import React, {useContext,useEffect,useState} from 'react'
import {Checkbox, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {useAlumno} from '../../../Context/alumnoContext'
import REG_EX from '../../../Helpers/regex'
import {hacerFoco} from '../../../Helpers/utilidades-globales'
import {Fecha} from '../../../componentes/MUI/Fecha';
import { LoaderCircular } from '../../../componentes/LoaderCircular'

export const DatosPersonales = ({objetoModificacion,
                                tiposDoc,
                                estadosCiviles,
                                nacionalidades,
                                paso_cumplido,validacion,validar})=>{
    
    const [id_nacionalidad,set_id_nacionalidad]=useState(objetoModificacion?.obj?.nacionalidad?.id || null)
    const [id_tipo_doc,set_id_tipo_doc]=useState(objetoModificacion?.obj?.tipo_doc?.id || null)
    const [id_estado_civil,set_id_estado_civil]=useState(objetoModificacion?.obj?.estado_civil?.id || null)
    const [fecha_nac,setFecha_nac]=useState(objetoModificacion?.obj?.fecha_nacimiento || null)
    const {snackbar} = useAlumno();
    const [pasos,setPasos] =useState({});
    const [espera,setEspera] =useState(false);
    const [avisar,setAvisar] =useState({});

    React.useEffect(()=>{
        if(!pasos.uno && objetoModificacion.obj?.nombre!=undefined && objetoModificacion.obj?.apellido!=undefined){
            setEspera(true)
            setTimeout(() => {
                setPasos({...pasos,uno:true})
                setEspera(false)
            }, 5000);
        }
        if(pasos.uno==true && !pasos.dos && 
            objetoModificacion.obj?.fecha_nacimiento!=undefined && 
            objetoModificacion.obj?.tipo_doc!=undefined && 
            objetoModificacion.obj?.dni!=undefined){
            setEspera(true)
            setTimeout(() => {
                setPasos({...pasos,dos:true})
                setEspera(false)
            }, 5000);
        }

    },[objetoModificacion])

    React.useEffect(()=>{
        if(id_nacionalidad && nacionalidades.length>0){
            const seleccion = nacionalidades.filter(item=>item.id==id_nacionalidad)

            if(seleccion.length>0){
                objetoModificacion.set({...objetoModificacion.obj,
                                        nacionalidad:{
                                            id: seleccion[0].id,
                                            nombre:seleccion[0].nombre
                                        }})
            }else{
                snackbar.showMessage('No se pudo determinar la nacionalidad del ministro','error')
            }
        }
    },[id_nacionalidad])

    React.useEffect(()=>{
        if(id_tipo_doc && tiposDoc.length>0){
            const seleccion = tiposDoc.filter(item=>item.id==id_tipo_doc)

            if(seleccion.length>0){
                objetoModificacion.set({...objetoModificacion.obj,
                                        tipo_doc:{
                                            id: seleccion[0].id,
                                            nombre:seleccion[0].nombre
                                        }})
            }else{
                snackbar.showMessage('No se pudo determinar el tipo de documento del ministro','error')
            }
        }
    },[id_tipo_doc])

    React.useEffect(()=>{
        if(id_estado_civil && estadosCiviles.length>0){
            const seleccion = estadosCiviles.filter(item=>item.id==id_estado_civil)

            if(seleccion.length>0){
                if((objetoModificacion.obj?.estado_civil?.id == 3 || objetoModificacion.obj?.estado_civil?.id == 6)){
                        objetoModificacion.set({...objetoModificacion.obj,
                            estado_civil:{
                                id: seleccion[0].id,
                                nombre:seleccion[0].nombre
                            }})

                            setTimeout(() => {
                                hacerFoco('ub-conyuge')
                            }, 500);
                }else{
                        objetoModificacion.set({...objetoModificacion.obj,
                            nombreconyuge:'',
                            emailconyuge:'',
                            estado_civil:{
                                id: seleccion[0].id,
                                nombre:seleccion[0].nombre
                            }})
                }
                
            }else{
                snackbar.showMessage('No se pudo determinar la nacionalidad del ministro','error')
            }
        }
    },[id_estado_civil])

    React.useEffect(()=>{
        if(id_nacionalidad && nacionalidades.length>0){
            const seleccion = nacionalidades.filter(item=>item.id==id_nacionalidad)

            if(seleccion.length>0){
                objetoModificacion.set({...objetoModificacion.obj,
                                        nacionalidad:{
                                            id: seleccion[0].id_nacionalidad,
                                            nombre:seleccion[0].nombre
                                        }})
            }else{
                snackbar.showMessage('No se pudo determinar la nacionalidad del ministro','error')
            }
        }
    },[id_nacionalidad])

    React.useEffect(()=>{
       
                objetoModificacion.set({...objetoModificacion.obj,
                                        fecha_nacimiento:fecha_nac})
        },[fecha_nac])

    const handleChange = (e,regex,noupper)=>{

        if(e.target.value === '' || ((regex && regex?.test(e.target.value)|| !regex))){
            const obj_aux = {...objetoModificacion.obj}

            if(noupper){
                obj_aux[e.target.name]=e.target.value;
            }else{
                obj_aux[e.target.name]=e.target.value.toUpperCase();
            }
    
            objetoModificacion.set({...obj_aux})
        }
    }
    
const changeOnblur = (e)=>{
    if(!e.target.value){
        const aux = {...avisar};
        aux[e.target.name] = true
        setAvisar(aux)
    }else{
        const aux = {...avisar};
        aux[e.target.name] = false
        setAvisar(aux)
    }
}

const checkvalidacion = (campo,validacion)=>{
    if (!validar) return ''

    const objetoValidacion = validacion(validar)
    const resultado = objetoValidacion[0];
    const texto = objetoValidacion[1]
    const campos = objetoValidacion[2]

    if(resultado==true || !(campos instanceof Array) || campos.length==0){
        return ''
    }
    if(campos.some(item=>item.campo==campo)){
        return campos.filter(item=>item.campo==campo)[0]?.texto || ''
    }else{
        return ''
    }
}

return <Box sx={{marginTop:'20px'}}>
        {<h3>¿Cómo se llama el nuevo ministro?</h3>}
        <p>(Asegúrese de ingresar los nombres y apellidos completos)</p>
        <Grid container>
            <Grid item xs={12}> 
                <div className='form-card'><div>Nombres:<span className='c-obl'></span></div>
                    <TextField 
                            onBlur={changeOnblur} 
                            //label={'Nombre'} 
                            //disabled={ministroSeleccionado ? true : false} 
                            fullWidth={true} 
                            autoFocus={true}
                            type='text' 
                            id='ub-nombre'
                            name='nombre'
                            inputProps={{
                                maxLength:100
                            }}
                            value={objetoModificacion.obj?.nombre || ''} 
                            placeholder='Tu respuesta' 
                            onChange={(e)=>{
                                handleChange(e,REG_EX.ALPHABETIC)
                            }}
                    />
                    <FormHelperText className="Mui-error">{checkvalidacion('nombre',validacion)}</FormHelperText>
                    {/*(paso_cumplido == true || avisar['nombre']) && !objetoModificacion.obj?.nombre && <FormHelperText className="Mui-error">Falta ingresar el nombre</FormHelperText>}*/}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className='form-card'><div>Apellidos: <span className='c-obl'></span></div>
                    <TextField 
                            onBlur={changeOnblur} 
                            //label={'Apellido'} 
                            //disabled={ministroSeleccionado ? true : false} 
                            fullWidth={true} 
                            type='text' 
                            id='ub-apellido'
                            name='apellido'
                            inputProps={{
                                maxLength:100
                            }}
                            value={objetoModificacion.obj?.apellido || ''} 
                            placeholder='Tu respuesta' 
                            onChange={(e)=>{
                                handleChange(e,REG_EX.ALPHABETIC)
                            }}
                    />
                    <FormHelperText className="Mui-error">{checkvalidacion('apellido',validacion)}</FormHelperText>
                    {/*(paso_cumplido == true || avisar['apellido']) && !objetoModificacion.obj?.apellido && <FormHelperText className="Mui-error">Falta ingresar el apellido</FormHelperText>*/}
                </div>
            </Grid>
        </Grid>

       <div>
            {/*<div style={{wordWrap:'break-word'}}>{JSON.stringify(objetoModificacion)}</div>*/}
            {/*espera && <div style={{left:'0',position:'absolute',width:'100%'}}>
                <LoaderCircular/>
            </div>*/}
             <Grid item xs={12} style={{textAlign:'center'}}>
                    <FormControl>
                        <FormLabel style={{marginTop:'30px',marginBottom:'20px'}}>Sexo<span className='c-obl'></span></FormLabel>
                        <RadioGroup
                            value={objetoModificacion.obj.sexo}
                            onChange={(e)=>{
                                    objetoModificacion.set({...objetoModificacion.obj,sexo:e.target.value})
                            }}>
                                <Box sx={{display:'flex',justifyContent:'center'}}>
                                    <FormControlLabel value="M" control={<Radio />} label="Hombre" />
                                    <FormControlLabel value="F" control={<Radio />} label="Mujer" />
                                </Box>
                        </RadioGroup>
                    </FormControl>
                    <FormHelperText className="Mui-error">{checkvalidacion('sexo',validacion)}</FormHelperText>
            </Grid>
        </div>
</Box>

}





