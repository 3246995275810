import React, {useEffect,useState,useContext} from 'react'
import { Box,Typography,Button, Snackbar} from "@material-ui/core";
import { AlertDialog } from '../../../componentes/MUI/AlertDialog';
import {FormularioTramite} from '../../FormularioTramite'
import {TramiteContextProvider} from '../contexto/tramiteContext'
import {useTramite} from '../hooks/useTramite'
import {LoaderCircular} from '../../../componentes/LoaderCircular'
import {useAlumno} from '../../../Context/alumnoContext'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Iglesias} from '../componentes/Iglesias'
import {Cuestionario2} from '../componentes/Cuestionario2'
import Alert from '@mui/material/Alert';
import regex from '../../../Helpers/regex'
import {doPostFile,eliminarArchivos} from '../../../Helpers/utilidades-globales'
import { SubirArchivo } from '../../../componentes/formulariosFormik/SubirArchivo';
import { ActaAfiliacion } from '../componentes/ActaAfiliacion';
import { imprimir } from '../../../impresiones/compromiso'
import PrintIcon  from '@mui/icons-material/Print';
import { afiliacion, afiliacion as documento } from '../../../Helpers/jsons';

let adjuntos = [];

export const InicioTramite = ({finalizar})=>{
    const [loadingFile,setLoadingFile] = React.useState(false);
    const {usuario,snackbar} = useAlumno();
    const {campos_formulario,anexos,afiliacionExistente} = useTramite();
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario});
    const [continuar,setContinuar]=useState(false);
    const [continuar0,setContinuar0]=useState(false);
    const [validarCampos,setvalidarCampos]=useState(false);
    const [actaFile, setActaFile] = React.useState(null);
    const [actaURL, setActaURL] = React.useState(null);

  useEffect(()=>{
    if(continuar){
        imprimir(documento,`Declaración de afiliación ${documento.version}-0`,`Declaración de afiliación versión ${documento.version}-0`,'DECLARACIÓN DE AFILIACIÓN')
    }
  },[continuar])

  const callbackPrevio = async ()=>{

    if(objetoDinamico.iglesia.id==undefined)
    {
      return [false,'Falta identificar la iglesia']
    }

    if(!objetoDinamico.descarga || objetoDinamico.descarga=='NO')
    {
      return [false,'Falta confirmar que ha leído y descargado la declaración de afiliación']
    }

    return [true]
} 

   
    const validarCondicionesIniciales = async ()=>{
      // agregar validaciones si las hay

      try{
        const resultado = await afiliacionExistente(objetoDinamico.iglesia.id)
        if(resultado[0]){
            return [true]
          }else{
            return [false,resultado[1]]
          }
      }catch(err){
        return [false,err]
      }
    }

    const iniciarContinuar = async ()=>{
      
      const res = await validarCondicionesIniciales();

      if(!res[0]){
        alert(res[1])
        return
      }

      setContinuar0(true)
    }

    const validarCondicionesInicialesPaso1 = (validar)=>{

      const validaciones = [];
      
     if(!objetoDinamico?.descarga || objetoDinamico.descarga=='NO'){
        validaciones.push({texto:'Falta confirmar que ha leído y descargado la declaración de afiliación',campo:'descarga'}) 
      }

     if(validar && validaciones.length>0){
          return [false,'Falta confirmar que ha leído y descargado la declaración de afiliación',validaciones]
     }

    return [true]
  }

    const validarCondicionesInicialesPaso2 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.provincia?.id==undefined){
        //return [false,'Falta identificar la provincia del nuevo ministro']
        validaciones.push({texto:'Falta identificar la provincia del nuevo ministro',campo:'provincia'})
      }

      if(objetoDinamico?.partido?.id==undefined){
        //return [false,'Falta identificar el partido del nuevo ministro']
        validaciones.push({texto:'Falta identificar el partido del nuevo ministro',campo:'partido'})
      }

      if(objetoDinamico?.id_region==undefined || Number(objetoDinamico?.id_region)<1){
        //return [false,'Falta identificar la región del nuevo ministro']
        validaciones.push({texto:'Falta identificar la región de la iglesia vinculada',campo:'region'})
      }

      if(objetoDinamico?.distrito?.id==undefined || Number(objetoDinamico?.distrito?.id)<1){
//        return [false,'Falta identificar el distrito del nuevo ministro']
        validaciones.push({texto:'Falta identificar el distrito de la iglesia vinculada',campo:'distrito'})
      }

      if(objetoDinamico?.iglesia?.id==undefined || objetoDinamico?.iglesia?.nombre==undefined || !(objetoDinamico?.iglesia?.id) || !(objetoDinamico?.iglesia?.nombre)){
        //        return [false,'Falta identificar el distrito del nuevo ministro']
                validaciones.push({texto:'Falta identificar a la iglesia',campo:'iglesia'})
      }
    
      if(validar && validaciones.length>0){
        console.log('validaciones',validaciones)
        return [false,'Hay errores en los datos de ubicación',validaciones]
       }

      return [true]

    }

    const callbackValidate = async (values)=>{
/*
      if(!campoAdicional)
      {
        return [false,'Mensaje']
      }

      const {data} = await consulteAlgoAlBackend()

      let validado = true;

      const campox = data.campox;

      if(!campox){
        validado = false
      }

      if(!validado){
          return [false,`Verifique...${JSON.stringify(values)}`]
      }
*/

      /*
      // Validación de datos a enviar a la mutacion + values del formik
      const validacionEncargado = validarEncargado(encargado);

      if(validacionEncargado[0]==false){
          return[false,validacionEncargado[1]]
      }

      */

      setvalidarCampos(true)
      let validacion_parcial;
    
      validacion_parcial = validarCondicionesInicialesPaso2(true);

      if(validacion_parcial[0]==false){
          return[false,validacion_parcial[1]]
      }

      validacion_parcial = validarCondicionesInicialesPaso1(true);

      if(validacion_parcial[0]==false){
          return[false,validacion_parcial[1]]
      }

      if (validacion_parcial[0]==false){
        return [false,validacion_parcial[1]]
      }else{
        return [true]
      }

    }

    const validarCondicionesInicialesPaso5 = (validar)=>{

      const validaciones = [];

        if(objetoDinamico?.descarga==undefined || objetoDinamico.descarga=='NO'){
        //return [false,'Falta responder sobre el diezmo']
        validaciones.push({texto:'Falta confirmar que ha leído y descargado el acta de afiliación',campo:'descarga'})
      }

      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de confirmación',validaciones]
       }

      return [true]

    }

    // esto es para ver los datos del usuario
    /*return <Box>
          {Object.entries(usuario).map(item=>{
              return <p>{item[0]} ---- {item[1]}</p>
          })}
    </Box>
    */

    /*
    FUE NECESARIO agregar un flag para que luego de seleccionar el ministroSolicitud
    no renderice inmediatamente el componente FormularioTramite.
    Sucedía que apenas se seleccionaba un ministroSolicitud se renderizadaba el formik
    antes de que se actualice el objeto objetoDinamico con la información dinámica que se necesita
    del contexto para poder cargar correctamente los selects de status, region, distrito y ministro.
    Se usa un flag de "Continuar" para que renderice algo antes y de tiempo a que se ejecuten los
    useEffects de éste componente y se actualice el objetoDinmico antes de renderizar el formik.
    El flujo es, primero se renderiza, luego se ejecutan los useEffects entonces hay situaciones en que
    hay que implementar lógica para que cada acción sea en el momento adecuado.
    En este caso 1) seleccionar un ministroSolicitud, luego actualizar objetiDinamico luego renderizar
    el formulario formik con el contexto dinámico correcto.
    Sucedía que primero montaba el formulario, renderizaba el mismo, ejecutaba un useEffect del formik
    y luego procesaba los useEffects del componente InicioTramite y quedaba mal sincronizado el proceso.
    Pensaba que era un problema de closures con las funciones allowedValues de campos_formuularios
    pero no era un closure sino un problema de renderizado antes de tiempo del componente.
    Se resolvió con un flag y un componente de confirmación previo.
    */

  const CustomSubmit = ({submit,values,props,validate})=>{
    const [esperarRespuesta,setEsperarRespuesta] = React.useState(false)
    const [codigo,setCodigo] = React.useState('1234')
    const [envios,setEnvios] = React.useState(0)
    const [hayErrores,setHayErrores] = React.useState(false)
    const [enviando,setEnviando] = React.useState(false)

    const errores = Object.values(props?.errors);
    const erroreskeys = Object.keys(props?.errors);

    /*if (errores.length>0){
      props.setTouched({nombre_encargado:true});
      props.setSubmitting(true)
    }*/

    useEffect(()=>{
      props.setSubmitting(true)
      /*Object.keys(props?.errors).forEach(item=>{
        props.setFieldTouched([item], true,true)      
      })*/
// hago esto para forzar a que sean "tocados" todos los campos aún si no se es el 
// caso para provocar la validación de los campos.

      Object.keys(props?.values).forEach(item=>{
        props.setFieldTouched([item], true,true)      
      })

    },[])

    const validarpaso = ()=>{
            if(validate){
                const check = validate(values,snackbar)
            }
  
            if(errores.length>0){

              props.setSubmitting(true)

              Object.keys(props?.errors).forEach(item=>{
                  props.setFieldTouched([item], true,true)      
              })
              console.log(errores)
              snackbar.showMessage('Hay errores en el formulario','error')
            } else{
              callbackValidate(values)
              .then(resultado=>{
                      if (resultado[0]==false){
                          snackbar.showMessage(resultado[1],'error')
                          //alert(resultado[1],'error')
                          return 
                      }else{
                          //submit()
                          setEsperarRespuesta(true)
                      }
              })
            }
    }

    const handlechange =(e)=>{
       if(regex.NUMERIC.test(e.target.value)){
          setCodigo(e.target.value)
       }
    }

    const verificarCodigo = async ()=>{
      if(codigo==='1234'){
        submit()
      }else{
        alert('El código es incorrecto')
      }
     
  }

    const enviarCodigo = async ()=>{
        alert('Se envió el código')
    }

    return  <Box>
            <Button onClick={validarpaso} style={{background:'steelblue'}} variant="outlined">
                Continuar
            </Button>

            <AlertDialog
                titulo="Submit custom"
                open={esperarRespuesta}
                procesarCancelar={()=>setEsperarRespuesta(false)}
                //procesarResultado={()=>alert('Procesando')}
            >
                <Box>
                    <p>Submit custom</p>
                    <p>Se ejecuta este componente opcional si hay alguna operación a hacer antes de procesar el formulario</p>
                    <p>Se recibe el callback submit y el botón Ejecutar algo si es válido el proceso lo ejecuta si no lo cancela...</p>
                    {/*<p style={{wordWrap:'break-word'}}>{JSON.stringify(values)}</p>*/}
                    <Box style={{marginTop:'20px', marginBottom:'20px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <Button onClick={enviarCodigo} variant="outlined" color="secondary">
                              {envios==0 ? 'Hacer algo' : 'Volver a hacer algo'}
                        </Button>
                        {enviando && <LoaderCircular/>}
                    </Box>

                    <Box style={{marginTop:'20px', marginBottom:'20px',display:'flex',justifyContent:'space-evenly'}}>
                        <input id="verif-cod" maxLength={6} value={codigo} placeholder='Ingrese el código aquí' onChange={handlechange} style={{border:'solid 1px red',padding:'10px'}} type="text"/>
                        {<Button onClick={verificarCodigo} style={{background:'steelblue'}} variant="outlined">
                              Ejecutar algo
                        </Button>}
                    </Box>

                </Box>
            </AlertDialog>
    </Box> 
   
  }

  if(continuar0==false){
    return <Box>
        <Box sx={{margin:'5px',padding:'5px'}}>
            <Typography style={{marginTop:'25px'}} variant="h5">Solicitud de afiliación</Typography>
            <Typography style={{marginTop:'25px'}} variant="h6">1  - Seleccione la iglesia (Autónoma o En Formación) </Typography>
            {anexos.length >0 && <Box>
              <Iglesias usuario={usuario} 
                anexos = {anexos}
                objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
                validacion={validarCondicionesInicialesPaso2}
               />
              {/*<Alert variant="filled" severity="info">
                  <p>Texto {usuario.iglesias}</p> 
                  <br />
            </Alert>*/}
              <br />
              {/*<div>
                  <b>Recuerde que se solicitará la siguiente documentación:</b>
                  <ul className='list-req-1cr mt-4'>
                      <li>Acta de la Iglesia avalando la autonomía, la cual debe escanearse y adjuntarse en formato PDF</li>
                  </ul>
              </div>*/}
            </Box>}
          
        </Box>
        {anexos.length==0 && <Box>
          <Alert variant="filled" severity="info">
                  <p>No se encontraron anexos</p> 
                  <br />
              </Alert>
        </Box>}
        <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
            {anexos.length > 0 && objetoDinamico?.iglesia?.id && <Button variant='outlined' color='primary' onClick={iniciarContinuar}>Continuar</Button>}
            <Button style={{background:'tomato'}} variant="outlined" onClick={finalizar}>
                  cancelar
            </Button>
        </Box>
    </Box>
}

    if(continuar0==true && continuar==false){

      return <Box>
        <Box sx={{margin:'5px',padding:'5px'}}>
        <Typography style={{marginTop:'25px'}} variant="h6">2  - Confirme la iglesia seleccionada. Lea y descargue la declaración de afiliación.</Typography>
        <Box sx={{margin:'5px',padding:'5px'}}>
            <h4>Iglesia seleccionada: <span className='fw-400'>{objetoDinamico.iglesia.nombre}</span></h4>
        </Box>
        <ActaAfiliacion/>
        {/*<Box>
              <h3 className=''>Por favor adjunte el siguiente archivo:</h3>
                <table className='table-adjuntos-1racre'>
                  <tbody>
                    <tr>
                      <td>
                      <SubirArchivo texto={!actaFile ? "" : ''} 
                              titulo={`Acta de autonomía en formato PDF`}
                              file={actaFile}
                              tiposFiles={['.pdf']}
                              maximo={5000000}
                              setFile={(setActaFile)}/>
                        {loadingFile && <LoaderCircular/>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>*/}
        <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
          {actaFile || true && <Button variant='outlined' color='primary' onClick={()=>setContinuar(true)}>Descargar documento y continuar</Button>}
          <Button style={{background:'tomato'}} variant="outlined" onClick={()=>{
                                                            setObjetoDinamico({...objetoDinamico,
                                                              provincia:null,
                                                              partido:null,
                                                              id_region:null,
                                                              distrito:null,
                                                              iglesia:null
                                                            })
                                                            setContinuar0(false)
                                                            }}>
                cancelar
          </Button>
        </Box>
      </Box>  
    </Box>
    }

     if(continuar==true){

      return <TramiteContextProvider>
      <Box  className='form-body-tram'>
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'25px'}}>
              <Typography variant="h5">Solicitud de afiliación</Typography>
              <div className='cursor-pointer text-center ml-2' title='Cerrar' onClick={finalizar}>
                    <FontAwesomeIcon className="color-red text-xlarge"  icon={faTimesCircle}/>
              </div>
        </Box>

        <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
        {/*<p style={{wordWrap:'break-word'}}>{JSON.stringify(objetoDinamico)}</p>*/}

           <Box>
            {/*<h1>UBICAR AQUÍ ALGUN formulario auxiliar al formik</h1>*/}
            {loadingFile && <LoaderCircular/>}
              {/*<Iglesias usuario={usuario} 
                anexos = {anexos}
                objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
                validacion={validarCondicionesInicialesPaso2}
              />*/}
              <Box>
                <h4>Iglesia seleccionada: <span className='fw-400'>{objetoDinamico?.iglesia?.nombre}</span></h4>
              </Box>
              {/*<Box>
              <h3 className=''>Por favor adjunte el siguiente archivo:</h3>
                <table className='table-adjuntos-1racre'>
                  <tbody>
                    <tr>
                      <td>
                      <SubirArchivo texto={!actaFile ? "" : ''} 
                              titulo={`Acta de autonomía en formato PDF`}
                              file={actaFile}
                              tiposFiles={['.pdf']}
                              maximo={5000000}
                              setFile={(setActaFile)}/>
                        {loadingFile && <LoaderCircular/>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>*/}
               <Cuestionario2 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  validar={true}
                  validacion={validarCondicionesInicialesPaso5}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
              {<FormularioTramite cancelar={()=>setContinuar(false)}  // función que se ejecuta al cancelar o terminar de guardar
                                 callbackPrevio = {callbackPrevio} // callback que se ejecuta antes de guardar y puede modificar o agregar campos que se envían a la mutation. Si están en el formik se van a pisar, si no se agregan
                                 objetoDinamico={{...objetoDinamico,anexos}} // este objeto sirve para pasar valores por default a campos del formulario y también para pasar un contexto particular del trámite para que llegue esa info a los inputs que se van creando según la configuración del objeto json de campos del formulario
                                 campos_formulario={campos_formulario}
                                 callbackError={()=>setLoadingFile(false)}
                                 CustomSubmit = {null}
//                                 CustomSubmit = {CustomSubmit}
                                 textoSumbit="Enviar la solicitud de afiliación"
                                 finalizar={finalizar}
                                 setObjetoDinamico = {setObjetoDinamico}
                                 callbackValidatePlus={callbackValidate} // callback de validación adicional custom
                                 useTramite = {useTramite}/>}
          </Box>
      </Box>
  </TramiteContextProvider>
     }
}





const createId = ()=>{
  const d = new Date();
  let dy = d.getDate()< 10 ? `0${d.getDate()}`: d.getDate();
  let mt = d.getMonth() < 10 ? `0${d.getMonth()}`: d.getMonth();
  let yr = d.getFullYear();
  let hs = d.getHours() < 10 ? `0${d.getHours()}`: d.getHours();
  let mn = d.getMinutes() < 10 ? `0${d.getMinutes()}`: d.getMinutes();
  let sc = d.getSeconds() < 10 ? `0${d.getSeconds()}`: d.getSeconds() ;

  const id = `${yr}${mt}${dy}${hs}${mn}${sc}`
  return id
}

const consulteAlgoAlBackend = ()=>{
    return new Promise((exito,rechazo)=>{

      setTimeout(() => {
        if(1<2){
          exito({data:{campox:59}})
        }else{
          rechazo()
        }
      }, 2000);
       
    })
}


const SeleccioneAlgo = ({setAlgoSeleccionado})=>{
    return <select onChange={(e)=>setAlgoSeleccionado(e.target.value)}>
        <option value="algo">algo</option>
        <option value="algo2">algo 2</option>
        <option value="algo3">algo 3</option>
    </select>
}

const validarUbicacion = (values)=>{

  if(!values?.partido?.id){
      return [false,`Falta identificar el partido`]
  }

  if(!values?.provincia?.id){
    return [false,`Falta identificar la provincia`]
  }

  if(!values?.distrito?.id){
    return [false,`Falta identificar el distrito`]
  }

  if(!values?.id_region){
    return [false,`Falta identificar la región`]
  }

  if(!values?.localidad){
    return [false,`Falta ingresar la localidad del nuevo ministro`]
  }

  if(!values?.cod_postal){
    return [false,`Falta ingresar el código postal del nuevo ministro`]
  }

  if(!values?.direccion){
    return [false,`Falta ingresar la dirección del nuevo ministro`]
  }

  return true
}

const numerosIguales = (telefono,celular)=>{
  
  if(typeof telefono == 'string' && typeof celular == 'string' ){
      if(telefono.trim()===celular.trim()){
          return true
      }else{
          return false
      }
  }else{
      return false
  }

}

const BotonImprimir = ({documento})=>{
  return  <Box  sx={{margin:'1rem'}}><Button onClick={()=>imprimir(documento,`Declaración de afiliación ${documento.version}-0`,`Declaración de afiliación versión ${documento.version}-0`,'DECLARACIÓN DE AFILIACIÓN')} variant="outlined" startIcon={<PrintIcon />}>
  Imprimir
</Button></Box>
}