import React, { useEffect, useState} from 'react'
import {Box,Button,TextField,Typography} from "@material-ui/core";
import BuildCircleIcon from '@mui/icons-material/Security';
import WhatsappRoundedIcon from '@mui/icons-material/WhatsappRounded';
import Axios from 'axios'
import {useParams} from 'react-router-dom'
import Alert from '@mui/material/Alert';
import {Loader} from './Loader'
import {AlertDialog} from './MUI/AlertDialog'
import {useAlumno} from '../Context/alumnoContext';
import Login from '../Vistas/Login';

const regex_solo_numeros = /^[0-9\b]+$/;

export const CodigoSeguridad = ({usuario,retorno})=>{
    const [user,setUser] = useState(null)
    const [codigoActual,setCodigoActual] = useState(null)
    const [codigoNuevo,setCodigoNuevo] = useState(null)
    const [confirmacion,setConfirmacion] = useState(null)
    const [cargando,setCargando]= useState(false)
    const [contador,setContador]= useState(0)
    const [error,setError] = useState(null)
    const [errorPaso2,setErrorPaso2] = useState(null)
    const [mensaje,setMensaje] = useState(null)

    const [codigoActualCorrecto,setCodigoActualCorrecto] = useState(true)
    const {esCelular,usuario:usuarioaux} = useAlumno()

    const parametros = useParams()

    useEffect(()=>{
            setError(null)
            setCodigoActualCorrecto(false)
            setConfirmacion(null)
            setCodigoNuevo(null)
            setErrorPaso2(null)
    },[codigoActual])

    useEffect(()=>{
        if(contador>2){
            login()
        }
    },[contador])

    useEffect(()=>{
        if(codigoNuevo==codigoActual){
            setErrorPaso2('El nuevo código debe ser diferente al actual')
            return
        }
        if(codigoNuevo && confirmacion){
            if(codigoNuevo!=confirmacion){
                setErrorPaso2('No coinciden')
                return
            }
        }
        setErrorPaso2(null)
    },[codigoNuevo,confirmacion])

    useEffect(()=>{
        if(usuarioaux.resetpass){
            setErrorPaso2(null)
            setError(null)
            setCodigoActualCorrecto(true)
        }
    },[])

    const validarCodigoActual = async ()=>{
        try{
            const {data} = await Axios.post(`/api/usuarios/validar/codigo`,{nro_documento:usuario.dni,cod_seguridad:codigoActual})

            if (data?.usuario.dni==usuario.dni){
                setError(null)
                setCodigoActualCorrecto(true)
              }else{
                    setError('El código ingresado no coincide con sus credenciales')
                    setContador(contador+1)
                    setCodigoActualCorrecto(false)
              }

        }catch(err){
            setError(err?.response?.data?.message || 'Error en la validación')
            setContador(contador+1)
            setCodigoActualCorrecto(false)
        }
    }

    const handleKeyPress = (e)=>{
        if(e.key === 'Enter'){
            validarCodigoActual()
        }
    }  

    const cambiarCodigo = async ()=>{

        if(!(codigoNuevo && codigoNuevo===confirmacion && codigoNuevo!=codigoActual)){
            alert('Los códigos ingresados son inválidos')
            return
        }
        try{
            const {data} = await Axios.post(`/api/usuarios/actualizar/codigo`,{opcion:usuario.id_obrero,cod_seguridad:codigoNuevo})
            
            setMensaje(data.mensaje)
            
            setTimeout(() => {
                login()
            }, 1000);

        }catch(err){
            setErrorPaso2(err?.response?.data?.message || 'Error en la actualización del código de seguridad')
        }
    }

    const login = ()=>{
        sessionStorage.removeItem('REG_TOKEN')
        setTimeout(() => {
            window.location = '/login'
        }, 1000);
    }
    
    const handleChangeCodigoActual = (e)=>{
        if(regex_solo_numeros.test(e.target.value) || e.target.value==''){
                setCodigoActual(e.target.value.trim())
        }
    }

    const handleChangeCodigoNuevo = (e)=>{
        if(regex_solo_numeros.test(e.target.value) || e.target.value==''){
                setCodigoNuevo(e.target.value.trim())
        }
    }

    const handleChangeConfirmacion = (e)=>{
        if(regex_solo_numeros.test(e.target.value) || e.target.value==''){
                setConfirmacion(e.target.value.trim())
        }
    }

    if (cargando){
        return  <Loader open={true} 
        texto='Cargando...'/>
    }

    if (contador>2){
        return <div style={{minHeight:'350px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Alert style={{fontSize:'large'}} severity="error" variant='outlined'>
                    <p>Superó la cantidad de 3 intentos permitidos</p>
                    <p>Por favor vuelva a loguearse</p>
                </Alert>
        </div>
    }


    if (!codigoActualCorrecto){
        
        return <div style={{minHeight:'350px',minWidth:'280px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        <Box sx={{marginTop:'1rem',marginBottom:'1rem',minWidth:'280px'}}>
            <TextField  onKeyPress={handleKeyPress} autoFocus={!esCelular} fullWidth value={codigoActual} label="Ingrese su código de seguridad actual"  type="password" inputProps={{maxLength: 4}} placeholder="Ingrese su código de seguridad actual" onChange={handleChangeCodigoActual} />
            {codigoActual && contador > 0 && <p>Puede intentar {3-contador} {3-contador>1 ? 'veces' : 'vez'} más</p>}
        </Box>

        {codigoActual && !error && <Box sx={{display:'flex',justifyContent:'space-between', marginTop:'1rem'}}>
            <Button variant="contained" color="primary" onClick={validarCodigoActual}>Continuar</Button>
        </Box>}

        {error && <Alert style={{fontSize:'large'}} severity="error" variant='outlined'>{error}</Alert>}
        </div>
    }

    return <AlertDialog open={codigoActualCorrecto} 
            titulo={usuario.resetpass ? 'Primer ingreso: Reseteo de clave' : 'Cambie su código de seguridad'} 
            error={''}>
                    <Box sx={{width:!esCelular ? '380px' : '100%'}}>
                        {usuario.resetpass && <p className='mb-4'>Por favor elija un código de 4 dígitos que utilizará para acceder a la plataforma cada vez que ingrese.</p>}
                        {usuario.resetpass && <Alert style={{fontSize:'large'}} severity="info" variant='outlined'>Luego será dirigido a la pantalla de login para ingresar con su DNI y el código elegido.</Alert>}
                        {mensaje && <Alert style={{fontSize:'large'}} severity="info" variant='outlined'>{mensaje}</Alert>}
                        
                        {!mensaje && <>
                        <Box sx={{marginTop:'1rem',marginBottom:'1rem'}}>
                            <TextField autoFocus={!esCelular} value={codigoNuevo} inputProps={{maxLength: 4}} type="password" fullWidth placeholder="Nuevo código de seguridad" label="Nuevo código de seguridad" onChange={handleChangeCodigoNuevo} />
                        </Box>        
                        <Box sx={{marginTop:'1rem',marginBottom:'1rem'}}>
                            <TextField value={confirmacion} inputProps={{maxLength: 4}} type="password" fullWidth placeholder="Repita el código" label="Repita el código" onChange={handleChangeConfirmacion} />
                        </Box>
                        
                        {errorPaso2 && <Alert style={{fontSize:'large'}} severity="error" variant='outlined'>{errorPaso2}</Alert>}
                        
                        <Box sx={{display:'flex',justifyContent:'space-between', marginTop:'1rem'}}>
                            {usuario.resetpass==0 && <Button style={{border:'none'}} color="primary" onClick={()=>setCodigoActual(null)}>Cancelar</Button>}
                            {!errorPaso2 && !mensaje && <Button variant="contained" color="primary" onClick={cambiarCodigo}>Cambiar</Button>}
                        </Box>
                        
                        </>}
                    </Box>
                    
        </AlertDialog>

}