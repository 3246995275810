import React,{useState,useEffect} from 'react'
import Axios from 'axios'
import Swal from 'sweetalert2';
import {useAlumno} from '../../../Context/alumnoContext'
import {gql,useQuery,useMutation,useLazyQuery} from '@apollo/client'
import REG_EX from '../../../Helpers/regex';
import moment from 'moment';
import {Comision} from '../componentes/Comision';
import { TramRounded } from '@material-ui/icons';

export const useTramite = ()=>{
    const {usuario} = useAlumno()
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario})
    const [provincias,setProvincias]=useState([])
    const [partidos,setPartidos]=useState([])
    const [ministros,setMinistros]=useState([])
    const [anexos,setAnexos] = useState([])
    const [tiposDoc,setTiposDoc]=useState([])
    const [nacionalidades,setNacionalidades]=useState([])
    const [estadosCiviles,setEstadosCiviles]=useState([])

    React.useEffect(()=>{
        buscarTablas()
    },[])

    const actualizarObjetoDinamico = (campoNuevo)=>{
        setObjetoDinamico({...objetoDinamico,...campoNuevo})
    }

    const CONSULTAR_TRAMITES_POR_TIPO_MINISTRO = gql`
        query consultaTramites($tipo:String!,$usuario_id:Int!){
            tramitesTipoMinistro(tipo:$tipo,id_ministro:$usuario_id){
                id,
                codigo_web,
                fecha,
                status{
                    texto
                }
            }
        }
    `

const CONSULTAR_MINISTROS_POR_MINISTRO = gql`
query consultaMinistros($ministro_id:Int!){
    ministrosPorMinistro(ministro_id:$ministro_id){
        status_actual,
        status,
        id_tramite{
            codigo_web
        }
    }
}
`


    const CREAR_T1 = gql`
mutation nuevoAutonomia($region:Int!,
                        $usuario: String!,
                        $usuario_id:Int!,
                        $descripcion:String!,
                        $distrito:propTypeInput!,
                        $iglesia:propTypeInput!,
                        $actaURL:String!,
                        $condicion_edificio:String!,
                        $miembros_registrados: Int!,
                        $asistencia_promedio: Int!,
                        $nombre_encargado: String!,
                        $credenciales_si_no: Boolean!,
                        $rango: String,
                        $sostenido_iglesia_si_no: Boolean!,
                        $porcentaje_tiempo:Int, 
                        $comision_si_no: Boolean!,
                        $comision:[ComisionTypeInput],
                        $fichero_culto_si_no: Boolean!,
                        $certificado_afiliacion_si_no:Boolean!, 
                        $libro_actas_si_no: Boolean!,
                        $envia_diezmos_si_no: Boolean!,
                        $observaciones: String
                      )
                      {
                        nuevoAutonomia(
                                region:$region,
                                solicitante:$usuario,
                                solicitante_id:$usuario_id,
                                descripcion:$descripcion,
                                distrito:$distrito,
                                iglesia:$iglesia,
                                actaURL:$actaURL,
                                condicion_edificio:$condicion_edificio,
                                miembros_registrados:$miembros_registrados, 
                                asistencia_promedio:$asistencia_promedio,
                                nombre_encargado :$nombre_encargado,
                                credenciales_si_no:$credenciales_si_no, 
                                rango:$rango, 
                                sostenido_iglesia_si_no:$sostenido_iglesia_si_no, 
                                porcentaje_tiempo:$porcentaje_tiempo,
                                comision_si_no:$comision_si_no,
                                comision:$comision,
                                fichero_culto_si_no :$fichero_culto_si_no,
                                certificado_afiliacion_si_no :$certificado_afiliacion_si_no, 
                                libro_actas_si_no :$libro_actas_si_no,
                                envia_diezmos_si_no :$envia_diezmos_si_no,
                                observaciones: $observaciones
                            )}`;

const callback = (values)=>{
        // todo lo que se obtiene de values llega desde el formulario.
        debugger
        const variables = {
            region:Number(values.id_region),
            usuario:usuario.nombre,
            descripcion:`Solicita autonomía del anexo ${values.iglesia.nombre}`,
            usuario_id:Number(usuario?.id_obrero || 685),
            distrito:{id:Number(values.distrito.id),nombre:values.distrito.nombre},
            iglesia:{id:Number(values.iglesia.id),nombre:values.iglesia.nombre},
            actaURL:values.actaURL, // viene del callbackPrevio
            condicion_edificio:values.condicion_edificio,
            miembros_registrados:Number(values.miembros_registrados), 
            asistencia_promedio:Number(values.asistencia_promedio),
            nombre_encargado :values.nombre_encargado,
            credenciales_si_no:values.credenciales_si_no, 
            rango:values.credenciales_si_no ? values.rango : null, 
            sostenido_iglesia_si_no:values.sostenido_iglesia_si_no, 
            porcentaje_tiempo:values.sostenido_iglesia_si_no ? Number(values.porcentaje_tiempo) : null,
            comision_si_no:values.comision_si_no,
            comision:values.comision_si_no ? values.comision : null,
            fichero_culto_si_no : values.fichero_culto_si_no,
            certificado_afiliacion_si_no : values.certificado_afiliacion_si_no, 
            libro_actas_si_no : values.libro_actas_si_no,
            envia_diezmos_si_no : values.envia_diezmos_si_no,
            observaciones: values.observaciones 
        }
 return variables
}

const buscarTablas = async ()=>{
    try{
        const data = await Promise.all([Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/provincias`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/partidos`),
                                       // Axios.get(`${process.env.REACT_APP_ROOT}/api/tablasgenerales/ministros/todos`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/tiposdocumento`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/nacionalidades`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/estadosciviles`),
                                        Axios.get(`api/tablasgenerales/iglesiasobreroall/${usuario.id_obrero}`)])
        
        setProvincias(data[0].data.filter(item=>item.id_provincia>0).map(item=>{
            return {id:item.id_provincia,nombre:item.nombre}
        }))

        setPartidos(data[1].data.partidos)

        /*const data_aux = data[2].data.filter(item=>item?.id_obrero>0)
        .map(item=>{return {id:item?.id_obrero || 685,nombre:item.nombre_obrero}})
        setMinistros(data_aux)
        */
        setTiposDoc(data[2].data.filter(i=>i.id_tipo_doc>0).map(i=>{return{id:i.id_tipo_doc,nombre:i.nombre}}))
        setNacionalidades(data[3].data.filter(i=>i.id_nacionalidad>0).map(i=>{return{id:i.id_nacionalidad,nombre:i.nombre}}))
        setEstadosCiviles(data[4].data.filter(i=>i.id_estado_civil>0).map(i=>{return{id:i.id_estado_civil,nombre:i.nombre}}))
        setAnexos(data[5].data.filter(i=>i.tipo_iglesia=='Anexo').map(item=>{
            return {id:item.id_iglesia,
                    nombre:item.iglesia,
                    id_region:item.id_region,
                    id_distrito:item.id_distrito,
                    id_provincia:item.id_provincia,provincia:item.provincia,
                    id_partido:item.id_partido,partido:item.partido}
        }))
}catch(err){
   alert(err)
}
}

/*const {loading,error,data,refetch} = useQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario.id_obrero,tipo:'ascenso'},
//    pollInterval: 5000000
})
*/
const [listadoTramitesporMinistro,{error,data,loading}] = useLazyQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario?.id_obrero || 685,tipo:'ministro'}})

const [ministrosPorMinistroDetalle] = useLazyQuery(CONSULTAR_MINISTROS_POR_MINISTRO) // las variables las paso como un objeto 
                                                                                    // al ejecutar
const campos_formulario = [
    {   
        name:'nombre_encargado',
        required:true,
        visible:true,
        disabled:false,
        initial_value:'',
        length:50,
        type:'text',
        allowed_values:[],
        label:'Nombre del encargado',
        place:null,
        grupo:'Datos del encargado',
        onChange:(event,handleChange,setFieldValue,values)=>{
            setFieldValue('nombre_encargado',event.target.value.toUpperCase())
        },
        REG_EX:REG_EX.ALPHABETIC
    },
    {   
        name:'credenciales_si_no',
        required:true,
        mustBePresent:true,
        visible:true,
        initial_value:true,
        disabled:false,
        onChange:(props)=>props.values.credenciales_si_no== false ? props.setFieldValue('rango','') : null,
        type:'switch',
        label:'El encargado tiene credenciales',
        place:'Ministerios',
        grupo:'Datos del encargado',
    },
    {   
        name:'rango',
        required:false,
        visible:true,
        disabled:false,
        initial_value:'',
        requiredIf:'credenciales_si_no',
        length:100,
        hide:(props)=>props.values?.credenciales_si_no==false,
        type:'select',
        selectFirst:false,
        allowed_values:[{id:'',value:'Seleccione un valor'},{id:'MOED',value:'Ministro Ordenado Ejecutivo Dorado'},
                        {id:'MOE',value:'Ministro Ordenado Ejecutivo'},
                        {id:'MO',value:'Ministro Ordenado'},
                        {id:'ML',value:'Ministro Licenciado'},
                        {id:'MA',value:'Ministro Autorizado'},
                        {id:'PA',value:'Predicador Autorizado'}],
        label:'Indique cuál es el rango del encargado',
        place:null,
        grupo:'Datos del encargado',
        REG_EX:REG_EX.NUMERIC
    },
    {   
        name:'sostenido_iglesia_si_no',
        required:true,
        mustBePresent:true,
        visible:true,
        disabled:false,
        initial_value:true,
        //onchange:(props)=>props.setFieldValue('porcentaje_tiempo',''),
        onChange:(props)=>props.values.sostenido_iglesia_si_no!= true ? props.setFieldValue('porcentaje_tiempo','') : null,
        type:'switch',
        label:'El encargado será sostenido por la iglesia',
        place:'Ministerios',
        grupo:'Datos del encargado',
    },
    {name:'porcentaje_tiempo',
    required:false,
    visible:true,
    requiredIf:'sostenido_iglesia_si_no',
    disabled:false,
    initial_value:null,
    prefix:'%',
    length:3,
    hide:(props)=>props.values?.sostenido_iglesia_si_no==false,
    type:'text',
    validate:(value)=>{return value!='' ? [false,'id_proveedor es requerido'] : value.length<4? [false,'id_proveedor debe ser tener 4 dígitos']:[true,null]},
    selectFirst:true,
    label:'Porcentaje de tiempo del sostenimiento recibido por el encargado',
    place:null,
    onBlur:(event,handleBlur,snackbar,setFieldError,setFieldValue)=>{
        if(Number(event.target.value)>100){
            setFieldValue('porcentaje_tiempo',100)
        }else{
            setFieldValue('porcentaje_tiempo',Number(event.target.value))
        }
        handleBlur(event)
    },
    grupo:'Datos del encargado',
    REG_EX:REG_EX.NUMERIC
},
    {   
        name:'comision_si_no',
        required:true,
        mustBePresent:true,
        visible:true,
        initial_value:true,
        disabled:false,
        onChange:(props)=>props.setFieldValue('comision',null),
        type:'switch',
        label:'El anexo tiene una comisión',
        place:'Ministerios',
        grupo:'Información general del anexo',
    },
    {   
        name:'comision',
        required:false,
        mustBePresent:true,
        visible:true,
        disabled:false,
        requiredIf:'comision_si_no',
        //initial_value:[{quantity:1,color:'BLANCO'}],
        initial_value:[],
        length:23,
        type:'array',
        allowed_values:[],
        label:'Comisión',
        place:'',
        grupo:'Información general del anexo',
        hide:(props)=>props.values?.comision_si_no==false,
        REG_EX:null,
        component:(props)=><Comision {...props}/>,
        validation:(values,value)=>{
            if(value.length<1 && values.comision_si_no==true){
                return [false,'Debe haber al menos 1 miembro de la comisión']
            }
            return [true,null]
        },
        planilla:()=>{return null}
    },
    {name:'miembros_registrados',
    required:true,
    visible:true,
    disabled:false,
    initial_value:'',
    length:4,
    type:'text',
    selectFirst:true,
    label:'Número de miembros registrados en el anexo',
    place:null,
    grupo:'Asistencia',
    REG_EX:REG_EX.NUMERIC
},
{name:'asistencia_promedio',
    required:true,
    visible:true,
    disabled:false,
    initial_value:'',
    length:4,
    type:'text',
    selectFirst:true,
    label:'Asistencia promedio en el culto principal de la semana',
    place:null,
    grupo:'Asistencia',
    REG_EX:REG_EX.NUMERIC
},
    {   
    name:'condicion_edificio',
    required:true,
    visible:true,
    disabled:false,
    initial_value:null,
    length:100,
    type:'select',
    selectFirst:false,
    allowed_values:[{id:'',value:'Seleccione un valor'},{id:'PROPIEDAD',value:'Propiedad iglesia'},{id:'ALQUILADO',value:'Alquilado'},{id:'COMODATO',value:'Comodato'},{id:'CEDIDO',value:'Cedido'}],
    label:'Condición del edificio',
    place:null,
    grupo:'Información general del anexo',
    REG_EX:REG_EX.NUMERIC
},
{   
    name:'libro_actas_si_no',
    required:true,
    mustBePresent:true,
    visible:true,
    disabled:false,
    initial_value:false,
    type:'switch',
    label:'Tiene libro de actas',
    place:'Ministerios',
    grupo:'Información general del anexo',
},
{   
    name:'fichero_culto_si_no',
    required:true,
    mustBePresent:true,
    visible:true,
    disabled:false,
    initial_value:false,
    type:'switch',
    label:'Tiene fichero de culto',
    place:'Ministerios',
    grupo:'Información general del anexo',
},
{   
    name:'certificado_afiliacion_si_no',
    required:true,
    mustBePresent:true,
    visible:true,
    disabled:false,
    initial_value:false,
    type:'switch',
    label:'Tiene certificado de afiliación',
    place:'Ministerios',
    grupo:'Información general del anexo',
},
{   
    name:'envia_diezmos_si_no',
    required:true,
    mustBePresent:true,
    visible:true,
    disabled:false,
    initial_value:false,
    type:'switch',
    label:'Está enviando diezmos a la sede central',
    place:'Ministerios',
    grupo:'Información general del anexo',
},
{   
    name:'observaciones',
    required:false,
    visible:true,
    disabled:false,
    initial_value:'',
    length:200,
    type:'text',
    allowed_values:[],
    label:'Observaciones',
    place:null,
    grupo:'Información general del anexo',
    multiline:true,
    REG_EX:REG_EX.DESCRIPTION,
},

]

const fechaEsValida = (fecha)=>{
    const fecha_hoy = moment();
    const fechaHoyMenos100 = moment().subtract(100, "years");
    const fecha_aux = moment(fecha)
    if(!(fecha_aux.isBetween(fechaHoyMenos100,fecha_hoy))){
      return false
    }
    return true  
  }

    return {
        usuario,
        mutationPadre:CREAR_T1,
        variables:callback,
        listadoTramitesporMinistro,
        objetoDinamico,
        actualizarObjetoDinamico,
        campos_formulario,
        ministrosPorMinistroDetalle,
        provincias,
        partidos,
        //ministros,
        tiposDoc,
        nacionalidades,
        estadosCiviles,
        fechaEsValida,
        anexos
    }
}

const callbackFilterDinamicoRegiones = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_region==objetoDinamico.otroMinistro.id_region
    }else{
        return (item)=>item.id_region==objetoDinamico.usuario.id_region
    }
}

const callbackFilterDinamicoAnexos = (objetoDinamico)=>{
    return objetoDinamico.anexos
}

const callbackFilterDinamicoDistrito = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_distrito==objetoDinamico.otroMinistro.id_distrito
    }else{
        return (item)=>item.id_distrito==objetoDinamico.usuario.id_distrito
    }
}

const vectorStatusDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.rango,value:objetoDinamico.otroMinistro.rango}]
    }else{
        return [{id:objetoDinamico.usuario.rango,value:objetoDinamico.usuario.rango}]
    }
}

const vectorMinistroDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.id_ministro,value:objetoDinamico.otroMinistro.ministro}]
    }else{
        return [{id:objetoDinamico.usuario?.id_obrero || 685,value:objetoDinamico.usuario.nombre}]
    }
}
