import React, {useContext,useEffect,useState} from 'react'
import {Typography, makeStyles, Box ,Card,Button,Select,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { afiliacion as documento } from '../../../Helpers/jsons';
import { v4 as uuidv4 } from 'uuid';
import { imprimir } from '../../../impresiones/compromiso'
import PrintIcon  from '@mui/icons-material/Print';

export const ActaAfiliacion = ({objetoModificacion,paso_cumplido,validacion,validar})=>{
    
    const useStyle = makeStyles({
        tituloGrande: {
            marginTop: '2rem',
            marginBottom:'2rem'
        },
        titulo:{
            marginTop:'1.5rem',
            marginBottom:'1.5rem'
        },
        punto:{
            marginTop:'1rem',
            marginBottom:'1rem'
        }
      });
      const classes = useStyle();

      
return <Box sx={{marginTop:'20px'}}>
    <Box sx={{textAlign:'center'}} />
           <Typography className={classes.titulo} variant='h5'>{documento.titulo}</Typography>
           <Typography className={classes.titulo} variant='body1'>{documento.observaciones}</Typography>
           <Typography variant='body1'>Versión: {versionFomarmateada(documento.version)}</Typography>
           <Typography variant='body1'>Válido a partir del {documento.fecha}</Typography>
           <Typography variant='h6'>{documento.subtitulo}</Typography>
        <Box />

        {/*documento && <BotonImprimir documento = {documento}/>*/}

            {documento.secciones
                    .map((item,index)=><Card key={uuidv4()} style={{background:'#F5F5F5',borderRadius:'10px',padding:'0.5rem',marginBottom:'0.5rem'}} variant="outlined">
                        <Typography variant="h5" className={classes.tituloGrande}>{item.tituloGrande}</Typography>
                        <Typography id={`tit-0${index}`} variant='h5' className={classes.titulo}>{item.titulo}</Typography>
                        <Typography variant='h6'>{item.subtitulo}</Typography>
                        {item.puntos.map(punto=><div key={uuidv4()}>
                            <Typography  className={classes.punto} variant='body1'><span style={{fontWeight:'700'}}>{punto.id ? `${punto.id} - ` : ''} </span><span style={{whiteSpace:'break-spaces'}}>{punto.texto}</span></Typography>
                        </div>)}
                    </Card>)}
</Box>

}

const versionFomarmateada = (version)=>{
    return `${version}.0`
}

const BotonImprimir = ({documento})=>{
    return  <Box  sx={{margin:'1rem'}}><Button onClick={()=>imprimir(documento,`Declaración de afiliación ${documento.version}-0`,`Declaración de afiliación versión ${documento.version}-0`,'DECLARACIÓN DE AFILIACIÓN')} variant="outlined" startIcon={<PrintIcon />}>
    Imprimir
</Button></Box>
}