import React, { useState,useEffect } from 'react'
import { MuiCheckBoxes } from '../../../componentes/formulariosFormik/MuiCheckBoxes';
import {Button, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import {hacerFoco} from '../../../Helpers/utilidades-globales';
// Es un componente que "sabe" como manejar el array asociado a un campo de un formulario formik de
// tipo array 
// El array que recibo se deb poder consultar en este componente asi como actualizarlo para devolverlo
// con las modificaciones que correspondan.

export const Comision = ({array,callBackUpdateArray,name})=> {

    const [pastores,setPastores]= useState(array || [])//useState(objetoModificacion?.obj?.pastores || [])
    const [agregarNombre,setAgregarNombre] = useState(false)

useEffect(()=>{
    //objetoModificacion.set({...objetoModificacion.obj,pastores:pastores})
    callBackUpdateArray('comision',pastores)
},[pastores])

const addPastor = (value)=>{
    setPastores([...pastores,value])
}

const deletePastor = (index)=>{
    setPastores(pastores.filter((i,indice)=>indice!=index))
}

const agregarPastor = ()=>{
    setAgregarNombre(true)
    setTimeout(() => {
        hacerFoco('input-pastor-t')
    }, 200);
}

return <Box sx={{marginTop:'20px'}}>
        <h3>Nombre a los integrantes de la comisión y su respectivo cargo.</h3>
        <table id="l-tramites-pastores">
            <tbody>
            {pastores.map((item,index)=><tr>
                <td>
                    <CircleIcon sx={{ fontSize: 5 }} />
                </td>
                <td>
                    {item?.nombre}
                </td>
                <td>
                    ( {item?.cargo})
                </td>
                <td>
                    <IconButton title='Eliminar' color="primary" onClick={()=>deletePastor(index)} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </td>
            </tr>
            )}
            </tbody>
        </table>


        <Box>
            {(!agregarNombre && pastores.length>0) && <Button  startIcon={<PlusOneIcon/>} variant='text' color='primary' onClick={agregarPastor}>
                    Agregar otro integrante de la comisión
            </Button>}
            {(agregarNombre || pastores.length==0) && <InputPastor esPrimero={pastores.length==0} addPastor={addPastor} finalizar={()=>setAgregarNombre(false)}/>}
        </Box>

</Box>

}

/*
    const [nombre,setNombre] = useState(['pedro stolarczuk'])

  return (
    <>

    <table>
        <tbody>
        {nombre.map(item=>{
            return <tr>
                <td>
                    {item}
                </td>
            </tr>
        })}
        </tbody>
    </table>
        <p>12313213132</p>
        {JSON.stringify(array)}
        <button onClick={()=>alert(1)}>modificar</button>
    </>
  );
  */
 /*
  const InputPastorc = ({addPastor,finalizar,esPrimero})=>{

    const [nombre,setNombre] = useState('')

    const handleChange = (e)=>{
        setNombre(e.target.value.toUpperCase())
    }

    const guardar = ()=>{
        if(nombre.length>=10){
            addPastor(nombre)
            finalizar()
        }else{
            alert('Agregue un nombre de al menos 10 caracteres')
        }
    }

    return <Box sx={{display:'flex'}}>
                <TextField 
                        label={'Nombre y apellido'} 
                        //autoFocus={true}
                        type='text' 
                        inputProps={{
                            maxLength:100
                        }}
                        fullWidth={false}
                        style={{width:'300px'}}
                        name="nombre"
                        id='input-pastor-t'
                        value={persona.nombre} 
//                        placeholder='Nombre y apellido del pastor' 
                        onChange={handleChange}
                />
                <TextField 
                        label={'Cargo'} 
                        //autoFocus={true}
                        type='text' 
                        name="cargo"
                        inputProps={{
                            maxLength:100
                        }}
                        fullWidth={false}
                        style={{width:'300px'}}
                        id='input-pastor-t'
                        value={persona.cargo} 
//                        placeholder='Nombre y apellido del pastor' 
                        onChange={handleChange}
                />
            

            {nombre.length > 1 && <Button size='small' startIcon={<AddCircleIcon/>} variant='contained' color='primary' onClick={guardar}>
                <p className='blink'>Agregar</p>
            </Button>}
            {!esPrimero && <Button style={{marginLeft:'25px'}} size='small' title='Agregar' startIcon={<CloseOutlinedIcon/>} variant='text' color='primary' onClick={finalizar}>
                Cancelar
            </Button>}
        </Box>
}*/

const InputPastor = ({addPastor,finalizar,esPrimero})=>{

    const [persona,setPersona] = useState({})

    const handleChange = (e)=>{
        setPersona({...persona,[e.target.name]:e.target.value.toUpperCase()})
    }

    const guardar = ()=>{
        if(persona.nombre && persona.nombre.length>=10){
            if(persona.cargo && persona?.cargo.length>=5){
                addPastor(persona)
                finalizar()
            }else{
                alert('Agregue un cargo de al menos 5 caracteres')
            }
          
        }else{
            alert('Agregue un nombre de al menos 10 caracteres')
        }
    }

          return  <Box>
                <Box sx={{display:'flex',marginTop:'5px'}}>
                <TextField 
                        label={'Nombre y apellido'} 
                        //autoFocus={true}
                        type='text' 
                        inputProps={{
                            maxLength:22
                        }}
                        fullWidth={false}
                        style={{width:'300px'}}
                        name="nombre"
                        id='input-pastor-t'
                        value={persona.nombre} 
//                        placeholder='Nombre y apellido del pastor' 
                        onChange={handleChange}
                />
                <TextField 
                        label={'Cargo'} 
                        //autoFocus={true}
                        type='text' 
                        name="cargo"
                        inputProps={{
                            maxLength:22
                        }}
                        fullWidth={false}
                        style={{width:'300px'}}
                        id='input-pastor-t'
                        value={persona.cargo} 
//                        placeholder='Nombre y apellido del pastor' 
                        onChange={handleChange}
                />
                </Box>
                <Box sx={{display:'flex',justifyContent:'center',marginTop:'5px'}}>
                    {persona?.nombre?.length > 1 && <Button size='small' startIcon={<AddCircleIcon/>} variant='contained' color='primary' onClick={guardar}>
                    <p className='blink'>Agregar</p>
                    </Button>}
                    {!esPrimero && <Button style={{marginLeft:'25px'}} size='small' title='Agregar' startIcon={<CloseOutlinedIcon/>} variant='contained' color='secondary' onClick={finalizar}>
                    <p>Cancelar</p>
                    </Button>}
                </Box>
            </Box>
}

