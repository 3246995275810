import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {_imagenLogo} from '../imagenesBase64/logo'
import Swal from 'sweetalert2';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const quitarEspaciosYsaltosdeLinea = (texto)=>{
   return texto.replace(/(\r\n|\n|\r)/gm, "").replace(/\s\s+/g, ' ')
}

export function imprimir(compromiso, nombrePDF, tituloPDF){
    try{

        Swal.fire({
            html: 'Procesando...',
            timer: 600,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        }).then(data=>{
            generarDocumento(compromiso, nombrePDF, tituloPDF)
        })
    }catch(err){

            const mensaje_html = `<p>Hubo un error al generar el documento</p><p>${err}</p>`

            Swal.fire({
                html:mensaje_html,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })
        }
}

function generarDocumento(compromiso,nombrePDF, tituloPDF) {

    const fecha = fechaDelDia();

    console.log('cursadas',compromiso)
    var secciones_mapeadas = compromiso.secciones.map(item => { return { 
                                        nombre_obrero: item.titulo}
                                    }) // uso .map para transformar un array de objetos a un nuevo array de objetos pero elijiendo los campos.
                                            // El array cursadas que viene como propiedad del abm de alumnos trae las cursadas con muchos campos pero solo queremos 4 campos para armar la grilla

    var secciones_mapeadas_vector = secciones_mapeadas.map(item=>{return Object.values(item)}) // uso .map para transformar un array de objetos en un nuevo array pero ahora no de objetos sino de arrays porque apliquè la funciòn Object.values. Hago esto porque para armar el pdf necesito un array de arrays y no array de objetos
    
    secciones_mapeadas_vector.unshift([{ text: 'Nombre', style: 'tableHeader' }])

    var docDefinition = {

        pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            var titulo_no_separado = false

            if (currentNode.headlineLevel === 1) {
                titulo_no_separado = followingNodesOnPage.some(function (item) {
                    return item.headlineLevel == 3
                })
            }
            // fuerza el cambio de página cuando se detecta que el nodo actual tiene headlineLevel = 1 (titulo) y el siguiente nodo con headlinelevel = 3 queda en la otra página
            return (currentNode.headlineLevel === 1 && !titulo_no_separado ); // de esta manera me aseguro que el nodo con headlineLevel= 2 que lo asigne al comentario final no ocupe 2 paginas, sino 1. SI ocupase 2 paginas significa que quedó partido
        },
        // izquierda, arriba, derecha, abajo
        pageMargins: [25, 30, 25, 80], // el margen de 100 para el top es necesario para que la imagen de la cabecera no se solape con el contenido

        footer: function (currentPage, pageCount, pageSize) { 
            return { // izquierda, arriba, derecha, abajo
                margin: [0, 50, 0, 0], //el margin inferior de la página (pageMargins) tiene que ser tal que sea consistente con el margen superior del footer, si no el footer desaparece
                table: {
                    
                    widths: ['*'],
                    body: [
                        [{ text: 'UAD - Rivadavia 4160 - C.P.C1205AAO Ciudad Autónoma de Buenos Aires, Argentina - Telfax: (011) 4981 1979 / 2588 / 2957- 4958-0239 ', fontSize: 8, alignment: 'center' }],
                        [{ text: 'Página ' + currentPage.toString() + ' de ' + pageCount, alignment: 'center', fontSize: 8 }]
                    ]
                },
                layout: 'noBorders'
                
                    } 
         },

        content: [

            {
                columns: [
                    {
                        image: _imagenLogo,
                        width: 70
                    },
                    {stack: [
                        // second column consists of paragraphs
                        {text:'UNIÓN DE LAS ASAMBLEAS DE DIOS',fontSize:9},
                        {text:tituloPDF || 'COMPROMISO MINISTERIAL',bold:true,fontSize:15},
                      ],
                      margin:[10,5]
                    }
                ],
            },
           /* {
                image: _imagenLogo,
                width: 70,
                alignment: 'center',
            }, */           
            {stack:
                [
                    {text: `Versión : ${compromiso.version}.0`},
                    {text: `Versión válida a partir del : ${compromiso.fecha}`},
                    aviso(),
                    {text:compromiso.subtitulo,bold:true,margin:[0,5],alignment:'center',fontSize:12},
                ],
                fontSize:9,
                margin:[0,10,0,5]
            },


           compromiso.secciones.map(item=>{
                return [
                        {text: item.tituloGrande? item.tituloGrande:null,margin:[0,0,0,10],bold:true},
                        {text:item.titulo,bold:true,margin:[0,5,0,5], headlineLevel: 1}, // le asigno al titulo un headlinelevel = 1 para analizar el cambio de página y que quede siempres queden juntos en la misma página los headlinelevel 1 y 3 que son los títulos de sección y sus puntos
                        {text: item.subtitulo? quitarEspaciosYsaltosdeLinea(item.subtitulo):null}, // debo agregar los replace para eliminar saltos de línea y excedentes de espacios porque el json tiene este problema como se copió y pegó el texto desde un PDF hay saltos de línea y espacios que no son normales
                        {stack:[...item.puntos
                                .map(punto=>{
                                        return {columns:[{text:`${punto.id}.`,width:25,bold:true,italics:true},
                                                         quitarEspaciosYsaltosdeLinea(punto.texto),
                                                        ]
                                                    ,margin:[0,5,0,0],
                                                    headlineLevel: 3 // // le asigno al titulo un headlinelevel = 3 para analizar el cambio de página y que quede siempres queden juntos en la misma página los headlinelevel 1 y 3 que son los títulos de sección y sus puntos
                                                }
                                    }
                                )
                        ],
                        margin:[10,2,10,15]} // izquierda, arriba, derecha, abajo
                    ]
           }),

          aviso()


        ],
        styles: {
            header: {
                alignment: 'right'
            },
            center: {
                alignment: 'center',
                decoration: 'underline'
            },
            anotherStyle: {
                fontSize: 15,
                bold: true,
            },
            body: {
                lineHeight: 1.5
            },
            tableHeader: {
                bold:true,
                fontSize:12
            }
        },
        images: {
            building: 'data:/Content/img/logo1.jpg'
        }
    };

    var dd = {
        content: [
            'First paragraph',
            'Another paragraph, this time a little bit longer to make sure, this line will be divided into at least two lines'
        ]
        
    }

    if (nombrePDF!=""){
        pdfMake.createPdf(docDefinition).download(nombrePDF);
    }else{
        pdfMake.createPdf(docDefinition).open()
    }
}

function fechaDelDia(){
    let fecha;

    const fecha_aux  = new Date()
    const dia = fecha_aux.getDate() < 10 ? `0${fecha_aux.getDate()}` : fecha_aux.getDate();
    let mes = fecha_aux.getMonth() + 1 ;
    mes = mes < 10 ? `0${mes}` : mes;
    fecha = `${dia}/${mes}/${fecha_aux.getUTCFullYear()}`

    return fecha;
}

const aviso = ()=>{
    return  {table:{body: // uso un table para que tenga bordes, se podría hacer de otra forma para darle bordes opté por esta forma rápida
                [ // la tabla tiene solo 1 row
                    [{ // solo 1 columna
                        text: [
                        'El presente documento  debe ser aceptado a través de la aplicación web ',
                        {text: `www.ministros.uad.digital`,link:'https://ministros.uad.digital',color:'blue'},
                        ' ingresando con su número de documento y código de seguridad.'
                        ]
                    }]
                ]
            },
            margin:[0,30,20,30],
    }
}

