import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Main from '../componentes/Main';
import {Link} from 'react-router-dom'
import Qrcode from 'qrcode.react';
import Axios from 'axios';
import Logo from '../componentes/Logo'
import Loading from '../componentes/Loading';
import {useAlumno} from '../Context/alumnoContext';

export default function Qr ({ocultar,usuario}){

const [url,setUrl] = useState(null);
const [token,setToken] = useState(3);
const [idIntervalo, setIdIntervalo]= useState(0)
const [status, setStatus]= useState(false)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const {confirmarCargandoVista} = useAlumno()

useEffect(()=>{
    const obtenerUrl = async ()=>{

        setBuscandoDatos(true);
        confirmarCargandoVista(true);

        try{
            const vectorResultado = await Promise.all([Axios.get(`/uad/ministro/qr/${usuario.id_obrero}`),
                                    Axios.get(`/api/tablasgenerales/credencial/${usuario.id_obrero}`)]) 
            setUrl(vectorResultado[0].data.url)
            setStatus(vectorResultado[1].data.status)
            setBuscandoDatos(false);
            confirmarCargandoVista(false)
        }catch(err){
            console.log(err)
            setBuscandoDatos(false);
            confirmarCargandoVista(false)
        }
    }

    obtenerUrl()       

},[])


    if(buscandoDatos){
        return <Main center><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    }

    return (
            <div className="w-cred_ overflow-hidden_ cpivot_ relative right-0 top-0 opacity-5">
                {/*<p className="text-xsmall">{usuario.dni}-{usuario.id_obrero}</p>*/}
                <div className="credencial cursor-pointer" onClick={ocultar}>
                    {url && 
                        <a>
                            <Qrcode
                                value={url}
                                size={120}
                                level={"H"}
                                includeMargin={true}
                            />
                        </a>}
                </div>
        </div>
    )
}

