import React, {useEffect,useState,useContext} from 'react'
import { Box,Typography,Button, Snackbar} from "@material-ui/core";
import Divider from '@mui/material/Divider';
import { AlertDialog } from '../../../componentes/MUI/AlertDialog';
import {FormularioTramite} from '../../FormularioTramite'
import {TramiteContextProvider} from '../contexto/tramiteContext'
import {tramiteContext} from '../contexto/tramiteContext'
import {useTramite} from '../hooks/useTramite'
import { DropzoneArea } from "material-ui-dropzone";
import { SubirArchivo } from '../../../componentes/formulariosFormik/SubirArchivo';
import Axios from 'axios';
import {LoaderCircular} from '../../../componentes/LoaderCircular'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useAlumno} from '../../../Context/alumnoContext'
import { faWindowClose,faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Iglesias} from '../componentes/Iglesias'
import {UbicacionInicial} from '../componentes/UbicacionInicial'
import {DatosPersonales} from '../componentes/DatosPersonales'
import {Contacto} from '../componentes/Contacto'
import {Cuestionario} from '../componentes/Cuestionario'
import {Cuestionario2} from '../componentes/Cuestionario2'
import {Pastores} from '../componentes/Pastores'
import {Referencias} from '../componentes/Referencias'
import Alert from '@mui/material/Alert';
import moment from 'moment';
import regex from '../../../Helpers/regex'
import { isPossiblePhoneNumber,isValidPhoneNumber  } from 'react-phone-number-input'
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { hacerFoco } from '../../../Helpers/utilidades-globales';

export const InicioTramite = ({finalizar})=>{
    const [loadingFile,setLoadingFile] = React.useState(false);
    const {usuario,snackbar} = useAlumno();
    const {campos_formulario,provincias,partidos,tiposDoc,nacionalidades,estadosCiviles,fechaEsValida} = useTramite();
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario,paso1:null,nacionalidad:{id:3,nombre:'Argentina'}});
    const [continuar,setContinuar]=useState(false);
    const [paso_ubicacion_inicial_ok,set_paso_ubicacion_inicial_ok]=useState(false);
    const [paso_datos_personales_ok,set_paso_datos_personales_ok]=useState(false);
    const [paso_contacto_ok,set_paso_contacto_ok]=useState(false);
    const [paso_cuestionario_ok,set_paso_cuestionario_ok]=useState(false);
    const [paso_cuestionario2_ok,set_paso_cuestionario2_ok]=useState(false);
    const [paso_pastores_ok,set_paso_pastores_ok]=useState(false);
    const [paso_referencias_ok,set_paso_referencias_ok]=useState(false);
    const [validarCampos,setvalidarCampos]=useState(false);

    const callbackPrevio = async ()=>{

      /*
      // Validación de datos a enviar a la mutación  + values del formik
      const validacionEncargado = validarEncargado(encargado);
      
      if(validacionEncargado[0]==false){
          return[false,validacionEncargado[1]]
      }

       // Envío de datos a la mutación + values del formik
       return [true,{
        iglesia_madre:infoIglesiaMadre.nombre_igl,
        iglesia_madre_id:infoIglesiaMadre.id_iglesia,
        encargadoEsUad:encargado.encargadoEsUad,
        nombreEncargado:encargado.nombreEncargado,
        id_pastor_uad:encargado.id_pastor_uad
      }]
     
      */

      // por default devuelva true

       return [true]
    } 

   
    const validarCondicionesIniciales = ()=>{
      // agregar validaciones si las hay
      return [true]
    }

    const iniciarContinuar = ()=>{
          
      if(!validarCondicionesIniciales()[0]){
        alert(validarCondicionesIniciales()[1])
        return
      }
      setContinuar(true)
    }

    const validarCondicionesInicialesPaso1_old = ()=>{

      const validaciones = [];

      if(objetoDinamico?.nombre==undefined || !objetoDinamico?.nombre){
        return [false,'Falta identificar el nombre del nuevo ministro','nombre']
      }

      if(objetoDinamico?.apellido==undefined || !objetoDinamico?.apellido){
        return [false,'Falta identificar el apellido del nuevo ministro','apellido']
      }

     if(objetoDinamico?.tipo_doc==undefined){
        return [false,'Falta identificar el tipo de documento del nuevo ministro','tipo_documento']
      }

      if(objetoDinamico?.dni==undefined || !objetoDinamico?.dni){
        return [false,'Falta identificar el número de documento del nuevo ministro','dni']
      }

      if(objetoDinamico?.fecha_nacimiento==undefined || !objetoDinamico?.fecha_nacimiento){
        return [false,'Falta identificar la fecha de nacimiento del nuevo ministro','fecha_nacimiento']
      }else{
        
        if(!(fechaEsValida(objetoDinamico?.fecha_nacimiento))){
          console.log('La fecha de naciminento no es válida')
          return [false,'La fecha de naciminento no es válida','fecha_nacimiento']
        }
      }

      if(objetoDinamico?.nacionalidad==undefined){
        return [false,'Falta identificar la nacionalidad del nuevo ministro','nacionalidad']
      }

      if(objetoDinamico?.estado_civil==undefined){
        return [false,'Falta identificar el estado civil del nuevo ministro','estado_civil']
      }

     if((objetoDinamico?.estado_civil?.id == 3 || objetoDinamico?.estado_civil?.id == 6) && (objetoDinamico?.conyuge==undefined || !objetoDinamico?.conyuge)){
        return [false,'Falta identificar el conyuge del nuevo ministro','conyuge']
     }

      
      return [true]
    }

    const validarCondicionesInicialesPaso1 = (validar)=>{

      const validaciones = [];
      
      if(objetoDinamico?.nombre==undefined || !objetoDinamico?.nombre){
        //return [false,'Falta identificar el nombre del nuevo ministro','nombre'];
        validaciones.push({texto:'Falta identificar el nombre del nuevo ministro',campo:'nombre'}) 
      }

      if(objetoDinamico?.apellido==undefined || !objetoDinamico?.apellido){
        //return [false,'Falta identificar el apellido del nuevo ministro','apellido'];
        validaciones.push({texto:'Falta identificar el apellido del nuevo ministro',campo:'apellido'}) 
      }
   
      if(objetoDinamico?.sexo==undefined){
//        return [false,'Falta identificar la nacionalidad del nuevo ministro','nacionalidad']
        validaciones.push({texto:'Falta completar el campo sexo',campo:'sexo'}) 
      }

     if(validar && validaciones.length>0){
          return [false,'Hay errores en los datos personales',validaciones]
     }

    return [true]
  }

    const validarCondicionesInicialesPaso2old = ()=>{


      if(objetoDinamico?.direccion==undefined || !objetoDinamico?.direccion){
        return [false,'Falta identificar el domicilio del nuevo ministro']
      }

      if(objetoDinamico?.localidad==undefined || !objetoDinamico?.localidad){
        return [false,'Falta identificar la localidad del nuevo ministro']
      }

      if(objetoDinamico?.cod_postal==undefined || !objetoDinamico?.cod_postal){
        return [false,'Falta identificar el código postal del nuevo ministro']
      }

      if(objetoDinamico?.provincia?.id==undefined){
        return [false,'Falta identificar la provincia del nuevo ministro']
      }

      if(objetoDinamico?.partido?.id==undefined){
        return [false,'Falta identificar el partido del nuevo ministro']
      }

      if(objetoDinamico?.id_region==undefined){
        return [false,'Falta identificar la región del nuevo ministro']
      }

      if(objetoDinamico?.distrito?.id==undefined){
        return [false,'Falta identificar el distrito del nuevo ministro']
      }
      return [true]
    }

    const validarCondicionesInicialesPaso2 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.provincia?.id==undefined){
        //return [false,'Falta identificar la provincia del nuevo ministro']
        validaciones.push({texto:'Falta identificar la provincia del nuevo ministro',campo:'provincia'})
      }

      if(objetoDinamico?.partido?.id==undefined){
        //return [false,'Falta identificar el partido del nuevo ministro']
        validaciones.push({texto:'Falta identificar el partido del nuevo ministro',campo:'partido'})
      }

      if(objetoDinamico?.id_region==undefined || Number(objetoDinamico?.id_region)<1){
        //return [false,'Falta identificar la región del nuevo ministro']
        validaciones.push({texto:'Falta identificar la región de la iglesia vinculada',campo:'region'})
      }

      if(objetoDinamico?.distrito?.id==undefined || Number(objetoDinamico?.distrito?.id)<1){
//        return [false,'Falta identificar el distrito del nuevo ministro']
        validaciones.push({texto:'Falta identificar el distrito de la iglesia vinculada',campo:'distrito'})
      }

      if(objetoDinamico?.iglesia?.id==undefined || objetoDinamico?.iglesia?.nombre==undefined || !(objetoDinamico?.iglesia?.id) || !(objetoDinamico?.iglesia?.nombre)){
        //        return [false,'Falta identificar el distrito del nuevo ministro']
                validaciones.push({texto:'Falta identificar la iglesia del nuevo ministro',campo:'iglesia'})
      }
    
      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de ubicación',validaciones]
       }

      return [true]

    }

    const validarCondicionesInicialesPaso3old = ()=>{

      if(objetoDinamico?.telefono==undefined || !objetoDinamico?.telefono){
        return [false,'Falta identificar el teléfono del nuevo ministro']
      }

      if(!isValidPhoneNumber(objetoDinamico?.telefono)){
        return [false,'El número de teléfono parece ser inválido. Incluya "Código de área local" y "Número de teléfono local". Por ejemplo: 11 1234-5678']
      }

      if(objetoDinamico?.celular==undefined || !objetoDinamico?.celular){
        return [false,'Falta identificar el celular del nuevo ministro']
      }

      if(!isValidPhoneNumber(objetoDinamico?.celular)){
        return [false,'El número de celular parece ser inválido. Incluya "Código de área local" y "Número de teléfono local". Por ejemplo: 11 1234-5678']
      }

      if(numerosIguales(objetoDinamico?.telefono,objetoDinamico?.celular)){
        return [false,'Los números de teléfono y celular no pueden ser iguales']
      }

      if(objetoDinamico?.email==undefined || !objetoDinamico?.email){
        return [false,'Falta identificar el email del nuevo ministro']
      }

      if(!regex.EMAIL.test(objetoDinamico?.email)){
        return [false,'El email es inválido']
      }

      return [true]
    }
    
    const validarEmailEnUso = async (validar)=>{
        try{
          const {data} = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/validaremailenuso/${objetoDinamico?.email}`)
          
          if(data.resultado==false){
            return [false,'Ya existe un trámite de solicitud de primera credencial asociado al email ingresado']
          }else{
            return [true]
          }
          
        }catch(err){
          console.log(err)
          return [false,err?.message || 'Se produjo un error al validar el email ingresado']
       }
    }

    const validarCondicionesInicialesPaso3 = (validar)=>{

    const validaciones = [];

      if(objetoDinamico?.email==undefined || !objetoDinamico?.email){
        //return [false,'Falta identificar el email del nuevo ministro']
        validaciones.push({texto:'El e-mail es obligatorio',campo:'email'})
      }

      if(!regex.EMAIL.test(objetoDinamico?.email)){
        //return [false,'El email es inválido']
        validaciones.push({texto:'El email es inválido',campo:'email'})
      }

      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de contacto',validaciones]
       }

      return [true]
    }

    const validarEmail = (validar)=>{

      const validaciones = [];
  
        if(objetoDinamico?.email==undefined || !objetoDinamico?.email){
          //return [false,'Falta identificar el email del nuevo ministro']
          //validaciones.push({texto:'El e-mail es obligatorio',campo:'email'})
          return [false]
        }
  
        if(!regex.EMAIL.test(objetoDinamico?.email)){
          //return [false,'El email es inválido']
          //validaciones.push({texto:'El email es inválido',campo:'email'})
          return [false]
        }
  
        return [true]
      }

    const validarCondicionesInicialesPaso4old = ()=>{

      if(objetoDinamico?.fecha_conversion==undefined || !objetoDinamico?.fecha_conversion){
        return [false,'Falta identificar la fecha de conversión']
      }else{
        if(!(fechaEsValida(objetoDinamico?.fecha_conversion))){
          return [false,'La fecha de conversión no es válida']
        }
      }

      if(objetoDinamico?.fecha_bautismo_a==undefined || !objetoDinamico?.fecha_bautismo_a){
        return [false,'Falta identificar la fecha de bautismo en aguas']
      }else{
        if(!(fechaEsValida(objetoDinamico?.fecha_bautismo_a))){
          return [false,'La fecha de bautismo en agua no es válida']
        }
      }

      if(objetoDinamico?.bautismo_es==undefined){
        return [false,'Falta responder sobre el bautismo en el Espíritu Santo']
      }

      if(objetoDinamico?.bautismo_es=='SI'){
        if(objetoDinamico?.fecha_bautismo_es==undefined || !objetoDinamico?.fecha_bautismo_es){
          return [false,'Falta identificar la fecha de bautismo en el Espíritu Santo']
        }else{
          if(!(fechaEsValida(objetoDinamico?.fecha_bautismo_es))){
            return [false,'La fecha de bautismo en el Espíritu Santo no es válida']
          }
        }
      }

      return [true]
    }

    const validarCondicionesInicialesPaso4 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.fecha_conversion==undefined || !objetoDinamico?.fecha_conversion){
        //return [false,'Falta identificar la fecha de conversión']
        validaciones.push({texto:'La fecha de conversión es obligatorio',campo:'fecha_conversion'})
      }else{
        if(!(fechaEsValida(objetoDinamico?.fecha_conversion))){
          //return [false,'La fecha de conversión no es válida']
          validaciones.push({texto:'La fecha de conversión no es válida',campo:'fecha_conversion'})
        }
      }

      if(objetoDinamico?.fecha_bautismo_a==undefined || !objetoDinamico?.fecha_bautismo_a){
        //return [false,'Falta identificar la fecha de bautismo en aguas']
        validaciones.push({texto:'Fecha de bautismo en aguas es obligatorio',campo:'fecha_bautismo_a'})
      }else{
        if(!(fechaEsValida(objetoDinamico?.fecha_bautismo_a))){
          //return [false,'La fecha de bautismo en agua no es válida']
          validaciones.push({texto:'La fecha de bautismo en agua no es válida',campo:'fecha_bautismo_a'})
        }
      }

      if(objetoDinamico?.bautismo_es==undefined){
//        return [false,'Falta responder sobre el bautismo en el Espíritu Santo']
        validaciones.push({texto:'Falta responder sobre el bautismo en el Espíritu Santo',campo:'bautismo_es'})
      }

      if(objetoDinamico?.bautismo_es=='SI'){
        if(objetoDinamico?.fecha_bautismo_es==undefined || !objetoDinamico?.fecha_bautismo_es){
          //return [false,'Falta identificar la fecha de bautismo en el Espíritu Santo']
          validaciones.push({texto:'Falta identificar la fecha de bautismo en el Espíritu Santo',campo:'fecha_bautismo_es'})
        }else{
          if(!(fechaEsValida(objetoDinamico?.fecha_bautismo_es))){
            //return [false,'La fecha de bautismo en el Espíritu Santo no es válida']
            validaciones.push({texto:'La fecha de bautismo en el Espíritu Santo no es válida',campo:'fecha_bautismo_es'})
          }
        }
      }

      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de conversión y bautismo',validaciones]
       }

      return [true]
    
    }

    const validarCondicionesInicialesPaso5old = ()=>{

      if(objetoDinamico?.sostenimiento==undefined || !objetoDinamico?.sostenimiento){
        return [false,'Falta responder sobre el sostenimiento financiero']
      }

      if(typeof objetoDinamico?.sostenimiento == 'string' && objetoDinamico?.sostenimiento.length<20){
        return [false,'Responda como mínimo con 20 caracteres sobre el sostenimiento financiero']
      }

      if(objetoDinamico?.diezmo==undefined){
        return [false,'Falta responder sobre el diezmo']
      }

      return [true]
    }
    const validarCondicionesInicialesPaso5 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.sostenimiento==undefined || !objetoDinamico?.sostenimiento){
        //return [false,'Falta responder sobre el sostenimiento financiero']
        validaciones.push({texto:'El sostenimiento financiero es obligatorio',campo:'sostenimiento'})
      }

      if(typeof objetoDinamico?.sostenimiento == 'string' && objetoDinamico?.sostenimiento.length<20){
        //return [false,'Responda como mínimo con 20 caracteres sobre el sostenimiento financiero']
        validaciones.push({texto:'Responda como mínimo con 20 caracteres sobre el sostenimiento financiero',campo:'sostenimiento'})
      }

      if(objetoDinamico?.diezmo==undefined){
        //return [false,'Falta responder sobre el diezmo']
        validaciones.push({texto:'Falta responder sobre el diezmo',campo:'diezmo'})
      }

      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de finanzas y diezmo',validaciones]
       }

      return [true]

    }

    const validarCondicionesInicialesPaso6old = ()=>{

      if(objetoDinamico?.pastores==undefined || !objetoDinamico?.pastores){
        return [false,'Falta ingresar el nombre de los pastores con quienes ha estado trabajando']
      }

      if(objetoDinamico?.pastores instanceof Array){
        if(objetoDinamico?.pastores.length<1){
          return [false,'Falta ingresar el nombre al menos un pastor con quien ha estado trabajando']
        }
      }

     
      return [true]
    }

    const validarCondicionesInicialesPaso6 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.pastores==undefined || !objetoDinamico?.pastores){
        //return [false,'Falta ingresar el nombre de los pastores con quienes ha estado trabajando']
        validaciones.push({texto:'Falta ingresar el nombre de los pastores con quienes ha estado trabajando',campo:'pastores'})
      }

      if(objetoDinamico?.pastores instanceof Array){
        if(objetoDinamico?.pastores.length<1){
          //return [false,'Falta ingresar el nombre al menos un pastor con quien ha estado trabajando']
          validaciones.push({texto:'Falta ingresar el nombre al menos un pastor con quien ha estado trabajando',campo:'pastores'})
        }
      }

     
      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de pastores',validaciones]
       }

      return [true]
    }

    const validarCondicionesInicialesPasoold = ()=>{

      if(objetoDinamico?.nombrepastor==undefined || !objetoDinamico?.nombrepastor){
        return [false,'Falta identificar el nombre del pastor']
      }

      if(objetoDinamico?.emailpastor==undefined || !objetoDinamico?.emailpastor){
        return [false,'Falta identificar el email del pastor']
      }

      if(!regex.EMAIL.test(objetoDinamico?.emailpastor)){
        return [false,'El email del pastor es inválido']
      }

      if(objetoDinamico?.nombreanciano==undefined || !objetoDinamico?.nombreanciano){
        return [false,'Falta identificar el nombre del anciano/diácono']
      }

      if(objetoDinamico?.emailpastor==undefined || !objetoDinamico?.emailpastor){
        return [false,'Falta identificar el email del anciano/diácono']
      }

      if(!regex.EMAIL.test(objetoDinamico?.emailanciano)){
        return [false,'El email del anciano/diácono es inválido']
      }

      if((objetoDinamico?.estado_civil?.id == 3 || objetoDinamico?.estado_civil?.id == 6)){
          if(objetoDinamico?.nombreconyuge==undefined || !objetoDinamico?.nombreconyuge){
            return [false,'Falta identificar el nombre del cónyuge']
          }

          if(objetoDinamico?.emailconyuge==undefined || !objetoDinamico?.emailconyuge){
            return [false,'Falta identificar el email del conyuge']
          }

          if(!regex.EMAIL.test(objetoDinamico?.emailconyuge)){
            return [false,'El email del cónyuge es inválido']
          }
      }
      return [true]
    }

    const validarCondicionesInicialesPaso7 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.nombrepastor==undefined || !objetoDinamico?.nombrepastor){
        //return [false,'Falta identificar el nombre del pastor']
        validaciones.push({texto:'Falta identificar el nombre del pastor',campo:'nombrepastor'})
      }

      if(objetoDinamico?.emailpastor==undefined || !objetoDinamico?.emailpastor){
        //return [false,'Falta identificar el email del pastor']
        validaciones.push({texto:'Falta identificar el email del pastor',campo:'emailpastor'})
      }

      if(!regex.EMAIL.test(objetoDinamico?.emailpastor)){
        //return [false,'El email del pastor es inválido']
        validaciones.push({texto:'El email del pastor es inválido',campo:'emailpastor'})
      }

      if(objetoDinamico?.nombreanciano==undefined || !objetoDinamico?.nombreanciano){
        //return [false,'Falta identificar el nombre del anciano/diácono']
        validaciones.push({texto:'Falta identificar el nombre del anciano/diácono',campo:'nombreanciano'})
      }

      if(objetoDinamico?.emailanciano==undefined || !objetoDinamico?.emailanciano){
        //return [false,'Falta identificar el email del anciano/diácono']
        validaciones.push({texto:'Falta identificar el email del anciano/diácono',campo:'emailanciano'})
      }

      if(!regex.EMAIL.test(objetoDinamico?.emailanciano)){
        //return [false,'El email del anciano/diácono es inválido']
        validaciones.push({texto:'El email del anciano/diácono es inválido',campo:'emailanciano'})
      }

      if((objetoDinamico?.estado_civil?.id == 3 || objetoDinamico?.estado_civil?.id == 6)){
          if(objetoDinamico?.nombreconyuge==undefined || !objetoDinamico?.nombreconyuge){
            //return [false,'Falta identificar el nombre del cónyuge']
            validaciones.push({texto:'Falta identificar el nombre del cónyuge',campo:'nombreconyuge'})
          }

          if(objetoDinamico?.emailconyuge==undefined || !objetoDinamico?.emailconyuge){
            //return [false,'Falta identificar el email del conyuge']
            validaciones.push({texto:'Falta identificar el email del conyuge',campo:'emailconyuge'})
          }

          if(!regex.EMAIL.test(objetoDinamico?.emailconyuge)){
            //return [false,'El email del cónyuge es inválido']
            validaciones.push({texto:'El email del cónyuge es inválido',campo:'emailconyuge'})
          }
      }
      
      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de referencias',validaciones]
       }

      return [true]

    }
    const iniciarCancelar_paso1 = ()=>{
        setObjetoDinamico({...objetoDinamico,
                          nombre:'',apellido:'',fecha_nacimiento:'',tipo_doc:null,dni:'',nacionalidad:{id:3,nombre:'Argentina'},estado_civil:null,conyuge:''})
       // set_paso_datos_personales_ok(false)
       setContinuar(false)
    }

    const iniciarCancelar_paso6 = ()=>{
      setObjetoDinamico({...objetoDinamico,
                        pastores:[]})
     // set_paso_datos_personales_ok(false)
     set_paso_cuestionario2_ok(false)
  }

  const iniciarCancelar_paso7 = ()=>{
    setObjetoDinamico({...objetoDinamico,
                        emailpastor:'',
                        nombrepastor:'',
                        nombreanciano:'',
                        emailanciano:'',
                        nombreconyuge:'',
                        emailconyuge:''
                    })
   // set_paso_datos_personales_ok(false)
   set_paso_referencias_ok(false)
}

    const iniciarCancelar_paso2 = ()=>{
      setObjetoDinamico({...objetoDinamico,id_region:null,partido:null,provincia:null,id_distrito:null,
                        localidad:'',cod_postal:'',barrio:'',direccion:''})
      set_paso_datos_personales_ok(false)
//      set_paso_ubicacion_inicial_ok(false)
    }

    const iniciarCancelar_paso3 = ()=>{
      setObjetoDinamico({...objetoDinamico,
                        telefono:'',celular:'',email:''})
//      set_paso_contacto_ok(false)
      set_paso_ubicacion_inicial_ok(false)
    }

    const iniciarCancelar_paso4 = ()=>{
      setObjetoDinamico({...objetoDinamico,
                        bautismo_es:false,
                        fecha_conversion:null,fecha_bautismo_a:null,fecha_bautismo_es:null})
//      set_paso_contacto_ok(false)
      set_paso_contacto_ok(false)
    }

    const iniciarCancelar_paso5 = ()=>{
      setObjetoDinamico({...objetoDinamico,
                        diezmo:false,
                        sostenimiento:null})
//      set_paso_contacto_ok(false)
      set_paso_cuestionario_ok(false)
    }

    const iniciarContinuar_paso1 = ()=>{
          
      if(!validarCondicionesInicialesPaso1()[0]){
        alert(validarCondicionesInicialesPaso1()[1])
        return
      }
      set_paso_datos_personales_ok(true)
    }

    const iniciarContinuar_paso2 = ()=>{
          
      if(!validarCondicionesInicialesPaso2()[0]){
        alert(validarCondicionesInicialesPaso2()[1])
        return
      }
      set_paso_ubicacion_inicial_ok(true)
    }

    const iniciarContinuar_paso3 = ()=>{
      if(!validarCondicionesInicialesPaso3()[0]){
        alert(validarCondicionesInicialesPaso3()[1])
        return
      }
      set_paso_contacto_ok(true)
    }

    const iniciarContinuar_paso4 = ()=>{
      if(!validarCondicionesInicialesPaso4()[0]){
        alert(validarCondicionesInicialesPaso4()[1])
        return
      }
      set_paso_cuestionario_ok(true)
    }

    const iniciarContinuar_paso5 = ()=>{
      if(!validarCondicionesInicialesPaso5()[0]){
        alert(validarCondicionesInicialesPaso5()[1])
        return
      }
      set_paso_cuestionario2_ok(true)
    }

    const iniciarContinuar_paso6 = ()=>{
      if(!validarCondicionesInicialesPaso6()[0]){
        alert(validarCondicionesInicialesPaso6()[1])
        return
      }
      set_paso_pastores_ok(true)
    }

    const iniciarContinuar_paso7 = ()=>{
      if(!validarCondicionesInicialesPaso7()[0]){
        alert(validarCondicionesInicialesPaso7()[1])
        return
      }
      set_paso_referencias_ok(true)
    }


    const callbackValidate = async (values)=>{
/*
      if(!campoAdicional)
      {
        return [false,'Mensaje']
      }

      const {data} = await consulteAlgoAlBackend()

      let validado = true;

      const campox = data.campox;

      if(!campox){
        validado = false
      }

      if(!validado){
          return [false,`Verifique...${JSON.stringify(values)}`]
      }
*/

      /*
      // Validación de datos a enviar a la mutacion + values del formik
      const validacionEncargado = validarEncargado(encargado);

      if(validacionEncargado[0]==false){
          return[false,validacionEncargado[1]]
      }

      */

      setvalidarCampos(true)
      let validacion_parcial;

      validacion_parcial = validarCondicionesInicialesPaso1(true);

      if(validacion_parcial[0]==false){
          return[false,validacion_parcial[1]]
      }

      validacion_parcial = validarCondicionesInicialesPaso2(true);

      if(validacion_parcial[0]==false){
        return[false,validacion_parcial[1]]
      }

      validacion_parcial = validarCondicionesInicialesPaso3(true);

      if(validacion_parcial[0]==false){
        return[false,validacion_parcial[1]]
      }

      validacion_parcial = await validarEmailEnUso(true)

      if (validacion_parcial[0]==false){
        return [false,validacion_parcial[1]]
      }else{
        return [true]
      }

    }

    // esto es para ver los datos del usuario
    /*return <Box>
          {Object.entries(usuario).map(item=>{
              return <p>{item[0]} ---- {item[1]}</p>
          })}
    </Box>
    */

    /*
    FUE NECESARIO agregar un flag para que luego de seleccionar el ministroSolicitud
    no renderice inmediatamente el componente FormularioTramite.
    Sucedía que apenas se seleccionaba un ministroSolicitud se renderizadaba el formik
    antes de que se actualice el objeto objetoDinamico con la información dinámica que se necesita
    del contexto para poder cargar correctamente los selects de status, region, distrito y ministro.
    Se usa un flag de "Continuar" para que renderice algo antes y de tiempo a que se ejecuten los
    useEffects de éste componente y se actualice el objetoDinmico antes de renderizar el formik.
    El flujo es, primero se renderiza, luego se ejecutan los useEffects entonces hay situaciones en que
    hay que implementar lógica para que cada acción sea en el momento adecuado.
    En este caso 1) seleccionar un ministroSolicitud, luego actualizar objetiDinamico luego renderizar
    el formulario formik con el contexto dinámico correcto.
    Sucedía que primero montaba el formulario, renderizaba el mismo, ejecutaba un useEffect del formik
    y luego procesaba los useEffects del componente InicioTramite y quedaba mal sincronizado el proceso.
    Pensaba que era un problema de closures con las funciones allowedValues de campos_formuularios
    pero no era un closure sino un problema de renderizado antes de tiempo del componente.
    Se resolvió con un flag y un componente de confirmación previo.
    */

  const CustomSubmit = ({submit,values})=>{
    const [esperarRespuesta,setEsperarRespuesta] = React.useState(false)
    const [codigo,setCodigo] = React.useState('')
    const [envios,setEnvios] = React.useState(0)
    const [identificador,setIdentificador] = React.useState('')
    const [enviando,setEnviando] = React.useState(false)

    const validarpaso = ()=>{
            callbackValidate(values)
            .then(resultado=>{
                    if (resultado[0]==false){
                        snackbar.showMessage(resultado[1],'error')
                        //alert(resultado[1],'error')
                        return 
                    }else{
                        //submit()
                        setEsperarRespuesta(true)
                    }
            })

    }

    const handlechange =(e)=>{
       if(regex.NUMERIC.test(e.target.value)){
          setCodigo(e.target.value)
       }
    }

    const verificarCodigo = async ()=>{

      try{
        const verificacion = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/validarcodigo/${identificador}/${codigo}`)
        if(verificacion.data.resultado==true){
          Swal.fire({
            text:`El código es correcto`,
            icon: 'success',
            showCancelButton:true,
            confirmButtonText:'Enviar solicitud',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    submit();
                }else{
                    console.log("Se canceló la operación de la solicitud")
                }
            }
        )
        }else{
          Swal.fire(
            'El código no es correcto',
            'Verifique el código o vuelva a enviarlo',
            'error'
          )
        }

      }catch(err){
          console.log(err)
          Swal.fire(`No se pudo verificar el código`,err?.response?.data?.message || '','error')
      }
}

    const enviarCodigo = async ()=>{
      const codigo = Math.random().toString().toUpperCase().slice(2, 8)
      const identificador = uuidv4();

      setIdentificador(identificador);

      try{
          setEnviando(true)
          const envio = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/codigoverificador/mail/${identificador}`,{nombre:objetoDinamico.nombre,email:objetoDinamico.email})
          setEnvios(envios+1)
          //Swal.fire(`Enviamos un \nmensaje con un código de 6 dígitos al e-mail: ${objetoDinamico.email}`,'Recuerde revisar también la carpeta "Correo no deseado"','info')
          setCodigo('')
          Swal.fire(`El mensaje fue enviado a ${objetoDinamico.email}`,'Enviamos un código de 6 dígitos\n\nRecuerde revisar también la carpeta "Correo no deseado"','info')
          .then(()=>{
            setTimeout(() => {
                hacerFoco('verif-cod')
            }, 1000);
              
          })
          setEnviando(false)
        }catch(err){
          console.log(err)
          setEnviando(false)
          Swal.fire(`No se pudo enviar el código`,err?.response?.data?.message || '','error')
      }
    }

    return  <Box>
            <Button onClick={validarpaso} style={{background:'steelblue'}} variant="outlined">
                Continuar
            </Button>
            <AlertDialog
                titulo="Validación del e-mail del solicitante"
                open={esperarRespuesta}
                procesarCancelar={()=>setEsperarRespuesta(false)}
                //procesarResultado={()=>alert('Procesando')}
            >
                <Box>
                    <p> Antes de enviar la solicitud necesitamos validar el e-mail de <b style={{whiteSpace:'nowrap'}}>{objetoDinamico.nombre}</b></p>
                    <br />
                    <p>Se enviará un mensaje a <b>{objetoDinamico.email}</b> con un código de 6 dígitos</p>
                    <br />
                    <p>Por favor haga clic en el botón "ENVIAR CÓDIGO" y luego ingrese el código recibido</p>
                    
                    <Box style={{marginTop:'20px', marginBottom:'20px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <Button onClick={enviarCodigo} variant="outlined" color="secondary">
                              {envios==0 ? 'Enviar código' : 'Reenviar código'}
                        </Button>
                        {enviando && <LoaderCircular/>}
                    </Box>

                    <Box style={{marginTop:'20px', marginBottom:'20px',display:'flex',justifyContent:'space-evenly'}}>
                        <input id="verif-cod" maxLength={6} value={codigo} placeholder='Ingrese el código aquí' onChange={handlechange} style={{border:'solid 1px red',padding:'10px'}} type="text"/>
                        {<Button onClick={verificarCodigo} style={{background:'steelblue'}} variant="outlined">
                              Verificar código
                        </Button>}
                    </Box>

                </Box>
            </AlertDialog>
    </Box> 
   
  }


  if(continuar==false){
    return <Box>
        <Box sx={{margin:'5px',padding:'5px'}}>
            <Typography style={{marginTop:'25px'}} variant="h5">Solicitud de credenciales para un nuevo ministro</Typography>
            <h4>Este trámite le permite presentar a una persona ante la secretaría regional para que inicie el proceso de solicitud de credenciales.</h4>
            <Alert variant="filled" severity="info">
                <p>Es importante que usted y el nuevo ministro se encuentren juntos al momento de generar ésta solicitud.</p>
                <br />
                <p>Complete el formulario en presencia de la persona candidata a recibir credenciales quien deberá tener acceso a su cuenta de correo electrónico para confirmar la validez del mismo.</p>
            </Alert>
            <br />
            <div>
                <b>Recuerde que se solicitarán los siguientes documentos al nuevo ministro:</b>
                <ul className='list-req-1cr mt-4'>
                    <li>Certificado de antecedentes penales en formato PDF</li>
                    <li>Certificado de estudios teológicos en formato PDF</li>
                    <li>Certificado de matrimonio si es casado/a en formato PDF</li>
                    <li>Foto de su DNI (Frente) en formato jpg,jpeg o npg</li>
                    <li>Foto de su DNI (Reverso) en formato jpg,jpeg o npg</li>
                    <li>Foto 4 x 4 en formato jpg,jpeg o npg</li>
                </ul>
            </div>
            <br />
            <div>
                <p>Una vez que complete y envíe el formulario el solicitante recibirá un mail con las instrucciones para continuar con la gestión por si mismo.</p>
            </div>
        </Box>
        <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
            <Button variant='outlined' color='primary' onClick={iniciarContinuar}>Continuar</Button>
            <Button style={{background:'tomato'}} variant="outlined" onClick={finalizar}>
                  cancelar
            </Button>
        </Box>
    </Box>
}

   if (!paso_datos_personales_ok && false){
        return <>
            <DatosPersonales 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  nacionalidades={nacionalidades}
                  estadosCiviles={estadosCiviles}
                  tiposDoc={tiposDoc}
                  paso_cumplido={paso_datos_personales_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
              <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                {validarCondicionesInicialesPaso1()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso1}>Continuar</Button>}
                {!validarCondicionesInicialesPaso1()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso1()[1]}</p>}
                <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso1}>
                      cancelar
                </Button>
            </Box>
        </>
    }

    if (!paso_ubicacion_inicial_ok && false){
      return <>
           <UbicacionInicial 
                usuario={usuario} 
                finalizar={finalizar} 
                provincias={provincias}
                partidos={partidos}
                paso_cumplido={paso_ubicacion_inicial_ok}
                objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
            />
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              {validarCondicionesInicialesPaso2()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso2}>Continuar</Button>}
              {!validarCondicionesInicialesPaso2()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso2()[1]}</p>}
              <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso2}>
                    cancelar
              </Button>
          </Box>
      </>
   }

   if (!paso_contacto_ok && false){
      return <>
          <Contacto 
                usuario={usuario} 
                finalizar={finalizar} 
                paso_cumplido={paso_contacto_ok}
                objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
            />
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              {validarCondicionesInicialesPaso3()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso3}>Continuar</Button>}
              {!validarCondicionesInicialesPaso3()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso3()[1]}</p>}
              <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso3}>
                    cancelar
              </Button>
          </Box>
      </>
  }

  if (!paso_cuestionario_ok && false){
    return <>
        <Cuestionario 
              usuario={usuario} 
              finalizar={finalizar} 
              paso_cumplido={paso_cuestionario_ok}
              objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
          />
          <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
            {validarCondicionesInicialesPaso4()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso4}>Continuar</Button>}
            {!validarCondicionesInicialesPaso4()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso4()[1]}</p>}
            <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso4}>
                  cancelar
            </Button>
        </Box>
    </>
}

if (!paso_cuestionario2_ok && false){
  return <>
      <Cuestionario2 
            usuario={usuario} 
            finalizar={finalizar} 
            paso_cumplido={paso_cuestionario2_ok}
            objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
        />
        <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
          {validarCondicionesInicialesPaso5()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso5}>Continuar</Button>}
          {!validarCondicionesInicialesPaso5()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso5()[1]}</p>}
          <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso5}>
                cancelar
          </Button>
      </Box>
  </>
}

if (!paso_pastores_ok && false){
  return <>
      <Pastores 
            usuario={usuario} 
            finalizar={finalizar} 
            paso_cumplido={paso_pastores_ok}
            objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
        />
        <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
          {validarCondicionesInicialesPaso6()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso6}>Continuar</Button>}
          {!validarCondicionesInicialesPaso6()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso6()[1]}</p>}
          <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso6}>
                cancelar
          </Button>
      </Box>
  </>
}

if (!paso_referencias_ok && false){
  return <>
      <Referencias 
            usuario={usuario} 
            finalizar={finalizar} 
            paso_cumplido={paso_referencias_ok}
            objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
        />
        <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
          {validarCondicionesInicialesPaso7()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso7}>Continuar</Button>}
          {!validarCondicionesInicialesPaso7()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso7()[1]}</p>}
          <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso7}>
                cancelar
          </Button>
      </Box>
  </>
}

    /*if (!paso_datos_personales_mas_ok){
      return <>
          <DatosPersonalesMas 
                usuario={usuario} 
                finalizar={finalizar} 
                nacionalidades={nacionalidades}
                estadosCiviles={estadosCiviles}
                tiposDoc={tiposDoc}
                paso_cumplido={paso_datos_personales_mas_ok}
                objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
            />
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              {validarCondicionesInicialesPaso3()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso3}>Continuar</Button>}
              <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso3}>
                    cancelar
              </Button>
          </Box>
      </>
  }*/
    
     //if(continuar==true && paso_ubicacion_inicial_ok && paso_datos_personales_ok && paso_contacto_ok && paso_cuestionario_ok && paso_cuestionario2_ok){
     if(continuar==true){

      return <TramiteContextProvider>
      <Box  className='form-body-tram' sxxxxx={{marginBottom:'25px',background:'#b3b0b0', padding:'20px', marginTop:'20px'}}>
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'25px'}}>
              <Typography variant="h5">Solicitud de credenciales</Typography>
              <div className='cursor-pointer text-center ml-2' title='Cerrar' onClick={finalizar}>
                    <FontAwesomeIcon className="color-red text-xlarge"  icon={faTimesCircle}/>
              </div>
        </Box>

        <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
           <Box>
            {/*<h1>UBICAR AQUÍ ALGUN formulario auxiliar al formik</h1>*/}
            {loadingFile && <LoaderCircular/>}
            {/*<Box>
                <h4>Texto: <span className='fw-400'>Texto</span></h4>
            </Box>*/}
              <DatosPersonales 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  nacionalidades={nacionalidades}
                  estadosCiviles={estadosCiviles}
                  tiposDoc={tiposDoc}
                  validacion={validarCondicionesInicialesPaso1}
                  validar={validarCampos}
                  paso_cumplido={paso_datos_personales_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
               {/*<DatosPersonalesMas 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  nacionalidades={nacionalidades}
                  estadosCiviles={estadosCiviles}
                  tiposDoc={tiposDoc}
                  paso_cumplido={paso_datos_personales_mas_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />*/}
              {/* La condición objetoDinamico?.paso_1 es para que se dispare primero la ubicación y luego el encargado */}
              {/*<UbicacionInicial 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  provincias={provincias}
                  paso_cumplido={paso_ubicacion_inicial_ok}
                  partidos={partidos}
                  validacion={validarCondicionesInicialesPaso2}
                  validar={validarCampos}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
            />*/}
              <Iglesias usuario={usuario} 
                objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
                validacion={validarCondicionesInicialesPaso2}
               />
               <Contacto 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  validar={validarCampos}
                  validacion={validarCondicionesInicialesPaso3}
                  paso_cumplido={paso_contacto_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
              {<FormularioTramite finalizar={finalizar}  // función que se ejecuta al cancelar o terminar de guardar
                                 callbackPrevio = {callbackPrevio} // callback que se ejecuta antes de guardar y puede modificar o agregar campos que se envían a la mutation. Si están en el formik se van a pisar, si no se agregan
                                 objetoDinamico={objetoDinamico} // este objeto sirve para pasar valores por default a campos del formulario y también para pasar un contexto particular del trámite para que llegue esa info a los inputs que se van creando según la configuración del objeto json de campos del formulario
                                 campos_formulario={campos_formulario}
                                 CustomSubmit = {CustomSubmit}
                                 textoSumbit="Enviar la solicitud de credenciales"
                                 callbackValidatePlus={callbackValidate} // callback de validación adicional custom
                                 useTramite = {useTramite}/>}
          </Box>
      </Box>
  </TramiteContextProvider>
     }

   
}





const createId = ()=>{
  const d = new Date();
  let dy = d.getDate()< 10 ? `0${d.getDate()}`: d.getDate();
  let mt = d.getMonth() < 10 ? `0${d.getMonth()}`: d.getMonth();
  let yr = d.getFullYear();
  let hs = d.getHours() < 10 ? `0${d.getHours()}`: d.getHours();
  let mn = d.getMinutes() < 10 ? `0${d.getMinutes()}`: d.getMinutes();
  let sc = d.getSeconds() < 10 ? `0${d.getSeconds()}`: d.getSeconds() ;

  const id = `${yr}${mt}${dy}${hs}${mn}${sc}`
  return id
}

const consulteAlgoAlBackend = ()=>{
    return new Promise((exito,rechazo)=>{

      setTimeout(() => {
        if(1<2){
          exito({data:{campox:59}})
        }else{
          rechazo()
        }
      }, 2000);
       
    })
}


const SeleccioneAlgo = ({setAlgoSeleccionado})=>{
    return <select onChange={(e)=>setAlgoSeleccionado(e.target.value)}>
        <option value="algo">algo</option>
        <option value="algo2">algo 2</option>
        <option value="algo3">algo 3</option>
    </select>
}

const validarUbicacion = (values)=>{

  if(!values?.partido?.id){
      return [false,`Falta identificar el partido`]
  }

  if(!values?.provincia?.id){
    return [false,`Falta identificar la provincia`]
  }

  if(!values?.distrito?.id){
    return [false,`Falta identificar el distrito`]
  }

  if(!values?.id_region){
    return [false,`Falta identificar la región`]
  }

  if(!values?.localidad){
    return [false,`Falta ingresar la localidad del nuevo ministro`]
  }

  if(!values?.cod_postal){
    return [false,`Falta ingresar el código postal del nuevo ministro`]
  }

  if(!values?.direccion){
    return [false,`Falta ingresar la dirección del nuevo ministro`]
  }

  return true
}

const numerosIguales = (telefono,celular)=>{
  
  if(typeof telefono == 'string' && typeof celular == 'string' ){
      if(telefono.trim()===celular.trim()){
          return true
      }else{
          return false
      }
  }else{
      return false
  }

}