import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";

export const MuiSwitch = ({item,objetoDinamico,setObjetoDinamico,props})=>{
    const [tiposSalida,setTiposSalida] = useState([])
    //const {movimiento,handleChangeRetirado,descripcion} = useContext(contexto)
    const {values,touched,errors,setFieldError,setFieldValue,setFieldTouched,handleChange,handleBlur,dirty} = props;
    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const [marca,setMarca] = useState(false);


    const handleChangeMarca = (e)=>{
        props.setFieldValue(item.name,e.target.checked)
        if(item?.onchange){
            item.onchange(props)
        }
        //setObjetoDinamico({...objetoDinamico,[item.name]:e.target.checked})
    }

    const classes = useStyle();

    return  <Box>
        {/*<>
        <p style={{wordWrap:'break-word'}}>{JSON.stringify(objetoDinamico)}</p>
        <p style={{wordWrap:'break-word',margin:'15px'}}>{JSON.stringify(item)}</p>
*/}
        <Box sx={{marginTop:'1rem',display:'flex', justifyContent:'center'}}>
            <FormControl>

                <FormControlLabel title='Active o desactive ésta opción según corresponda'
                control={<Switch checked={values[item.name]} 
                                 onChange={handleChangeMarca} />} 
                            label={item.label} />
            </FormControl>
        </Box>
        </Box>
}