import React, { useState } from 'react';
import Main from '../componentes/Main';
import Logo from '../componentes/Logo'
import Swal from 'sweetalert2';
import Axios from 'axios';
import {hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import {useParams} from 'react-router-dom'

export default function Login({login, mostrarError, error}){

    // la interface del usuario depende del estado, en este caso consiste solo de un objeto usuario. Cada vez que cambia el estado se vuelve a renderizar la interface
const [datosLogin,setdatosLogin] = useState({nro_documento:'',cod_seguridad:''})    

const parametros = useParams();

    React.useEffect(()=>{
        if(parametros.id_tramite && !error){
            buscarLoginTramite(parametros.id_tramite,login,mostrarError,error)
        }
    },[]);

    const handleInputChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
            const regex =/^[0-9\b]+$/
       
        if (e.target.value==''){
            setdatosLogin({...datosLogin,[e.target.name]:e.target.value})
        }else{
            if(regex.test(e.target.value)===true)
            {
                setdatosLogin({...datosLogin,[e.target.name]:e.target.value})
            }
        }
            
    }

    async function handleSubmit(e) {
        e.preventDefault();
    
        try {
          await login(datosLogin.nro_documento, datosLogin.cod_seguridad);
        } catch (err) {
            mostrarError(err);
        }
      }

      const recuperarCodSeguridad = async (documento)=>{

        if (!documento){
            return
        }

        const objetoAgrabar = { 
            documento: documento.trim()
        }
       
            try{
                  // const resultado= await Axios.post(`/api/cursos/copiar/${cuatrimestreActivo.id_cuatrimestre}`,objetoAgrabar)
                  //const resultado= await Axios.post(`/api/cursos/copiar/${cuatrimestreActivo.id_cuatrimestre}`,objetoAgrabar)
                  const resultado= await Axios.post(`/api/usuarios/recuperarid/`,objetoAgrabar)
                  return resultado
            }catch(err){
                throw err.response.data.message;
            }
        
        }

      const mensajeComunicacion = ()=>{

        const mensaje = `
        <div>
        <p>Por favor comuníquese a las siguientes direcciones de correo electrónico:</p>
        <br>
        <a target="_blank" className="mr-2 ml-2 mt-2 inline-block-1" href="mailto:info@uad.org.ar" title="info@uad.org.ar">info@uad.org.ar</a> 
        <br><br>
        <a target="_blank" className="mr-2 ml-2 mt-2 inline-block-1" href="mailto:secretaria@uad.org.ar" title="secretaria@uad.org.ar">secretaria@uad.org.ar</a> 
        <br><br>
        <p>O comuníquese a los siguientes teléfonos:</p>
        <br>
        <p>Telfax: (011) 4981 1979 / 2588 / 2957- 4958-0239</p>
        </div>
        `
        Swal.fire({
            html:mensaje,
            icon: 'info',
            width: 400,
            confirmButtonColor: '#3085d6',
        })
      }

      const buscarLoginTramite = async (id_tramite,login,mostrarError,error)=>{
            try{
                //alert(id_tramite)
                const {data} =  await Axios.get(`/api/tablasgenerales/inicio/autologin/${id_tramite}`)
                if(data.length>0){
                    await login(data[0]?.nro_documento?.toString() || '',data[0]?.cod_seguridad?.toString() || '',true)
                }else{
                    mostrarError('No se han podido identificar los datos de login.\n\n Tenga en cuenta que el link de acceso caduca una vez que ha cambiado su código de seguridad. \n\n Si el problema persiste comuníquese con la oficina UAD informando el link que utilizó para acceder a la plataforma')
                }
                //await login('5291398','11945')
                //const {data} = await Axios.get(``)
            }catch(err){
                mostrarError(err)
            }
      }

      const pedirCodSeguridad = async ()=>{

        const { value: documento ,isConfirmed,isDismissed } = await Swal.fire({
            html: '<p>Para recuperar su código de seguridad ingrese su número de documento</p>',
            width: 400,
            input: 'text',
            inputLabel: 'documento',
            inputPlaceholder: 'Número de documento',
            showCancelButton: true,
            customClass:{input:'input-rec-id'},
            inputAttributes: {
              maxlength: 10,
              autocapitalize: 'off',
              autocorrect: 'off'
            }})
    
            if (isConfirmed){
                if (documento){
                    recuperarCodSeguridad(documento)
                    .then((resultado)=>{
                        Swal.fire({
                            icon: 'alert',
                            title: 'Solicitud exitosa',
                            html: `<p>${resultado.data.message}</p>`,
                        })
                    })
                    .catch(err=>{
                        Swal.fire({
                            icon: 'error',
                            text: err
                        })
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        html: '<p>El número de documento ingresado es inválido</p>',
                      })
                }       
            }
     }

    return ( // envuelvo un contenido X con un componente Main 
    <Main center>  
        <div className="Signup">
            <div className="FormContainer login">
                <Logo/>
                <p className="FormContainer__info mt-2">Login</p>
                <form onSubmit={handleSubmit}>
                    {/* Tengo conectado el input email con el estado usuario.email a través del atributo value y del evento onChange */}
                    <input value={datosLogin.nro_documento} 
                        onChange={handleInputChange} 
                        type="text" 
                        maxLength="10"
                        name="nro_documento" 
                        placeholder="DNI" 
                        style={{fontSize:'x-large'}}
                        className="Form__field" required/>
                    <input value={datosLogin.cod_seguridad} 
                        onChange={handleInputChange} 
                        type="password" 
                        maxLength="4"
                        style={{fontSize:'x-large'}}
                        name="cod_seguridad" 
                        placeholder="Código de seguridad" 
                        className="Form__field" required/>
                    <button className="Form__submit" type="submit">Ingresar</button>
                </form>
                {<span className="error_formulario whitespace-break-spaces text-center">{error}</span>}
                {<button className="b-login cursor-pointer text-center mt-4 fw-400" onClick={pedirCodSeguridad}>Olvidé mi Código de seguridad</button>}
                <div className="text-center mt-12 text-small">
                    Ante la duda comuníquese con nosotros
                    
                    <div className="mt-2">
                        <a target="_blank" href="http://uad.org.ar">www.uad.org.ar</a>
                    </div>
                    <div className="mt-2">
                        <a target="_blank" href="mailto:secretaria@uad.org.ar" title="secretaria@uad.org.ar">secretaria@uad.org.ar</a> 
                    </div>
                    <div className="mt-2">
                        <p>+54 9 11 4981-2588</p>
                    </div>
                </div>
                {/*<button className="b-login cursor-pointer text-center fw-600 mt-2" onClick={mensajeComunicacion}>No puedo acceder a mi cuenta</button>*/}
            </div>
        </div>
    </Main>
    )
}