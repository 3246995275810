import React, {useContext,useEffect,useState} from 'react'
import {Button, FormHelperText, Box ,TextField,Grid,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {Autocompletar} from '../../../componentes/MUI/Autocompletar'
import {MuiSelect} from '../../../componentes/MUI/MuiSelect'
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'
import {useAlumno} from '../../../Context/alumnoContext'
import REG_EX from '../../../Helpers/regex'
import { TryRounded } from '@mui/icons-material';
 
export const UbicacionInicial = ({objetoModificacion,
                                    provincias,
                                    partidos,
                                    paso_cumplido,
                                    validacion,
                                    validar})=>{
    
    //objetoModificacion es {obj,set}

    const [cargando,setCargando] = useState(true);
    const [id_partido,setIdPartido]=useState(objetoModificacion?.obj?.partido?.id || null)
    const [id_provincia,set_id_provincia] = useState(objetoModificacion?.obj?.provincia?.id || null);
    const [partidos_aux,setPartidos_aux]=useState([])
    const {snackbar} = useAlumno();
    const [avisar,setAvisar] =useState({});

    React.useEffect(()=>{
        if(provincias.length>0 && partidos.length>0){
            setCargando(false)
        }
    },[provincias,partidos])

    React.useEffect(()=>{

        if(Number(id_provincia)>0 && partidos.length>0){
            const seleccion = provincias.filter(item=>item.id==id_provincia)
            objetoModificacion.set({...objetoModificacion.obj,
                provincia:{id:seleccion[0].id,nombre:seleccion[0].nombre},
                partido:null,
                //id_region:null, // región y distrito son de la iglesia y vienen del tramite de patrocinio
                //distrito:null
            })

            setPartidos_aux(partidos.filter(i=>i.id_provincia==id_provincia).map(j=>{
                return {id:j.id_partido,nombre:j.partido}
            }))
        }

    },[id_provincia])

    React.useEffect(()=>{
        if(id_partido && partidos.length>0){
            const seleccion = partidos.filter(item=>item.id_partido==id_partido)

            if(seleccion.length>0){
                objetoModificacion.set({...objetoModificacion.obj,
                                        provincia:{id:seleccion[0].id_provincia,nombre:seleccion[0].provincia},
                                        partido:{id:seleccion[0].id_partido,nombre:seleccion[0].partido},
                                       // id_region:seleccion[0].id_region, // región y distrito son de la iglesia y vienen del tramite de patrocinio
                                       // distrito:{id:seleccion[0].id_distrito,nombre:`Distrito ${seleccion[0].id_distrito}`},
                                        })
            }else{
                snackbar.showMessage('No se pudo determinar la región y distrito para la provincia y partido seleccionados','error')
            }
        }
    },[id_partido])

    const handleChange = (e,regex)=>{

        if(e.target.value === '' || ((regex && regex?.test(e.target.value)|| !regex))){
            const obj_aux = {...objetoModificacion.obj}
            obj_aux[e.target.name]=e.target.value.toUpperCase();
    
          /*  objetoModificacion.set({...objetoModificacion.obj,
               localidad:e.target.value.toUpperCase(),
            })
           */
            objetoModificacion.set({...obj_aux})
        }
    }
    
    const changeOnblur = (e)=>{
        if(!e.target.value){
            const aux = {...avisar};
            aux[e.target.name] = true
            setAvisar(aux)
        }else{
            const aux = {...avisar};
            aux[e.target.name] = false
            setAvisar(aux)
        }
    }    

    const checkvalidacion = (campo,validacion)=>{
        const objetoValidacion = validacion(validar)
        const resultado = objetoValidacion[0];
        const texto = objetoValidacion[1]
        const campos = objetoValidacion[2]
    
        if(resultado==true || !(campos instanceof Array) || campos.length==0){
            return ''
        }
        if(campos.some(item=>item.campo==campo)){
            return campos.filter(item=>item.campo==campo)[0]?.texto || ''
        }else{
            return ''
        }
    }

if(cargando){
    return <p>Cargando datos</p>
}   

if(objetoModificacion?.partido?.id>0){
    return <h1>{objetoModificacion?.partido?.nombre}</h1>
}

return <Box sx={{marginTop:'20px'}}>
        <h3>Información de su domicilio</h3>

        <Grid container>
            <Grid item xs={12}>
                <div className='form-card'><div>Direccion:<span className='c-obl'></span></div>
                    <TextField 
                            onBlur={changeOnblur} 
                            //label={'Domicilio'} 
                            //disabled={ministroSeleccionado ? true : false} 
                            fullWidth={true} 
                            type='text' 
                        //  autoFocus={true}
                            id='ub-direccion'
                            name='direccion'
                            inputProps={{
                                maxLength:500
                            }}
                            value={objetoModificacion.obj?.direccion || ''} 
                            placeholder='Tu respuesta' 
                            onChange={(e)=>{
                                handleChange(e,REG_EX.ADDRESS)
                            }}
                    />
                    {/*(paso_cumplido == true || avisar['direccion']) && !objetoModificacion.obj?.direccion && <FormHelperText className="Mui-error">Falta ingresar la dirección</FormHelperText>*/}
                    <FormHelperText className="Mui-error">{checkvalidacion('direccion',validacion)}</FormHelperText>
               </div>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <div className='form-card'><div>Localidad:<span className='c-obl'></span></div>
                    <TextField 
                                onBlur={changeOnblur} 
                                //label={'Localidad'} 
                                //disabled={ministroSeleccionado ? true : false} 
                                fullWidth={true} 
                                type='text' 
                                id='ub-localidad'
                                name='localidad'
                                inputProps={{
                                    maxLength:500
                                }}
                                value={objetoModificacion.obj?.localidad || ''} 
                                placeholder='Tu respuesta' 
                                onChange={(e)=>{
                                    handleChange(e,REG_EX.ADDRESS)
                                }}
                        />
                        {/*(paso_cumplido == true || avisar['localidad']) && !objetoModificacion.obj?.localidad && <FormHelperText className="Mui-error">Falta ingresar la localidad</FormHelperText>*/}
                        <FormHelperText className="Mui-error">{checkvalidacion('localidad',validacion)}</FormHelperText>
                    </div>
            </Grid>
            <Grid item xs={12}>
                <div className='form-card'><div>Barrio:</div>
                    <TextField 
                                onBlur={changeOnblur} 
                                //label={'Barrio'} 
                                //disabled={ministroSeleccionado ? true : false} 
                                fullWidth={true} 
                                type='text' 
                                id='ub-barrio'
                                name='barrio'
                                value={objetoModificacion.obj?.barrio || ''} 
                                placeholder='Tu respuesta' 
                                inputProps={{
                                    maxLength:500
                                }}
                                onChange={(e)=>{
                                    handleChange(e,REG_EX.ADDRESS)
                                }}
                        />
                </div>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={6}>

            </Grid>
        </Grid>
        <div className='form-card'><div>Código postal:<span className='c-obl'></span></div>
                <TextField 
                        onBlur={changeOnblur} 
                        //label={'Código postal'} 
                        //disabled={ministroSeleccionado ? true : false} 
                        fullWidth={true} 
                        type='text' 
                        id='ub-codpostal'
                        name='cod_postal'
                        inputProps={{
                            maxLength:10
                        }}
                        value={objetoModificacion.obj?.cod_postal || ''} 
                        placeholder='Tu respuesta' 
                        onChange={(e)=>{
                            handleChange(e,REG_EX.NUMERIC)
                        }}
                />
                {/*(paso_cumplido == true  || avisar['cod_postal']) && !objetoModificacion.obj?.cod_postal && <FormHelperText className="Mui-error">Falta ingresar el código postal</FormHelperText>*/}
                <FormHelperText className="Mui-error">{checkvalidacion('cod_postal',validacion)}</FormHelperText>

                        <Campos partidos_aux={partidos_aux}
                            provincias={provincias}
                            id_partido={id_partido}
                            setIdPartido={setIdPartido}
                            id_provincia={id_provincia}
                            avisar={avisar}
                            paso_cumplido={paso_cumplido}
                            set_id_provincia={set_id_provincia}
                            objetoModificacion={objetoModificacion}
                            checkvalidacion={checkvalidacion}
                            validacion={validacion}
                        />
        </div>
        {/*objetoModificacion.obj?.id_region && objetoModificacion.obj?.distrito.id && <h3 className='mt-4 text-xlarge fw-600 color-red'>Corresponde a región: {objetoModificacion.obj?.id_region} distrito: {objetoModificacion.obj?.distrito?.id}</h3>*/}
        
</Box>

}

const Campos = ({partidos_aux,provincias,
                id_partido,
                setIdPartido,
                id_provincia,
                set_id_provincia,
                avisar,
                paso_cumplido,
                checkvalidacion,
                validacion,
                objetoModificacion})=>{

    return <Grid container>
                <Grid item xs={12}>
                    <div className='form-card'><div>Provincia:<span className='c-obl'></span></div>
                        <MuiSelect datos={provincias} 
                            //titulo={'Provincia'}
                            value={id_provincia}
                            noDefault
                            //obligatorio
                            tituloDefault={'Seleccione'}
                            // NO PERMITO QUE MODIFIQUE EL EMAIL PORQUE LO COMPLETÓ EL PATROCINANTE
                            disabled={false}
                            setValue= {set_id_provincia}
                            />
                            {/*(paso_cumplido == true || avisar['cod_postal']!=undefined) && !objetoModificacion.obj?.provincia && <FormHelperText className="Mui-error">Falta ingresar la provincia</FormHelperText>*/}
                            <FormHelperText className="Mui-error">{checkvalidacion('provincia',validacion)}</FormHelperText>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='form-card'><div>Partido:<span className='c-obl'></span></div>
                        <MuiSelect datos={partidos_aux} 
                                    //titulo={'Partido'}
                                    noDefault
                                    noAdvertir
                                    //obligatorio
                                   // NO PERMITO QUE MODIFIQUE EL EMAIL PORQUE LO COMPLETÓ EL PATROCINANTE
                                   setValue= {setIdPartido}
                                   disabled={false}
                                   tituloDefault={'Seleccione'}
                                    value={id_partido}
                            />
                            {/*(paso_cumplido == true || avisar['cod_postal']!=undefined) && !objetoModificacion.obj?.partido && <FormHelperText className="Mui-error">Falta ingresar el partido</FormHelperText>*/}
                            <FormHelperText className="Mui-error">{checkvalidacion('partido',validacion)}</FormHelperText>
                    </div>
                </Grid>
    </Grid>
}




