import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose,faUser } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt,faChurch,faLink,faTrash,faSearch,faPhone,faEnvelope} from '@fortawesome/free-solid-svg-icons';
import Logo from '../componentes/Logo'
import Parametros from '../componentes/Parametros'
import Axios from 'axios';
import Loading from '../componentes/Loading';
import Main from '../componentes/Main';
import { v4 as uuidv4 } from 'uuid';
import Modal from '../componentes/Modal';
import useModal from '../hooks/useModal';
import Swal from 'sweetalert2';

const _maximoFilas = 200


export default function Nomina ({usuario}){

const [datos,setDatos] = useState([])
const [copia,setCopia] = useState([])
const [entes,setEntes] = useState([])
const [buscandoDatos,setBuscandoDatos] = useState(false)
const [iglesia,setIglesia] = useState("")
const [pastor,setPastor] = useState("")
const [localidad,setLocalidad] = useState("")
const [provincia,setProvincia] = useState(-1)
const [provincias, setProvincias] = useState([])
const [tipo,setTipo] = useState('ministros')
const [filtro,setFiltro] = useState('')
const [contadorOperaciones,setContadorOperaciones]=useState(0)
const [limites,setLimites] = useState({minimo:0,maximo:_maximoFilas-1})
const {toggle, isShowing } = useModal();
const [idSeleccionado,setIdSeleccionado]=useState(null)
const [misIglesias,setMisIglesias]=useState(null)

const obtenerDatos = async ()=>{
        setLimites({minimo:0,maximo:_maximoFilas-1})
        setBuscandoDatos(true)

        try{
            const {data} = await Axios.get(`/api/tablasgenerales/nomina`,{params:{iglesia:iglesia,pastor:pastor,id_provincia:provincia!="-1" ? provincia : null,localidad:localidad,soloIgl:tipo==='iglesias' ? 1 : 0}})

            setDatos(data)
            setCopia(data)
            setBuscandoDatos(false)
            setContadorOperaciones(contadorOperaciones+1)

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
}

const obtenerDependientes = async ()=>{
    setLimites({minimo:0,maximo:_maximoFilas-1})
    setBuscandoDatos(true)

    try{
        const {data} = await Axios.get(`/api/tablasgenerales/obrero/dependientes/${usuario.id_obrero}`)

        setDatos(data)
        setCopia(data)
        setBuscandoDatos(false)
        setContadorOperaciones(contadorOperaciones+1)

    }catch(err){
        console.log(err)
        setBuscandoDatos(false)
    }
}

const obtenerDatosSecundarios = async ()=>{
    try{
        const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/provincias`),Axios.get(`/api/tablasgenerales/listado/entes`)])
        setProvincias(vectorResultado[0].data)
        setEntes(vectorResultado[1].data)

    }catch(err){
        console.log(err)
    }
}


useEffect(()=>{
    obtenerDatosSecundarios()
},[])

useEffect(()=>{
    if (entes.length>0){
        setMisIglesias(entes.filter(item=>item.id_obrero==usuario.id_obrero))
    }
},[entes])

useEffect(()=>{
   resetBusqueda()
},[tipo])

useEffect(()=>{
    setLimites({minimo:0,maximo:_maximoFilas-1})
    const filtroCopia = copia.filter((item)=>item.nombre.toUpperCase().includes(filtro.toUpperCase())
    || item.pastor && item.pastor.toUpperCase().includes(filtro.toUpperCase())
    || item.encargado && item.encargado.toUpperCase().includes(filtro.toUpperCase())
    || item.direccion && item.direccion.toUpperCase().includes(filtro.toUpperCase())
    || item.localidad_compl && item.localidad_compl.toUpperCase().includes(filtro.toUpperCase())
    || item.provincia && item.provincia.toUpperCase().includes(filtro.toUpperCase()))

    setDatos(filtroCopia)
 },[filtro])

function resetBusqueda(){

    setDatos([])
    setCopia([])
    setPastor("")
    setIglesia("")
    setLocalidad("")
    setFiltro("")
    setProvincia(-1)
    setContadorOperaciones(0)

}

function handleChangeTipo(e,nombre){
    setTipo(e.target.value)

    if(e.target.value=='dependientes'){
        obtenerDependientes()
    }
}

function handleChangeIglesia(e){
    setIglesia(e.target.value)
}

function handleChangeProvincia(e){
    setProvincia(e.target.value)
}

function handleChangePastor(e){
    setPastor(e.target.value)
}

function handleChangeLocalidad(e){
    setLocalidad(e.target.value)
}

function handleChangeFiltro(e){
    setFiltro(e.target.value)
}


function borrarLocalidad(){
    setLocalidad("")
}

function borrarFiltro(){
    setFiltro("")
}

function borrarIglesia(){
    setIglesia("")
}

function borrarPastor(){
    setPastor("")
}

function dependienteDesvinculado(){
    setFiltro("")
    toggle()
    obtenerDependientes()
}

function iniciarBusqueda(e){
    e.preventDefault()

    if(tipo=='dependientes'){
        setFiltro("")
        obtenerDependientes()
        return
    }

    if(pastor || localidad || iglesia || provincia!="-1"){
        setFiltro("")
        obtenerDatos()
    }else{
        alert('Ingrese algún valor de búsqueda')
    }
}

    if (buscandoDatos){
        return <Main center alinear estilo={{minHeight:'400px'}}><div><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    };    

    return (
        <Main center>
           {/* <div className="flex f-col justify-center items-center mt-4 mb-4">
                <Logo width={"50"}/>
                <p className="mb-4 mt-4">Nómina de iglesias y ministros</p>
    </div>*/}

        { isShowing && idSeleccionado && <Modal hide={toggle} isShowing={isShowing} closeOnclickOutside estilo={{maxWidth:'500px'}} estiloWrapper={{background:'transparent'}}>
            <Detalle tipo={tipo} item={idSeleccionado} usuario={usuario} misIglesias={misIglesias} dependienteDesvinculado={dependienteDesvinculado}/>  
        </Modal>}
        <Parametros tipo={tipo} handleChangeTipo={handleChangeTipo}
                    iglesia={iglesia} handleChangeIglesia={handleChangeIglesia}
                    pastor = {pastor} handleChangePastor={handleChangePastor}
                    localidad = {localidad} handleChangeLocalidad = {handleChangeLocalidad}
                    ejecutarBusqueda = {iniciarBusqueda}
                    borrarPastor={borrarPastor}
                    borrarIglesia={borrarIglesia}
                    borrarLocalidad={borrarLocalidad}
                    provincias={provincias}
                    provincia = {provincia}
                    handleChangeProvincia={handleChangeProvincia}
                    datos = {datos}
                    contadorOperaciones = {contadorOperaciones}
                    resetBusqueda = {resetBusqueda}
                    filtro = {filtro}
                    handleChangeFiltro={handleChangeFiltro}
                    borrarFiltro = {borrarFiltro}
                    resultado = {copia.length}
        />
        <Paginacion datos={datos} setLimites={setLimites} limites={limites}/>
        <div className="rounded ml-auto mr-auto mt-4">
        <table className="table table-cent">
        <tbody>
            <FilasSimple tipo={tipo} datos ={datos} limites={limites} toggle={toggle} setIdSeleccionado={setIdSeleccionado}/>
        </tbody>
    </table>
    </div>
    </Main>
    )


}

function Detalle({tipo,item,usuario,misIglesias,dependienteDesvinculado}){
    const [iglesiaSeleccionada,setIglesiaSeleccionada] = useState(misIglesias.length >0 ? misIglesias[0].id : -1)
    const [vincular,setVincular]=useState(false)
    const [nuevaIglesiaDependiente,setNuevaIglesiaDependiente]=useState(false)
    const [dependencia,setDependencia]=useState(null)
    const [ejecutando,setEjecutando]=useState(null)

    useEffect(()=>{
        buscarDependencia()
    },[])

    const buscarDependencia = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/obrero/dependencia/${item.id}`)

            setDependencia(data)

        }catch(err){
            console.log(err)
            alert('Error buscando la dependencia del ministro')
        }
    } 

    const cerrarSeleccionIglesia = ()=>{
        setVincular(false)
        setIglesiaSeleccionada(misIglesias.length>0 ? misIglesias[0].id : -1)
    } 

    const vincularMinistro = async ()=>{

        let resultado;
    
        const objetoAgrabar = { 
                    id_iglesia: Number(iglesiaSeleccionada)
            }
    
   
        let mensaje_html = `<p>El vínculo se generó exitosamente</p>`
        setEjecutando(true)

        try{

            resultado = await Axios.post(`/api/tablasgenerales/vinculos/alta/${item.id}`,objetoAgrabar)

            setNuevaIglesiaDependiente(resultado.data.id_iglesia_dependiente)

            let mensaje_html = `<p>El vínculo se generó exitosamente</p>`

            Swal.fire({
                html:mensaje_html,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                timer: 1500
            }).then(()=>
                {buscarDependencia();
                setVincular(false);
                setEjecutando(false)
            })   
    
        }catch(err){
            console.log(err.response)
            let mensaje_html_error;
    
            if(err.response.data.message){
                mensaje_html_error = `<p>Se produjo un error al grabar los datos del obrero</p><p>${err.response.data.message}</p>`
            }else if (err.response.data) {
                mensaje_html_error = `<p>Se produjo un error al grabar los datos del obrero</p><p>${err.response.data}</p>`
            }else{
                mensaje_html_error = `<p>Se produjo un error al grabar los datos del obrero</p><p>${err.response}</p>`
            }
    
    
            Swal.fire({
                html:mensaje_html_error,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })   

            setEjecutando(false)
        
        }
       
    
    }
    
    const desvincularMinistro = async ()=>{

        setEjecutando(true)
    
        let mensaje_html = `<p>Desvinculación exitosa</p>`
    
        try{

            const resultado = await Axios.delete(`/api/tablasgenerales/vinculos/baja/${item.id}`)

            Swal.fire({
                html:mensaje_html,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                timer: 1500
            }).then(()=>{
                buscarDependencia();
                setVincular(false);
                setEjecutando(false)
                dependienteDesvinculado()
            })      
    
        }catch(err){
            console.log(err.response)
            let mensaje_html_error;
    
            if(err.response.data.message){
                mensaje_html_error = `<p>Se produjo un error al grabar los datos del obrero</p><p>${err.response.data.message}</p>`
            }else if (err.response.data) {
                mensaje_html_error = `<p>Se produjo un error al grabar los datos del obrero</p><p>${err.response.data}</p>`
            }else{
                mensaje_html_error = `<p>Se produjo un error al grabar los datos del obrero</p><p>${err.response}</p>`
            }
    
    
            Swal.fire({
                html:mensaje_html_error,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })   

            setEjecutando(false)
        
        }
       
    
    }

    const iniciarVinculo = ()=>{

        if (iglesiaSeleccionada<0 || !item.id){
            alert('No se seleccionó una iglesia o un ministerio como vínculo')
            return
        }

        Swal.fire({
            html:`<div><p>Vinculando a ${item.nombre}</p><p>con ${misIglesias.filter(item=>item.id==iglesiaSeleccionada).map(item=>item.nombre)}</p><p class="mb-2 mt-2">¿Confirma el vínculo?</p></div>`,
            showCancelButton:true,
            confirmButtonText:'Vincular',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    vincularMinistro();
                }else{
                    console.log("Se canceló la modificación o creación del obrero")
                }
            }
        )
    } 

    const iniciarDesvinculacion = ()=>{

        if (!item.id){
            alert('No se seleccionó un ministro para desvincular')
            return
        }

        Swal.fire({
            html:`<div><p>Desvinculando a ${item.nombre}</p><p class="mb-2 mt-2">¿Confirma la desvinculación?</p></div>`,
            showCancelButton:true,
            confirmButtonText:'Desvincular',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    desvincularMinistro();
                }else{
                    console.log("Se canceló la modificación o creación del obrero")
                }
            }
        )
    }     
    
    if (!dependencia){
        return <p>Buscando dependencia</p>
    }

    return <div><div className="flex f-col mb-4 mt-2 enc-igl">
                        <div className="flex f-row mb-4 justify-center items-center">
                            <FontAwesomeIcon className="color-tomato" icon={tipo=='iglesias' ? faChurch : faUser}/>
                            <span className="filas-lista-nw cursor-pointer ml-2" >
                                      <b>{item.nombre.toUpperCase()}</b>
                            </span> 
                        </div>
                        <p className="text-center tipo-n">{item.tipo}</p>
                        <div>
                            {tipo=='iglesias' && <div className="flex f-row justify-content-space-evenly">
                                {item.pastor && <div className="ml-2"><b>Pastor : </b><span>{item.pastor}</span></div>}
                                {item.encargado && <div className="ml-2"><b>Encargado : </b><span>{item.encargado}</span></div>}
                            </div>}
                            <br/>
                            <Ubicacion item={item}/>
                            <br/>
                            <Contacto item={item}/>
                            <br/>
                        </div>
                    </div>
                    {!ejecutando && (tipo=='ministros' || tipo=='dependientes') && <div>
                        {dependencia.dependiente && <div className="text-center">
                            <p className="mb-2" >Depende de:</p>
                            <p>{dependencia.dependiente}</p>
                        </div>}
                        {tipo=='ministros' && misIglesias.length >0 && !dependencia.dependiente && !vincular && <button className="centro-w100pc" onClick={()=>setVincular(true)} title="Seleccione para confirmar que el ministro se encuentra asociado a su iglesia o ministerio">Vincular con mi iglesia o ministerio<FontAwesomeIcon icon={faLink} className="color-tomato ml-2"/> </button>}

                        {(tipo=='ministros' || tipo=='dependientes') && misIglesias.length >0 && dependencia.dependiente && dependencia.pastordependiente== usuario.id_obrero && !vincular && <button className="centro-w100pc mt-2" onClick={iniciarDesvinculacion} title="Seleccione para desvincular al ministro de su iglesia o ministerio">Desvincular<FontAwesomeIcon icon={faTrash} className="color-tomato ml-2"/> </button>}

                        {vincular && <SeleccionIglesia   misIglesias={misIglesias} 
                                        setIglesiaSeleccionada={setIglesiaSeleccionada}
                                        iglesiaSeleccionada={iglesiaSeleccionada}
                                        cerrar = {cerrarSeleccionIglesia}
                                        iniciarVinculo = {iniciarVinculo}/>}
                        </div>}
                        {ejecutando && <Loading/>}            
              </div>


}

function Filas({tipo,datos,limites}){

    return datos
        .filter((item,index)=>index<=limites.maximo && index>=limites.minimo)
        .map((item,index) => {
        return (
            <tr key={uuidv4()} className={index < 10000 ? "border-bottom-solid" : "hidden"}>
                <td>
                    <div className="flex f-col mb-4 mt-2 enc-igl">
                        <div className="flex f-row mb-4 justify-center items-center">
                            <FontAwesomeIcon className="color-tomato" icon={tipo=='iglesias' ? faChurch : faUser}/>
                            <span className="filas-lista-nw ti-nombre cursor-pointer ml-2" >
                                      <b>{item.nombre.toUpperCase()}</b>
                            </span> 
                        </div>
                        <p className="text-center tipo-n">{item.tipo}</p>
                        <div>
                            {tipo=='iglesias' && <div className="flex f-row justify-content-space-evenly">
                                {item.pastor && <div className="ml-2"><b>Pastor : </b><span>{item.pastor}</span></div>}
                                {item.encargado && <div className="ml-2"><b>Encargado : </b><span>{item.encargado}</span></div>}
                            </div>}
                            <br/>
                            <Ubicacion item={item}/>
                            <br/>
                            <Contacto item={item}/>
                            <br/>
                        </div>
                    </div>
                </td>
            </tr>
        )
        })
}

function SeleccionIglesia({misIglesias,setIglesiaSeleccionada,iglesiaSeleccionada,cerrar,iniciarVinculo,iniciarDesvinculacion}){
    
    return <div className="flex f-col">
            <p className="mb-2 text-small text-center">Vincule al ministro a una iglesia o ministerio de la lista :</p>
            <select value={iglesiaSeleccionada} onChange={(e)=>{setIglesiaSeleccionada(e.target.value)}}>
                {misIglesias.map(item=><option value={item.id}>{item.nombre} (id {item.id})</option>)}
            </select>
            <div className="flex f-row">
                <button className="Form__submit bc-submit" onClick={cerrar}><FontAwesomeIcon icon={faWindowClose}/>
                    <p>Cancelar</p>
                </button> 
                {iglesiaSeleccionada>0 && 
                    <button className="Form__submit bc-submit" onClick={iniciarVinculo}><FontAwesomeIcon icon={faLink}/>
                        <p>Vincular</p>
                    </button>
                }
            </div>
        </div>
}

function Paginacion({datos,setLimites,limites}){
    const total = datos.length;

    const vectorPaginas = datos.map((item,index)=>({minimo:index,maximo:(index+(_maximoFilas-1))<total ? index+(_maximoFilas-1) : total})).filter(item=>item.minimo%_maximoFilas==0)

    console.log(vectorPaginas)

       return <div className="flex f-row justify-center">
       {vectorPaginas.map((item,index)=><button title={`Filas ${item.minimo+1} a la ${item.maximo+1}`} className={limites.minimo==item.minimo ? "b-pag-sel":"b-pag"} onClick={()=>{setLimites({minimo:item.minimo,maximo:item.maximo})}}>{index+1}</button>)}
   </div>
}

function FilasSimple({tipo,datos,limites,toggle,setIdSeleccionado}){

    return datos
        .filter((item,index)=>index <=limites.maximo && index >=limites.minimo)
        .map((item,index) => {
        return (
            <tr key={uuidv4()} className={"border-bottom-solid cursor-pointer"} onClick={()=>{setIdSeleccionado(item);toggle()}}>
                <td className="f-movil-n">
                    <span className="ti-nombre">{item.nombre.toUpperCase()}</span>
                    {tipo=='iglesias' && <span className="filas-lista ti-pastor">{item.pastor ? item.pastor : item.encargado }</span>}
                    <span className="filas-lista"> <FontAwesomeIcon icon={faMapMarkerAlt} className="ml-2"/> {item.localidad_compl}</span>
                </td>
                <td className="f-movil-s f-col">
                    <p className="ti-nombre fw-700">{item.nombre.toUpperCase()}</p>
                    {tipo=='iglesias' && <p className="filas-listax ti-pastorx mt-2">{item.pastor ? item.pastor : item.encargado }</p>}
                    <p className="filas-lista mt-2"> <FontAwesomeIcon icon={faMapMarkerAlt} className="ml-2 color-red"/> {item.localidad_compl}</p>
                </td>
            </tr>
        )
        })
}


function Contacto({item}){
    return <div className="text-center">
        {item.telefono && <span className="ml-2"><FontAwesomeIcon icon={faPhone} className="mr-2"/>{item.telefono}</span>}
        {item.email && <span className="ml-2"><FontAwesomeIcon icon={faEnvelope} className="mr-2"/>{item.email}</span>}
    </div>
}

function Ubicacion({item}){
    return <div className="flex f-row justify-center items-center">
        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2"/>
            <div className="text-center flex f-col border-dotted-gray p-2 br-10">
                {item.direccion &&<span>{`${item.direccion}`}</span>}
                {item.localidad_compl && <span>{`${item.localidad_compl}`}</span>}
                {item.provincia && <span>{`${item.provincia}`}</span>}
            </div>
        </div>
}
 
