import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle,faWindowClose,faUser } from '@fortawesome/free-regular-svg-icons';
import {faMapMarkerAlt,faChurch,faTrash,faUndo,faSearch,faPhone,faEnvelope} from '@fortawesome/free-solid-svg-icons';

export default function Parametros({tipo,
    handleChangeTipo,
    iglesia,
    handleChangeIglesia,
    pastor,
    handleChangePastor,
    localidad,
    handleChangeLocalidad,
    ejecutarBusqueda,borrarPastor,borrarIglesia,borrarLocalidad,
    provincias,provincia,handleChangeProvincia,datos,contadorOperaciones,
    resetBusqueda, filtro, handleChangeFiltro,borrarFiltro,resultado}){

    return <div className= "rounded ml-auto mr-auto">
    <form className="nomina">
        <div className="text-center mb-4">
        <label>
                <input 
                    type="radio" 
                    name="tipo" 
                    value = "ministros"
                    className="ml-4"
                    checked = {tipo=='ministros'}
                    id = "ministros"
                    onChange={(e)=>handleChangeTipo(e,'ministros')}
                    />             
                    <span className="text-larger fw-100 ley-s ml-1">Ministros</span>
            </label>            
            <label>
            <input 
                type="radio" 
                name="tipo" 
                value = "iglesias"
                className="ml-4"
                checked = {tipo=='iglesias'}
                id = "iglesias"
                onChange={(e)=>handleChangeTipo(e,'iglesias')}
                />             
                <span className="text-larger fw-100 ley-s ml-1">Iglesias</span>
            </label>
            <label>
            <input 
                type="radio" 
                name="tipo" 
                value = "dependientes"
                className="ml-4"
                checked = {tipo=='dependientes'}
                id = "dependientes"
                onChange={(e)=>handleChangeTipo(e,'dependientes')}
                />             
                <span className="text-larger fw-100 ley-s ml-1">Mis ministros</span>
            </label>
        </div>
        { tipo!='dependientes' && <div>
            <div className="mb-2">
                <span className="text-medium w-100 inline-block-1 fw-100 ley-s">{tipo=='iglesias' ? 'Pastor' : 'Ministro'}</span>
                <input className="nomina" type="text" value={pastor.toUpperCase()} onChange={handleChangePastor}/>
                {/*pastor!='' && <FontAwesomeIcon onClick={borrarPastor} icon={faTrash} className="cursor-pointer"/>*/}
            </div>
            {tipo==='iglesias' && <div className="mb-2">
                <span className="text-medium w-100 inline-block-1 fw-100 ley-s">Iglesia</span>
                <input className="nomina" type="text" value={iglesia.toUpperCase()} onChange={handleChangeIglesia}/>
                {/*iglesia!='' && <FontAwesomeIcon onClick={borrarIglesia} icon={faTrash} className="cursor-pointer"/>*/}
            </div>}
            <div className="mb-2">
                <span className="text-medium w-100 inline-block-1 fw-100 ley-s">Ubicación</span>
                <input className="nomina" type="text" value={localidad.toUpperCase()} onChange={handleChangeLocalidad}/>
                {/*localidad!='' && <FontAwesomeIcon onClick={borrarLocalidad} icon={faTrash} className="cursor-pointer"/>*/}
            </div>
            <div>
                <span className="text-medium w-100 inline-block-1 fw-100 ley-s">Provincia</span>
                <select onChange={handleChangeProvincia} value={provincia} 
                                    name="id_provincia" 
                                    className="w-100pcxxx" id="abm-curso-profesor">
                                    <option value="-1">Todas</option>
                                    {
                                        provincias.map(item=>
                                            <option key={`abmcurso-permiso${item.id_provincia}`} 
                                                value={item.id_provincia}>{item.nombre}</option>
                                        )
                                    }
                </select>
            </div>
        </div>}
        {tipo!='dependientes' && <div className="flex f-row">
            {((pastor || localidad || iglesia || provincia!="-1") || resultado > 0) && <button onClick={resetBusqueda} title={'Cancelar'} className="Form__submit bc-tomato" type="button"><FontAwesomeIcon icon={faWindowClose}/><p>Cancelar</p></button>} 
            <button onClick={ejecutarBusqueda} className="Form__submit bc-submit" type="submit"><FontAwesomeIcon icon={faSearch}/><p>Buscar</p></button>
        </div>}

        {contadorOperaciones > 0 && <div> <p className="text-center resultados-n">{`${tipo=='iglesias' ? 'Iglesias encontradas: ' : tipo=='dependientes' ? 'Dependientes encontrados:' :'Ministros encontrados: '} ${datos.length}`}</p>
        {resultado > 0 && <div className="mb-2 mt-2">
            <span className="text-small w-100 inline-block-1 fw-100 ley-s">Filtrar más</span>
            <input className="nomina" title="Filtre el listado ingresando una palabra clave" type="text" value={filtro.toUpperCase()} onChange={handleChangeFiltro}/>
            {filtro!='' && <FontAwesomeIcon title="Borrar filtro" onClick={borrarFiltro} icon={faTrash} className="cursor-pointer color-tomato ml-2"/>}
        </div>}
        </div>
        }
    </form>
</div>

}