export const compromiso_test = {version:1,
    observaciones:`test compromiso`,
    titulo:'Compromiso Ministerial de ministros UAD',
    subtitulo:'El ministro de la UAD asume los siguientes compromisos:',
    fecha:'17/07/2022',
    secciones:[{
        tituloGrande:null,
        titulo:'I. COMPROMISOS INSTITUCIONALES:',
        subtitulo:null,
        grupo:1,
        puntos:[
            {id:'1',
            texto:`Considera y acepta que se incorpora en este acto como ministro religioso de la UNIÓN DE LAS ASAMBLEAS DE DIOS, en razón de haber reunido y acreditado cumplir los requisitos establecidos en los Estatutos Nacionales, que se dan aquí por conocidos y reproducidos.`},
            {id:'2',
            texto:`Considera y acepta todas y cada una de las reglamentaciones, escritas en los Estatutos Nacionales, Reglamentos Administrativos, y toda otra reglamentación creada o por crearse que sea aprobada por la Asamblea General Nacional con o sin su consentimiento, mientras permanezca en la Asociación`},
            {id:'3',
            texto:`Considera y acepta reconocer, aceptar, apoyar y obedecer a las autoridades de la UNIÓN DE LAS ASAMBLEAS DE DIOS que la Asamblea correspondiente ha elegido con la guía de Dios, ya sea a nivel nacional, regional, distrital o seccional.`},
        ]},
    {
        tituloGrande:'II. COMPROMISOS ÉTICOS:',
        titulo:'1. Compromisos éticos en la familia.',
        subtitulo:null,
        grupo:2,
        puntos:[
            {id:'a',
            texto:`Considera y acepta su lugar y responsabilidad frente a su cónyuge e hijos, por lo cual se compromete en lo que de él dependa a tomar el tiempo para que ellos vean, acepten y amen a Dios por su intermedio con su ejemplo en palabra y vida. Hará de su hogar una casa de Dios, donde el Altar Familiar estará presente periódicamente.`},
        ]
    }]
}

export const doctrinas_test = {version:1,
    observaciones:`test doctrinas`,
    titulo:'doctrinas test',
    subtitulo:'El ministro de la UAD asume los siguientes compromisos:',
    fecha:'17/07/2022',
    secciones:[{
        tituloGrande:null,
        titulo:'I. COMPROMISOS INSTITUCIONALES:',
        subtitulo:null,
        grupo:1,
        puntos:[
            {id:'1',
            texto:`Considera y acepta que se incorpora en este acto como ministro religioso de la UNIÓN DE LAS ASAMBLEAS DE DIOS, en razón de haber reunido y acreditado cumplir los requisitos establecidos en los Estatutos Nacionales, que se dan aquí por conocidos y reproducidos.`},
            {id:'2',
            texto:`Considera y acepta todas y cada una de las reglamentaciones, escritas en los Estatutos Nacionales, Reglamentos Administrativos, y toda otra reglamentación creada o por crearse que sea aprobada por la Asamblea General Nacional con o sin su consentimiento, mientras permanezca en la Asociación`},
            {id:'3',
            texto:`Considera y acepta reconocer, aceptar, apoyar y obedecer a las autoridades de la UNIÓN DE LAS ASAMBLEAS DE DIOS que la Asamblea correspondiente ha elegido con la guía de Dios, ya sea a nivel nacional, regional, distrital o seccional.`},
        ]},
    {
        tituloGrande:'II. COMPROMISOS ÉTICOS:',
        titulo:'1. Compromisos éticos en la familia.',
        subtitulo:null,
        grupo:2,
        puntos:[
            {id:'a',
            texto:`Considera y acepta su lugar y responsabilidad frente a su cónyuge e hijos, por lo cual se compromete en lo que de él dependa a tomar el tiempo para que ellos vean, acepten y amen a Dios por su intermedio con su ejemplo en palabra y vida. Hará de su hogar una casa de Dios, donde el Altar Familiar estará presente periódicamente.`},
        ]
    }]
}

export const estatutos_test = {version:1,
    observaciones:`test estatus`,
    titulo:'estatus test',
    subtitulo:'El ministro de la UAD asume los siguientes compromisos:',
    fecha:'17/07/2022',
    secciones:[{
        tituloGrande:null,
        titulo:'I. COMPROMISOS INSTITUCIONALES:',
        subtitulo:null,
        grupo:1,
        puntos:[
            {id:'1',
            texto:`Considera y acepta que se incorpora en este acto como ministro religioso de la UNIÓN DE LAS ASAMBLEAS DE DIOS, en razón de haber reunido y acreditado cumplir los requisitos establecidos en los Estatutos Nacionales, que se dan aquí por conocidos y reproducidos.`},
            {id:'2',
            texto:`Considera y acepta todas y cada una de las reglamentaciones, escritas en los Estatutos Nacionales, Reglamentos Administrativos, y toda otra reglamentación creada o por crearse que sea aprobada por la Asamblea General Nacional con o sin su consentimiento, mientras permanezca en la Asociación`},
            {id:'3',
            texto:`Considera y acepta reconocer, aceptar, apoyar y obedecer a las autoridades de la UNIÓN DE LAS ASAMBLEAS DE DIOS que la Asamblea correspondiente ha elegido con la guía de Dios, ya sea a nivel nacional, regional, distrital o seccional.`},
        ]},
    {
        tituloGrande:'II. COMPROMISOS ÉTICOS:',
        titulo:'1. Compromisos éticos en la familia.',
        subtitulo:null,
        grupo:2,
        puntos:[
            {id:'a',
            texto:`Considera y acepta su lugar y responsabilidad frente a su cónyuge e hijos, por lo cual se compromete en lo que de él dependa a tomar el tiempo para que ellos vean, acepten y amen a Dios por su intermedio con su ejemplo en palabra y vida. Hará de su hogar una casa de Dios, donde el Altar Familiar estará presente periódicamente.`},
        ]
    }]
}

export const compromiso = {version:1,
    observaciones:`La aceptación del presente compromiso ministerial será determinante para la renovación de las credenciales de ministro, la cual será
    indispensable para el ingreso de las asambleas que celebre la institución.`,
    titulo:'Compromiso Ministerial de ministros UAD',
    subtitulo:'El ministro de la UAD asume los siguientes compromisos:',
    fecha:'17/07/2022',
    secciones:[{
        tituloGrande:null,
        titulo:'I. COMPROMISOS INSTITUCIONALES:',
        subtitulo:null,
        grupo:1,
        puntos:[
            {id:'1',
            texto:`Considera y acepta que se incorpora en este acto como ministro religioso de la UNIÓN DE LAS ASAMBLEAS DE DIOS, en razón de haber reunido y acreditado cumplir los requisitos establecidos en los Estatutos Nacionales, que se dan aquí por conocidos y reproducidos.`},
            {id:'2',
            texto:`Considera y acepta todas y cada una de las reglamentaciones, escritas en los Estatutos Nacionales, Reglamentos Administrativos, y toda otra reglamentación creada o por crearse que sea aprobada por la Asamblea General Nacional con o sin su consentimiento, mientras permanezca en la Asociación`},
            {id:'3',
            texto:`Considera y acepta reconocer, aceptar, apoyar y obedecer a las autoridades de la UNIÓN DE LAS ASAMBLEAS DE DIOS que la Asamblea correspondiente ha elegido con la guía de Dios, ya sea a nivel nacional, regional, distrital o seccional.`},
            {id:'4',
            texto:`Considera y acepta cumplir y hacer cumplir en su iglesia o ministerio todos los requisitos legales y contables exigidos por la Asociación y firmados en el Acta de Afiliación`},    
            {id:'5',
            texto:`Considera y acepta cumplir y hacer cumplir con el envío mensual a la Sede Central de los diezmos de su congregación y/o ministerio.`},
            {id:'6',
            texto:`Considera y acepta que ante toda discrepancia o diferencia que pudiera tener con la Asociación en el futuro lo dialogará solo con las autoridades pertinentes`},
            {id:'7',
            texto:`Considera y acepta poner todo el esfuerzo para que la unidad del Cuerpo de Cristo sea una realidad, primero en su Iglesia, luego en su Sección, Distrito y Región; para así alcanzar la verdadera unidad de la UNIÓN DE LAS ASAMBLEAS DE DIOS en toda la Argentina y en el mundo donde se encuentre uno de sus miembros. Aceptando a sus autoridades como dadas por Dios y comprometiendo su fidelidad a las mismas más allá de la opinión que pudiera tener.`},
            {id:'8',
            texto:`Considera y acepta reconocer los principios que la Biblia y los Reglamentos le enseñan que no debe transgredir y acepta toda corrección, disciplina y aún la separación de la UNIÓN DE LAS ASAMBLEAS DE DIOS si fallara a las mismas. Acepta delante de Dios que toda diferencia o controversia, sin importar el concepto que sea, será resuelto siempre dentro de la Fraternidad, según lo expresan las Sagradas Escrituras (Mt. 18:18-20)`},
            {id:'9',
            texto:`Considera y acepta trabajar con ahínco en toda responsabilidad que se le conceda dentro de la Organización.`},
            {id:'10',
            texto:`Considera y acepta servir a Dios como si siempre “caminara la segunda milla”, con gozo, responsabilidad, amor, fe y fidelidad al Señor.`},
            {id:'11',
            texto:`Considera y acepta que el espíritu contencioso, difamador y cualquier palabra o acción ofensiva y/o falta de respeto hacia los pares, los superiores o subalternos en el ministerio son expresiones carnales que atentan contra la unidad y armonía del cuerpo de Cristo y son susceptibles de sanción conforme a los procedimientos del Reglamento`},
            {id:'12',
            texto:`Considera y acepta que pertenecer a la UAD significa mantener íntegro el compromiso de colaborar con la organización, en especial en lo que los reglamentos señalan. Esto implica la presentación de balances, pago de diezmos y asistencia a sus asambleas, sean nacionales, regionales, distritales o de sección, como así también a cualquier otra convocatoria.`},
            {id:'13',
            texto:`Considera y acepta que ser parte de la Unión de las Asambleas, teniendo sus credenciales junto con los derechos, privilegios y responsabilidades significa no poder formar parte ni pertenecer a otra organización de iglesias.`},                                                                                                            
        ]},
    {
        tituloGrande:'II. COMPROMISOS ÉTICOS:',
        titulo:'1. Compromisos éticos en la familia.',
        subtitulo:null,
        grupo:2,
        puntos:[
            {id:'a',
            texto:`Considera y acepta su lugar y responsabilidad frente a su cónyuge e hijos, por lo cual se compromete en lo que de él dependa a tomar el tiempo para que ellos vean, acepten y amen a Dios por su intermedio con su ejemplo en palabra y vida. Hará de su hogar una casa de Dios, donde el Altar Familiar estará presente periódicamente.`},
            {id:'b',
            texto:`Considera y acepta que Dios creó sólo dos géneros (Hombre y Mujer) por lo que cualquier otra opción en identidad y/o práctica y/o elección sexual es artificiosa, antinatural y de origen pecaminoso, salvo anomalías genéticas comprobables por la ciencia médica.`},
            {id:'c',
            texto:`Considera y acepta que el matrimonio es de carácter vitalicio, y consiste en sólo dos personas de distinto género (Hombre y Mujer) que se unen para ser una sola carne, por lo que todo intento de separarlo carece de la aprobación divina. Asimismo, toda unión matrimonial que surja luego de una separación de hecho y/o divorcio vincular amerita el retiro de credenciales de la institución Unión de las Asambleas de Dios.`}, 
            {id:'d',
            texto:`Considera y acepta que el vínculo matrimonial se cultiva en un ambiente de amor y comprensión. Cualquier desarmonía afecta la estabilidad de la familia, la seguridad en los hijos y la eficiencia en el ministerio. El matrimonio demanda la exclusividad del uno para con el otro, por lo que cualquier vínculo sentimental o erótico hacia alguien ajeno al propio matrimonio o de un matrimonio ajeno es considerado adulterio.`}, 
            {id:'e',
            texto:`Considera y acepta que todo maltrato o daño que ocasione el cónyuge de un género al otro, sea físico, verbal o económico, como así también actitudes despóticas poseen un carácter antibíblico y descalifica a cualquiera que ostente el ministerio. En caso que el ministro incurra en dichas acciones, su caso será presentado a la Comisión de Disciplina para su tratamiento y resolución.`}, 
            {id:'f',
            texto:`Considera y acepta como ministro que cuando atraviese una crisis personal, familiar, económica o de salud debe informar a sus superiores a fin de otorgarles la oportunidad de asistirlos, proveyéndoles de medios y oportunidades de resolución de sus crisis, previniendo así cualquier situación de mayor envergadura o gravedad.`}, 
            {id:'g',
            texto:`Considera y acepta que las relaciones sexuales están santificadas en el matrimonio. Cualquier relación sexual fuera del lecho matrimonial se halla catalogada como adulterio o fornicación`}, 
            {id:'h',
            texto:`Considera y acepta que el uso de la pornografía es tan pecaminoso como cualquier relación sexual fuera del matrimonio.`}
        ]
    },
    {
        tituloGrande:null,
        titulo:'2. Compromisos éticos en la sociedad.',
        subtitulo:null,
        grupo:2,
        puntos:[
            {id:'a', 
            texto:`Considera y acepta que el carácter irascible, contencioso y autoritario de un ministro corresponden a la propia carnalidad del que lo demuestra y producen serias interferencias en el ejercicio del ministerio, dejando un mal ejemplo para sucesivas generaciones ministeriales.`},
            {id:'b',
            texto:`Considera y acepta que la palabra de un pastor debe corresponder en un ciento por ciento con la verdad y la realidad. La honestidad más que una virtud debe ser parte de la naturaleza de un siervo de Dios. Cualquier falta a la verdad es mentira, y por lo tanto pecado.`},
            {id:'c',
            texto:`Considera y acepta que todo ministro con credenciales que aspira al activismo partidario político debe abstenerse de ejercer liderazgo ministerial mientras ejerza esa función, para evitar así que en su radio de influencia ejerza voluntaria o involuntariamente algún tipo de proselitismo u ocasionar conflictos que atenten contra la comunión de los santos y la predicación del evangelio.`},
            {id:'d',
            texto:`Considera y acepta que la ingesta imprudente del alcohol como así también el consumo de toda substancia psicotrópica sin la debida prescripción médica, dañan el buen testimonio, desacreditan el ministerio y tienen efectos nocivos sobre el cristiano.`},
            {id:'e',
            texto:`Considera y acepta que todo trato, lujurioso, erótico o bien que afecte la integridad sexual de personas incapaces de defenderse, en especial menores de edad, no sólo es un grave delito que debe denunciarse, sino un pecado con serias consecuencias sobre las víctimas, el victimario mismo y su propio entorno. Asimismo, cualquier tipo de insinuación o acoso sexual valiéndose de la jerarquía eclesiástica que ostenta un ministro es condenable desde el punto de vista legal, ministerial y cristiano.`},
            {id:'f',
            texto:`Considera y acepta que el uso de las redes sociales aunque son de uso personal y privado, todas las publicaciones que se realizan en ellas son de recepción pública, por ende, la exposición de comentarios de índole político partidario, de lenguaje inapropiado, y/o imágenes indecorosas u obscenas, como así también los comentarios que se emiten con el fin de ofender, denigrar o insultar directa o indirectamente a una persona física o institución, serán tomadas como una falta a este compromiso moral de un ministro.`}
        ]
    },
    {
        tituloGrande:null,
        titulo:'3. Compromisos éticos en la administración del dinero.',
        subtitulo:null,
        grupo:2,
        puntos:[
            {id:'a',
            texto:`Considera y acepta llevar una vida ordenada en su administración financiera personal. Comprometiéndose a cumplir con todos los requisitos legales que sean necesarios. No manteniendo deudas que excedan sus posibilidades reales. Procurar aún con esfuerzo tener alguna obra social o servicio médico permanente que lo beneficie junto a su familia. Como así también adoptar algún sistema de aportes previsionales.`},
            {id:'b',
            texto:`Considera y acepta que el manejo de las finanzas de la iglesia es sagrado, y nunca un ministro de Jesucristo debe disponer de ellas para su propio beneficio, por lo cual todo préstamo del pastor hacia la iglesia o viceversa será hecho de manera prolija, mediante un acuerdo escrito bajo el consentimiento de, al menos dos oficiales de la iglesia.`},
            {id:'c',
            texto:`Considera y acepta que la morosidad reiterada o habitual son señal de desorden y generan un mal testimonio que daña cualquier función ministerial.`},
            {id:'d',
            texto:`Considera y acepta que un ministro del evangelio, debe ser cuidadoso y estar atento al origen de los fondos recibidos en la congregación, velando por la integridad que debe haber en un siervo de Dios y teniendo en cuenta los riesgos que conlleva cualquier posible ilícito.`}
        ]
    },
    {
        tituloGrande:null,
        titulo:'III. COMPROMISOS DOCTRINALES:',
        subtitulo:`Considera y acepta, creer, vivir, predicar, y enseñar todas y cada una de las doctrinas pertenecientes al canon de la
        Asociación detalladas a continuación:`,
        grupo:3,
        puntos:[
            {id:'1', 
            texto:`Las Sagradas Escrituras como la Palabra inspirada e infalible de Dios, superior a la conciencia y la razón (2 Timoteo 3:15-16)`},
            {id:'2',
            texto:`El Único Dios verdadero, manifestado en tres personas: El Padre, el Hijo y el Espíritu Santo como la Deidad adorable. (1 Juan 5:7; Mateo 28:19; Marcos 12:29; 2 Corintios 13:14).`},
            {id:'3',
            texto:`La Deidad del Señor Jesucristo y su encarnación sobrenatural. (1 Juan 5:7; Mateo 28:19; Marcos 12:29; 2 Corintios 13:14).`},
            {id:'4',
            texto:`Los ángeles como mensajeros y ministradores de Dios. (Éxodo 23:20, Jueces 2:1, Lucas 2:11, Hechos 7:38, Hebreos 1:14).`},
            {id:'5',
            texto:`La Creación divina del Universo y del hombre, la santidad original, su caída y su redención. (Génesis 1:1,26, 2:17, 3:1-7, Romanos 5:12-21).`},
            {id:'6',
            texto:`La Salvación por medio de la fe en la obra redentora de Jesucristo. (Lucas 24:47, Juan 3:3-16, Romanos 10:13-15, Efesios 2:8, Tito 2:11)`},
            {id:'7',
            texto:`La Santificación por la obra de Jesucristo, del Espíritu Santo y de la Biblia. (Juan 17:17, Romanos 12:1-2, 1ª Tesalonicenses 5:23, Hebreos 12:14, 13:12, 1ª Pedro 1:15-16).`},
            {id:'8',
            texto:`El Bautismo en el Espíritu Santo, como la Promesa del Padre, con la señal física inicial de hablar en otras lenguas. (Lucas 24:49, Juan 1:33, Hechos 2:4, 10:46, 1ª Corintios 12:4.10.28)`},
            {id:'9',
            texto:`La Sanidad Divina por la obra redentora de Cristo (Is. 53:4-5, Mateo 8:16-17, 1ª Pedro 2).`},
            {id:'10',
            texto:`El bautismo en aguas por inmersión y la Cena del Señor como ordenanzas de Cristo. (Mateo 28:19, Lucas 22:14-16, Romanos 6:4, 1ª Corintios 11:23-26, 2ª Pedro 1:4).`},
            {id:'11',
            texto:`La Iglesia como el Cuerpo Místico de Cristo; Los Ministerios divinamente ordenados y su misión evangelizadora. (Mateo 28:19-20, Marcos 3:13,14,, 16:15-20; Romanos 1:1; Efesios 1:22-23; 4:11-12; Hebreos 5:4; 12:23).`},
            {id:'12',
            texto:`La Resurrección de los redimidos y el arrebatamiento de la Iglesia antes de la tribulación. (Romanos 8:23; 1ª Tesalonicenses 1:10; 4:16-17; Tito 2:13)`},
            {id:'13',
            texto:`La Segunda Venida visible de Jesucristo para establecer su Reino Milenial en la tierra. (Zacarías 14:5; Mateo 24:27-30; Romanos 11:26-27; 2ª Tesalonicenses 1:;7; Apocalipsis 20).`},
            {id:'14',
            texto:`El Lago de Fuego como castigo eterno para los impíos, Satanás y los ángeles caídos. (Apocalipsis 19:20; 20:10-15).`},
            {id:'15',
            texto:`El cielo como morada eterna de los salvos (Juan 14:2; 1ª Cor. 2:9-10; Apocalipsis 21:22).`},
            {id:'16',
            texto:`Los Cielos Nuevos y la Tierra Nueva (1ª Pedro 3:13; Apocalipsis 21:2).`},
        ]},
    {   tituloGrande:null,
        titulo:'Declaraciones finales',
        subtitulo:null,
        grupo:4,
        puntos:[
            {id:'', 
            texto:`El presente Compromiso Ministerial tiene vigencia y valor porque el firmante es un fiel creyente, buen siervo de Dios y digno ejemplo de un Ministro del Evangelio perteneciente a la UNIÓN DE LAS ASAMBLEAS DE DIOS.`},
            {id:'',
            texto:`Declaro mi acuerdo con los puntos arriba redactados y me comprometo a cumplirlos y a que, si experimentase algún conflicto en concepto, conducta, o alguna forma de pensar con éstos, podré no recibir o tener que entregar mis credenciales a mis autoridades hasta la aclaración o resolución del conflicto.`},
            {id:'',
            texto:`Declaro que los compromisos asumidos los realizo en pleno uso de mis facultades físicas y mentales, sin que medie coacción alguna, y no implicando los mismos ningún agravio o discriminación alguna hacia mi persona ni menoscabo de mis derechos`},
            {id:'',
            texto:`Cualquier transgresión o falta de cumplimento al presente compromiso firmado, derivará en un llamado de atención, una posible sanción y hasta la pérdida de forma temporal o definitiva las credenciales de esta organización.`},
            {id:'',
            texto:`La aceptación de la presente acta será determinante para la renovación de las credenciales de ministro, la cual será indispensable para el ingreso de las asambleas que celebre la institución.`},
        ]}]
}


export const doctrinas = {version:1,
    observaciones:``,
    titulo:'DOCUMENTO DE DOCTRINAS FUNDAMENTALES DE LA UNIÓN DE LAS ASAMBLEAS DE DIOS',
    subtitulo:'',
    fecha:'01/01/2023',
    secciones:[{
        tituloGrande:null,
        titulo:`1. Doctrina sobre las Sagradas Escrituras`,
        subtitulo:`Las Sagradas Escrituras como la Palabra inspirada e infalible de Dios, superior a la conciencia y la razón.`,
        grupo:1,
        puntos:[
            {id:'',
            texto:`Las Escrituras, tanto el Antigüo como el Nuevo Testamento, son verbalmente inspiradas por Dios y son la revelación de Dios para el hombre, la regla infalible y autoritaria de fe y conducta (2 Timoteo 3:15, 16; 1 Pedro 2:2.)`},
        ]},
        {
            tituloGrande:null,
            titulo:`2. Doctrina sobre la Trinidad`,
            subtitulo:`El Único Dios verdadero, manifestado en tres personas: El Padre, el Hijo y el Espíritu Santo como la Deidad adorable.
            `,
            grupo:1,
            puntos:[
                {id:'',
                texto:`El único Dios verdadero se ha revelado como el Eterno existente en sí mismo "YO SOY", el Creador del cielo y de la tierra y Redentor de la humanidad. \nSe ha revelado también encarnando los principios de relación y asociación como el Padre, el Hijo, y el Espíritu Santo. (Deuteronomio 6:4; Isaías 43:10; Mateo 28:19; Marcos 12:29; Lucas 3:22).
                `},
                {id:'a',
                texto:`Definición de vocablos. Los vocablos trinidad y personas, según se relacionan con la Deidad, aunque no se encuentran en la Biblia, son vocablos que están en armonía con ella, por lo tanto podemos comunicar a los demás nuestro entendimiento inmediato de la doctrina de Cristo respecto al Ser de Dios, según se distingue de "muchos dioses y muchos señores". Por tanto podemos hablar debidamente del Señor nuestro Dios, que es un solo Señor, como una Trinidad o como un Ser de tres personas, sin apartarnos por ello de las enseñanzas bíblicas (como ejemplo, Mateo 28:19; 2 Corintios 13:14; Juan 14:16,17).`
                },
                {id:'b',
                texto:`Distinción y relación en la Deidad. Cristo enseñó una distinción de personas en la Deidad que expresó en términos específicos de relación, como Padre, Hijo y Espíritu Santo, pero que esta distinción y relación, en lo que a su forma se refiere es inescrutable e incomprensible, pues la Biblia no lo explica (Lucas 1:35; 1 Corintios 1:24; Mateo 11:25-27; 28:19; 2 Corintios 13:14; 1 Juan 1:3, 4).`
                },
                {id:'c',
                texto:`Unidad del Único Ser del Padre, del Hijo y del Espíritu Santo\n\nPor lo tanto, de la misma manera, hay eso en el Padre que lo constituye Padre y no Hijo; hay eso en el Hijo que lo constituye Hijo y no Padre; y hay eso en el Espíritu Santo que lo constituye Espíritu Santo y no Padre ni Hijo. Por lo que el Padre es el Engendrador; el Hijo es el Engendrado; y el Espíritu Santo es el que procede del Padre y del Hijo.\nAsí que, por cuanto estas tres personas de la Deidad están en un estado de unidad, existe un solo Señor Dios Todopoderoso y tiene un solo nombre (Juan 1:18; 15:26; 17:11, 21; Zacarías 14:9).`
                },
                {id:'d',
                texto:`Identidad y cooperación en la Deidad\n\nEl Padre, el Hijo y el Espíritu Santo no son idénticos en lo que respecta a persona; ni se les confunde en cuanto a relación; ni están divididos en cuanto a la Deidad; ni opuestos en cuanto a cooperación. El Hijo está en el Padre y el Padre está en el Hijo en cuanto a relación. El Hijo está con el Padre y el Padre está con el Hijo, en cuanto a confraternidad. El Padre no procede del Hijo, sino el Hijo procede del Padre, en lo que respecta a autoridad. El Espíritu Santo procede del Padre y del Hijo, en cuanto a naturaleza, relación, cooperación y autoridad. Por tanto, ninguna de las personas de la Deidad existe ni opera separada o independientemente de las otras (Juan 5:17-30,32,37; 8:17,18).`
                },
                {id:'e',
                texto:`El título Señor Jesucristo\n\nEl título Señor Jesucristo es un nombre propio. En el Nuevo Testamento nunca se le aplica al Padre ni al Espíritu Santo.Por tanto pertenece exclusivamente al Hijo de Dios (Romanos 1:1-3,7; 2 Juan 3).`
                },
                {id:'f',
                texto:`El Señor Jesucristo, Dios con nosotros\n\nEl Señor Jesucristo, en lo que respecta a su naturaleza divina y eterna, es el verdadero y unigénito Hijo del Padre, pero en lo que respecta a su naturaleza humana, es el verdadero Hijo del Hombre. Por lo tanto, se le reconoce como Dios y hombre; quien por ser Dios y hombre, es "Emanuel", Dios con nosotros (Mateo 1:23; 1 Juan 4:2,10,14; Apocalipsis 1:13,17).`
                },
                {id:'g',
                texto:`El título Hijo de Dios\n\nSiendo que el nombre Emanuel abarca lo divino y lo humano, en una sola persona, nuestro Señor Jesucristo, el título Hijo de Dios describe su debida deidad, y el título Hijo del Hombre su debida humanidad. De manera que el título Hijo de Dios pertenece al orden de la eternidad, y el título Hijo del Hombre al orden del tiempo (Mateo 1:21-23; 2 Juan 3; 1 Juan 3:8; Hebreos 7:3; 1:1-13)`
                },
                {id:'h',
                texto:`Trasgresión de la doctrina de Cristo\n\nPor tanto, es una trasgresión de la doctrina de Cristo decir que el Señor Jesús derivó el título de Hijo de Dios sólo del hecho de la encarnación, o por su relación con la economía de la redención. De modo que negar que el Padre es un Padre verdadero y eterno y que el Hijo es un Hijo verdadero y eterno es negar la distinción y relación en el Ser de Dios; una negación del Padre y del Hijo; y una substitución de la verdad de que Jesucristo fue hecho carne (2 Juan 9; Juan 1:1,2,14,18,29,49; 1 Juan 2:22,23; 4:1-5; Hebreos 12:2).2`
                },
                {id:'i',
                texto:`Exaltación de Jesucristo como Señor\n\nEl Hijo de Dios, nuestro Señor Jesucristo, después de limpiarnos del pecado con su sangre, se sentó a la diestra de la Majestad en las alturas, sujetándose a El ángeles, principados, y potestades. Después de ser hecho Señor y Cristo, envió al Espíritu Santo para que en el nombre de Jesús se doble toda rodilla y confiese que Jesucristo es el Señor para la gloria de Dios el Padre hasta el fin, cuando el Hijo se sujete al Padre para que Dios sea todos en todo (Hebreos 1:3; 1 Pedro 3:22; Hechos 2:32-36; Romanos 14:11; 1 Corintios 15:24-28).`
                },
                {id:'j',
                texto:`Igual honor para el Padre y el Hijo\n\nSiendo que el Padre ha dado al Hijo todo juicio, no es solo un deber de todos en el cielo y en la tierra postrarse ante El, sino que es un gozo inefable en el Espíritu Santo adscribir al Hijo todos los atributos de la deidad y rendirle todo el honor y la gloria contenidos en todos los nombres y títulos de la Deidad excepto los que denotan relación (ver los párrafos b, c y d), honrando así al Hijo como se honra al Padre (Juan 5:22,23; 1 Pedro 1:8; Apocalipsis 5:6-14; Filipenses 2:8,9; Apocalipsis 7:9,10; 4:8-11).`
                },
            ]},
            {
                tituloGrande:null,
                titulo:`3. Doctrina sobre la deidad del Señor Jesucristo`,
                subtitulo:`La Deidad del Señor Jesucristo y su encarnación sobrenatural.`,
                grupo:1,
                puntos:[
                    {id:'',
                    texto:`El Señor Jesucristo es el eterno Hijo de Dios.La Biblia declara:\n`
                    },
                    {id:'a', texto:`Su nacimiento virginal (Mateo 1:23; Lucas 1:31,35).`
                    },
                    {id:'b', texto:`Su vida sin pecado (Hebreos 7:26; 1 Pedro 2:22).`
                    },
                    {id:'c', texto:`Sus milagros (Hechos 2:22; 10:38).`
                    },
                    {id:'d', texto:`Su obra vicaria en la cruz (1 Corintios 15:3; 2 Corintios 5:21).`
                    },
                    {id:'e', texto:`Su resurrección corporal de entre los muertos (Mateo 28:6; Lucas 24:39; 1 Corintios 15:4).`
                    },
                    {id:'f', texto:`Su exaltación a la diestra de Dios (Hechos 1:9, 11; 2:33; Filipenses 2:9-11; Hebreos 1:3).`
                    },
                ]}, 
                {
                    tituloGrande:null,
                    titulo:`4. Doctrina sobre los ángeles.`,
                    subtitulo:`Los ángeles como mensajeros y ministradores de Dios.`,
                    grupo:1,
                    puntos:[
                        {id:'',
                        texto:`Éxodo 23:20; Jueces 2:1; Lucas 2:11; Hechos 7:38; Hebreos 1:14`}
                    ]},      
                    {
                        tituloGrande:null,
                        titulo:`5. Doctrina sobre la creación y el principio de la humanidad.`,
                        subtitulo:`La Creación divina del Universo y del hombre, la santidad original, su caída y su redención.`,
                        grupo:1,
                        puntos:[
                            {id:'',
                            texto:`Génesis 1:1,26; 2:17; 3:1-7; Romanos 5:12-21\n`},
                            {
                                id:'',
                                texto:`La caída del hombre\n\nEl hombre fue creado bueno y justo; porque Dios dijo: "Hagamos al hombre a nuestra imagen, conforme a nuestra semejanza". Sin embargo, el ser humano por su propia voluntad cayó en trasgresión, incurriendo así no sólo la muerte física sino también la espiritual, que es la separación de Dios (Génesis 1:26, 27; 2:17; 3:6; Romanos 5:12-19).`
                            }
                        ]},  
                        {
                            tituloGrande:null,
                            titulo:`6. Doctrina sobre la salvación.`,
                            subtitulo:`La Salvación por medio de la fe en la obra redentora de Jesucristo.`,
                            grupo:1,
                            puntos:[
                                {id:'',
                                texto:`Lucas 24:47; Juan 3:3,16; Romanos 10:13-15; Efesios 2:8; Tito 2:11\n\nLa única esperanza de redención para el hombre es a través de la sangre derramada de Jesucristo, el Hijo de Dios.`
                                },
                                {id:'a',
                                texto:`Condiciones para la salvación. La salvación se recibe a través del arrepentimiento para con Dios y la fe en el Señor Jesucristo. El hombre se convierte en hijo y heredero de Dios según la esperanza de vida eterna por el lavamiento de la regeneración, la renovación del Espíritu Santo y la justificación por la gracia a través de la fe (Lucas 24:47; Juan 3:3; Romanos 10:13–15; Efesios 2:8; Tito 2:11; 3:5–7).`},
                                {id:'b',
                                texto:`Evidencias de la salvación. La evidencia interna de la salvación es el testimonio directo del Espíritu (Romanos 8:16). La evidencia externa ante todos los hombres es una vida de justicia y verdadera santidad (Efesios 4:24; Tito 2:12).`
                                }
                                ]},  
                        {
                            tituloGrande:null,
                            titulo:`7. Doctrina sobre la santificación.`,
                            subtitulo:`La Santificación por la obra de Jesucristo, del Espíritu Santo y de la Biblia.`,
                            grupo:1,
                            puntos:[
                                {id:'',
                                texto:`Juan 17:17; Romanos 12:1,2, 1 Tesalonicenses 5:23; Hebreos 12:14; 13:12; 1 Pedro 1:15,16`},
                            {id:'',texto:`La santificación es un acto de separación de todo lo malo, y de dedicación a Dios (Romanos 12:1, 2; 1 Tesalonicenses 5:23; Hebreos 13:12). La Biblia prescribe una vida de "santidad sin la cual nadie verá al Señor" (Hebreos 12:14). Por el poder del Espíritu Santo podemos obedecer el mandato que dice: "Sed santos porque yo soy santo" (1 Pedro 1:15, 16).`},
                            {id:'',texto:`La santificación se efectúa en el creyente cuando este reconoce su identidad con Cristo en su muerte y su resurrección, y por fe se propone vivir cada día en esta unión con Cristo, y somete todas sus facultades al dominio del Espíritu Santo (Romanos 6:1–11, 13; 8:1, 2, 13; Gálatas 2:20; Filipenses 2:12, 13; 1 Pedro 1:5).`}

                            ]},  
                        {
                            tituloGrande:null,
                            titulo:`8. Doctrina sobre el bautismo en el Espíritu Santo.`,
                            subtitulo:`El Bautismo en el Espíritu Santo , como la Promesa del Padre, con la señal física inicial de hablar en otras lenguas.`,
                            grupo:1,
                            puntos:[
                                {id:'',
                                texto:`Lucas 24:49; Juan 1:33; Hechos 2:4; 10:46; 1 Corintios 12:4, 10, 28;`},
                                {id:'',
                                texto:`Todos los creyentes tienen el derecho de recibir y deben buscar fervientemente la promesa del Padre, el bautismo en el Espíritu Santo y fuego, según el mandato del Señor Jesucristo. Esta era la experiencia normal y común de toda la primera iglesia cristiana. Con el bautismo viene una investidura de poder para la vida y el servicio y la concesión de los dones espirituales y su uso en el ministerio (Lucas 24:49; Hechos 1:4, 8; 1 Corintios 12:1–31). Esta experiencia es distinta a la del nuevo nacimiento y subsecuente a ella (Hechos 8:12–17; 10:44–46; 11:14–16; 15:7–9). Con el bautismo en el Espíritu Santo el creyente recibe experiencias como la de ser lleno del Espíritu (Juan 7:37–39; Hechos 4:8), una reverencia más profunda para Dios (Hechos 2:43; Hebreos 12:28), una consagración más intensa a Dios y dedicación a su obra (Hechos 2:42) y un amor más activo para Cristo, para su Palabra y para los perdidos (Marcos 16:20).`},
                                {id:'',
                                texto:`El bautismo de los creyentes en el Espíritu Santo se evidencia con la señal física inicial de hablar en otras lenguas como el Espíritu los dirija (Hechos 2:4). El hablar en lenguas en este caso es esencialmente lo mismo que el don de lenguas (1 Corintios 12:4–10, 28), pero es diferente en propósito y uso.`}
                        ]},
                        {
                            tituloGrande:null,
                            titulo:`9. Doctrina sobre la sanidad divina. `,
                            subtitulo:`La Sanidad Divina por la obra redentora de Cristo.`,
                            grupo:1,
                            puntos:[
                                {id:'',texto:'Isaías 53:4-5; Mateo 8:16,17; 1 Pedro 2:24'},
                                {id:'',texto:'La sanidad divina es una parte integral del evangelio. La liberación de la enfermedad ha sido provista en la expiación y es el privilegio de todos los creyentes (Isaías 53:4, 5; Mateo 8:16, 17; Santiago 5:14–16).'}
                            ]
                        } ,
                        {
                            tituloGrande:null,
                            titulo:`10. Doctrina sobre el Bautismo en agua y la Cena del Señor.`,
                            subtitulo:`El bautismo en agua por inmersión y la Cena del Señor como ordenanzas de Cristo.`,
                            grupo:1,
                            puntos:[
                                {id:'',texto:'Mateo 28:19; Lucas 22:14-16; Romanos 6:4; 1 Corintios 11:23-26; 2 Pedro 1:4'},
                            {id:'a',texto:`El bautismo en agua. Las Escrituras establecen la ordenanza del bautismo en agua por inmersión. Todos los que se arrepienten y creen en Cristo como Salvador y Señor deben ser bautizados. De esta manera declaran ante el mundo que han muerto con Cristo y que han sido resucitados con El para andar en nueva vida (Mateo 28:19; Marcos 16:16; Hechos 10:47, 48; Romanos 6:4).`},
                            {id:'b',texto:`La santa comunión. La Cena del Señor, que consiste en la participación de las especies eucarísticas –el pan y el fruto de la vid– es el símbolo que expresa nuestra participación de la naturaleza divina de nuestro Señor Jesucristo (2 Pedro 1:4); un recordatorio de sus sufrimientos y su muerte (1 Corintios 11:26); y una profecía de su segunda venida (1 Corintios 11:26); y un mandato para todos los creyentes "¡hasta que él venga!"`}
                            ] 
                        },
                        {
                            tituloGrande:null,
                            titulo:`11. Doctrina sobre el cuerpo de Cristo.`,
                            subtitulo:`La Iglesia como el Cuerpo Místico de Cristo`,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Mateo 28:19-20; Marcos 3:13,14;16:15-20; Romanos 1:1; Efesios 1:22,23; 4:11,12; Hebreos 5:4; 12:23`},
                                {id:'',texto:`La Iglesia es el cuerpo de Cristo, la morada de Dios por el Espíritu Santo, con el encargo divino de llevar a cabo su gran comisión. Todo creyente, nacido del Espíritu Santo, es parte integral de la asamblea general e iglesia de los primogénitos, que están inscritos en los cielos (Efesios 1:22, 23; 2:22; Hebreos 12:23).`},
                                {id:'',texto:`Siendo que el propósito de Dios en relación con el hombre es buscar y salvar lo que se había perdido, ser adorado por el ser humano y edificar un cuerpo de creyentes a la imagen de su Hijo, la principal razón de ser de las Asambleas de Dios como parte de la Iglesia es:`},
                                {id:'a',texto:`Ser una agencia de Dios para la evangelización del mundo (Hechos 1:8; Mateo 28:19, 20; Marcos 16:15, 16).`},
                                {id:'b',texto:`Ser un cuerpo corporativo en el que el hombre pueda adorar a Dios (1 Corintios 12:13).`},
                                {id:'c',texto:`Ser un canal para el propósito de Dios de edificar a un cuerpo de santos siendo perfeccionados a la imagen de su Hijo (Efesios 4:11–16; 1 Corintios 12:28; 14:12).`},
                                {id:`Las Asambleas de Dios existe expresamente para dar continuo énfasis a esta razón de ser según el modelo apostólico del Nuevo Testamento enseñando a los creyentes y alentándolos a que sean bautizados en el Espíritu Santo. Esta experiencia:`},
                                {id:'a',texto:`Los capacita para evangelizar en el poder del Espíritu con señales y milagros (Marcos 16:15–20; Hechos 4:29–31; Hebreos 2:3, 4).`},
                                {id:'b',texto:`Agrega una dimensión necesaria a la adoración y a la relación con Dios (1 Corintios 2:10–16; 1 Corintios 12–14)`},
                                {id:'c',texto:`Los capacita para responder a la plena manifestación del Espíritu Santo en la expresión de frutos, dones y ministerios como en los tiempos del Nuevo Testamento para la edificación del cuerpo de Cristo (Gálatas 5:22–26; 1 Corintios 14:12; Efesios 4:11, 12; 1 Corintios 12:28; Colosenses 1:29).\nNuestro Señor ha provisto un ministerio divinamente llamado y ordenado con el triple propósito de dirigir a la iglesia en: (1) la evangelización del mundo (Marcos 16:15–20), (2) la adoración a Dios (Juan 4:23, 24) y (3) la edificación de un cuerpo de santos, para perfeccionarlos a la imagen de su Hijo (Efesios 4:11, 16).`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`12. Doctrina sobre la resurección y el arrebatamiento.`,
                            subtitulo:`La resurrección de los redimidos y el arrebatamiento de la iglesia antes de la tribulación.`,
                            grupo:1,
                            puntos:[
                                {id:'',texto:'Romanos 8:23; 1 Tesalonicenses 1:10; 4:16, 17; Tito 2:13'},
                                {id:'',texto:`La resurrección de los que han muerto en Cristo y su arrebatamiento junto con los que estén vivoscuando sea la venida del Señor es la esperanza inminente y bienaventurada de la Iglesia (1 Tesalonicenses 4:16, 17; Romanos 8:23; Tito 2:13; 1 Corintios 15:51, 52).`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`13. Doctrina sobre la Segunda Venida de Jesucristo.`,
                            subtitulo:`La Segunda Venida visible de Jesucristo para establecer su Reino Milenial en la Tierra.`,
                            grupo:1,
                            puntos:[
                                {id:'',texto:'Zacarías 14:5; Mateo 24:27,30; Romanos 11:26,27; 2 Tesalonicenses 1:7; Apocalipsis 20.'},
                                {id:'',texto:`La segunda venida de Cristo incluye el rapto de los santos, que es nuestra esperanza bienaventurada, seguido por el regreso visible de Cristo con sus santos para reinar sobre la tierra por mil años (Zacarías 14:5; Mateo 24:27–30; Apocalipsis 1:7; 19:11–14; 20:1–6). Este reino milenario traerá la salvación de Israel como nación (Ezequiel 37:21, 22; Sofonías 3:19,20; Romanos 11:26,27) y el establecimiento de una paz universal (Isaías 11:6–9; Salmo 72:3–8; Miqueas 4:3, 4).`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`14. Doctrina sobre el Lago de Fuego.`,
                            subtitulo:`El Lago de Fuego como castigo eterno para los impíos, Satanás y los ángeles caídos.`,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Apocalipsis 19:20; 20:10-15`},
                                {id:'',texto:`Habrá un juicio final en el que los pecadores muertos serán resucitados y juzgados según sus obras. Todo aquel cuyo nombre no se halle en el Libro de la Vida, será confinado a sufrir castigo eterno en el lago que arde con fuego y azufre, que es la muerte segunda, junto con el diablo y sus ángeles, la bestia y el falso profeta (Mateo 25:46; Marcos 9:43–48; Apocalipsis 19:20; 20:11–15; 21:8).`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`15. Doctrina sobre el Cielo.`,
                            subtitulo:`El cielo como morada eterna de los salvos.Juan 14:2; 1 Corintios 2:9-10; Apocalipsis 21:22`,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Juan 14:2; 1 Corintios 2:9-10; Apocalipsis 21:22`},
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`16. Doctrina sobre los Cielos Nuevos y la Tierra Nueva.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`1 Pedro 3:13; Apocalipsis 21:2 "Pero nosotros esperamos, según sus promesas, cielos nuevos y tierra nueva, en los cuales mora la justicia" (2 Pedro 3:13; Apocalipsis 21:22).`}
                            ]
                        }
   
        ]
}



export const estatutos = {version:1,
    observaciones:``,
    titulo:'DOCUMENTO DE TESTIMONIO y ESTATUTOS DE LA UNION DE LAS ASAMBLEAS DE DIOS',
    subtitulo:'',
    fecha:'01/01/2023',
    secciones:[{
        tituloGrande:null,
        titulo:`ARTICULO I – NOMBRE`,
        subtitulo:``,
        grupo:1,
        puntos:[
            {id:'',texto:`Bajo la denominación de UNION DE LAS ASAMBLEAS DE DIOS se constituye el día seis de Junio de mil novecientos cuarenta y siete una Asociación de Iglesias Evangélicas.`}
        ]},
        {
            tituloGrande:null,
            titulo:`ARTICULO II – FINES Y PROPOSITOS`,
            subtitulo:``,
            grupo:1,
            puntos:[
                {id:'1',texto:`Promover la más amplia difusión del Evangelio de Nuestro Señor Jesucristo, según las Sagradas Escrituras y de acuerdo a nuestra Declaración de Fe, con el firme propósito de alcanzar una sólida comunión espiritual fundada en los principios de amor, justicia y verdad.`},
                {id:'2',texto:`Cumplir fielmente la Gran Comisión (Mateo 28:19-20) que el Señor Jesucristo encomendó a su Iglesia, a través de la enseñanza y la evangelización, con la creación de medios de difusión propios, tendientes al cumplimiento de dicha finalidad.`},
                {id:'3',texto:`Fomentar y desarrollar el Reino de Cristo a través de la obra misionera en el país y siendo de ejemplo y utilidad en cada comunidad donde se estableciere esta Asociación.`},
                {id:'4',texto:`Establecer y supervisar el funcionamiento local de las filiales de predicación, delegaciones y representaciones, como asimismo de las entidades de servicio, tendientes a realizar los postulados implícitos en el inciso dos.`},
                {id:'5',texto:`Practicar el bien mediante la realización y auspicio de obras de caridad, filantrópicas y establecimientos de beneficencias. A tal fin esta Asociación se compromete a colaborar, en caso de ser menester, con entidades de bien público y de reconocido prestigio en nuestra sociedad.`},
                {id:'6',texto:`Desarrollar y supervisar el sostenimiento de esfuerzos locales o nacionales de la obra educativa, mediante la fundación de instituciones de difusión y enseñanza en todos sus niveles y modalidades, creando para ello institutos, seminarios bíblicos, escuelas, colegios, universidades, bibliotecas y otros por crearse.`},
                {id:'7',texto:`Establecer librerías, imprentas, publicar libros, revistas, boletines y otros medios de difusión en consonancia con los fines de la institución.`},
            ]},
            {
                tituloGrande:null,
                titulo:`ARTICULO III – DOMICILIO LEGAL`,
                subtitulo:``,
                grupo:1,
                puntos:[
                    {id:'',texto:`Queda fijado en la ciudad de Buenos Aires. Podrá establecer iglesias filiales, anexos, delegaciones y representaciones en cualquier punto del país`}
                ]}, 
                {
                    tituloGrande:null,
                    titulo:`ARTICULO IV – PATRIMONIO SOCIAL – CONSTITUCION`,
                    subtitulo:``,
                    grupo:1,
                    puntos:[
                        {id:'',texto:`Se formará de la siguiente manera: `},
                        {id:'a',texto:`Con las ofrendas, donaciones, legados, cesiones y subvenciones que reciba.`},
                        {id:'b',texto:`Con los aportes voluntarios que efectúen los miembros de la Asociación.`},
                        {id:'c',texto:`Con los bienes propios y bienes de las filiales, delegaciones o representaciones, que posee en la actualidad y los que adquiera en lo sucesivo por cualquier título, así como las rentas que los mismos produzcan , y cualquier otra fuente legalmente admitida.`}
                    ]},      
                    {
                        tituloGrande:null,
                        titulo:`ARTICULO V – CAPACIDADES DE LA ASOCIACION`,
                        subtitulo:``,
                        grupo:1,
                        puntos:[
                            {id:'',texto:`La Asociación se encuentra capacitada para:`},
                            {id:'a',texto:`Adquirir bienes raíces, muebles, semovientes, títulos y/o acciones, ya sea para compra, permuta, cesión, legado o donación, pudiendo enajenarlos a título oneroso o gratuito ya sean registrables o no y/o cualquier otro modo de adquisición admitido por las leyes.`},
                            {id:'b',texto:`Tomar en préstamo dinero, valores o otros efectos, con o sin garantía real o personal, tanto de particulares, como de instituciones bancarias, privadas u oficiales, nacionales o internacionales, provinciales o municipales y de cualquier otra entidad habilitada para cumplir dicha finalidad.`},
                            {id:'c',texto:`Dar o tomar en locación bienes muebles, inmuebles o semovientes, aún por el máximo legal establecido.`},
                            {id:'d',texto:`Transigir, comprometer en árbitros, arbitradores o amigables componedores, celebrar toda clase de convenios y cualquier otro acto jurídico permitido por las leyes del país, que tengan relación con sus bienes y tiendan a la más apropiada consecución de sus fines, agregar la presentación en juicio a los efectos de la defensa de su honor y de los bienes de la Asociación.`}
                        ]},  
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO VI – MEMBRESIA`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Los miembros de la Asociación se clasifican en:`},
                                {id:'a',texto:`MIEMBROS ACTIVOS NACIONALES. Son todos aquellos que poseen alguna de las credenciales otorgadas en el orden nacional; son las siguientes: Ministro Ordenado, Ministro Licenciado o Licenciada, quienes han sido aprobados por el Consejo Directivo Nacional.`},
                                {id:'b',texto:`MIEMBROS ADHERENTES. Serán miembros adherentes:`},
                                {id:'1',texto:`Escalafón ministerial: Los Ministros/as Autorizados/as y Predicadores/as Autorizados/as por la Comisión Regional con informe y aval del Consejo Directivo Nacional. `},
                                {id:'2',texto:`Escalafón Departamental: Los Funcionarios de los Departamentos Nacionales, Funcionarios de los Departamentos Regionales, Funcionarios de los Departamentos Distritales y Funcionarios de los Departamentos Seccionales, recibirán credenciales otorgadas a los funcionarios de las secciones, de los distritos, de las regiones y a nivel nacional para ejercer las funciones en los departamentos. Estas credenciales son otorgadas a los miembros activos fieles de las iglesias locales mientras dure el tiempo de sus funciones específicas fuera del ámbito de su iglesia.`},
                                {id:'3',texto:`Escalafón Local: Funcionarios de la Junta Oficial Local, Obrero Local y Funcionario de Departamento Local, recibirán credenciales otorgadas por el pastor de cada iglesia autónoma con un informe previo y aval escrito de la Comisión Regional a los obreros, a los funcionarios de las distintas comisiones y departamentos de cada iglesia local. Estas credenciales se otorgan por el tiempo que duren sus funciones específicas dentro del ámbito de la iglesia local.`},
                                {id:'4',texto:`Los fieles en plena comunión que figuran en los registros de las iglesias establecidas, anexos, delegaciones y representaciones.`},
                                {id:'5',texto:`Ministro Ordenado Ejecutivo: Los Miembros Activos Nacionales o Regionales en el Escalafón Ministerial elegidos en el Consejo Directivo Nacional, Comisión Regional, Superintendente de Distrito o Presbítero de Sección, recibirá la credencial de Ministro Ordenado Ejecutivo en el nivel nacional, regional, distrital o seccional, según corresponda; en forma automática y por el tiempo que ejerza su función para que lo identifique en sus tareas.`},
                                ]},  
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO VII – BIENES DE LA IGLESIA LOCAL Y ENTIDADES DE SERVICIO`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Con relación a los bienes de la iglesia local, entidades de servicio, se deberán tener en cuenta las siguientes normas y situaciones: `},
                                {id:'a',texto:`TITULARIDAD. Todo inmueble, ya sea lote de terreno, local o templo, obtenido y/o construido con los aportes voluntarios de los miembros de la iglesia local, deberá ser escriturado a nombre de la Asociación para protección tanto de la Iglesia Local como de la misma Asociación.`},
                                {id:'b',texto:`DOCUMENTO EN CUSTODIA. El título del bien, mueble o inmueble, escriturado a nombre de la Asociación, quedará archivado en custodia en la Sede Central. El Consejo Directivo Nacional dará a la Iglesia Local un documento en el cual constará las características de la propiedad, así como la declaración de que dicha propiedad está en custodia de la Asociación, debiendo figurar el valor del inmueble en el Balance Anual, con las salvedades del caso. Se declara que estos bienes continuarán siendo propiedad de la Iglesia Local pertinente. La Titularidad de los bienes estará a nombre de la Asociación otorgándose a la Iglesia Local o Entidad de Servicio, el pleno y absoluto derecho de usufructo y tenencia, quedando bajo total responsabilidad de la propia Iglesia Local o Entidad de Servicio toda deuda, litigio, responsabilidad civil o penal en que incurriera según acuerdo firmado, en el Acta de Afiliación. `},
                                {id:'c',texto:`ESCRITURACION Y DESAFECTACION - Tanto para escriturar una propiedad a nombre de la Asociación, como para que la Asociación vuelva a escriturar a nombre de quién indique la Iglesia Local, deberá llenar los siguientes requisitos:`},
                                {id:'1',texto:`Asamblea. La Iglesia Local realizará una Asamblea con la presencia de un miembro del Consejo Directivo Nacional, u otra persona que este cuerpo designare, cuando así lo creyere conveniente. En esta Asamblea deberá obtenerse conformidad como mínimo del 75% de los miembros activos debidamente registrados.`},
                                {id:'2',texto:`Comunicación por escrito al Consejo Directivo Nacional. Luego de realizada la Asamblea de la Iglesia Local, la misma enviará al Consejo Directivo Nacional una carta solicitando la escrituración del bien a nombre de la Asociación. A esta carta se adjuntará copia del Acta de Asamblea.`},
                                {id:'3',texto:`Gastos. La Iglesia Local será responsable del pago de los gastos ocasionados por la operación.`}
                            ]},  
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO VIII – ORGANIZACIÓN Y GOBIERNO DE LA ASOCIACION`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'A',texto:`NATURALEZA Y CONSTITUCION.`},
                                {id:'',texto:`La Asociación “Unión de las Asambleas de Dios” es una fraternidad cooperativa, constituida por cristianos bautizados en Aguas y en el Espíritu Santo, e integrada por Iglesias Locales de fe y prácticas bíblicas pentecostales, establecidas en todo el país con el carácter de filiales, delegaciones o representaciones, y conforme a este Estatuto, y la respectiva Acta de Afiliación, o de apertura, según corresponda. La Asociación declara y establece las Sagradas Escrituras como Regla Unica y suficiente de fe y conducta, y considera como instrumentos fundamentales y oficiales la Declaración de Fe y el Manual Bíblico para la Iglesia Local, así como los Estatutos y los Reglamentos para el orden Nacional, Regional, Distrital, Seccional y Departamental. Tanto cada Iglesia Local incorporada como Filial a la Asociación, delegación o representación, como cada miembro activo nacional con credenciales de Ministro Licenciado, Ministro Licenciada o Ministro Ordenado, y todos los miembros adherentes de las distintas regiones, afiliados a la Asociación, deberán prestar apoyo incondicional en palabra y práctica, dentro de su esfera de actividad a los instrumentos mencionados.`},
                                {id:'B',texto:`ORGANIZACIÓN Y GOBIERNO.`},
                                {id:'',texto:`La Asociación se regirá y desarrollará sus actividades por medio de:`},
                                {id:'1',texto:`La Asamblea General. Es el cuerpo legislativo único de la Asociación, cuyo sistema de gobierno es congregacional. La autoridad suprema reside en todos los miembros de la Asociación, quienes a su vez delegan en la Asamblea General por intermedio de los miembros activos nacionales y delegados de las iglesias Locales, de acuerdo a los presentes estatutos.`},
                                {id:'2',texto:`El Consejo Directivo Nacional. Está constituido por la Junta Ejecutiva Nacional y los Presidente Regionales. Su función es la de cumplir, hacer cumplir y ejecutar todas las resoluciones de la Asamblea General, de acuerdo a los Estatutos.`},
                                {id:'3',texto:`La Junta Ejecutiva Nacional. Es elegida por la Asamblea General, y sus funciones son:`},
                                {id:'a',texto:`Ejecutar las tareas que el Consejo Directivo Nacional le asigne.`},
                                {id:'b',texto:`Cumplir y hacer cumplir todas y cada una de las resoluciones de la Asamblea General y del Consejo Directivo Nacional, de acuerdo a los Estatutos.`},
                                {id:'4',texto:`La Asamblea Regional. Es el cuerpo electivo de cada región, al cual asisten los miembros activos nacionales, adherentes en el Escalafón Ministerial, y delegados de las Iglesias Locales de cada región, de acuerdo a los presentes estatutos.`},
                                {id:'5',texto:`Comisiones Regionales: Son elegidas por la Asamblea Regional y sus funciones son:`},
                                {id:'a',texto:`Ejecutar las tareas que el Consejo Directivo Nacional les asigne, según las necesidades de la región.`},
                                {id:'b',texto:`Cumplir y hacer cumplir todas y cada una de las resoluciones de la Asamblea Regional, en total concordancia con las resoluciones tomadas en la Asamblea General, y emanadas en su ejecución del Consejo Directivo Nacional de acuerdo a los Estatutos.`},
                                {id:'6',texto:`La Asamblea Distrital. Es el cuerpo electivo de cada distrito, al cual asisten los miembros activos nacionales, adherentes en el Escalafón Ministerial y delegados de las Iglesias Locales de dicho distrito, cada cuatro años, de acuerdo a los presente estatutos.`},
                                {id:'7',texto:`Superintendente de Distrito. Es elegido por la Asamblea Distrital y sus funciones son:`},
                                {id:'a',texto:`Ejecutar las tareas que la Comisión Regional le asigne, según las necesidades del Distrito.`},
                                {id:'b',texto:`Cumplir y hacer cumplir las resoluciones que emanen del Consejo Directivo Nacional, y/o Asambleas Nacionales y/o 5 Regionales, según directivas que le indique la Comisión Regional en total concordancia con los cuerpos superiores.`},
                                {id:'c',texto:`Cumplir con la tarea de pastorear a los pastores, obreros, y funcionarios distritales que estén en su zona.`},
                                {id:'8',texto:`La Asamblea Seccional. Es el cuerpo electivo de cada Sección, al cual asisten los miembros activos nacionales, adherentes en el Escalafón Ministerial y delegados de las Iglesias Locales de dicha sección, cada cuatro años, de acuerdo a los presente estatutos.`},
                                {id:'9',texto:`Presbítero de Sección. Es elegido por la Asamblea de Sección y sus funciones son: `},
                                {id:'a',texto:`Ejecutar las tareas que el Superintendente de Distrito le asigne, según las necesidades de la Sección.`},
                                {id:'b',texto:`Cumplir y hacer cumplir las resoluciones que emanen del Consejo Directivo Nacional y/o Asambleas Nacionales y/o Regionales y/o Comisión Regional, según directivas que le indique el Superintendente de Distrito, en total concordancia con los cuerpos superiores.`},
                                {id:'c',texto:`Cumplir la tarea de pastorear a los pastores, obreros y funcionarios distritales que estén en su zona.`},
                                {id:'10',texto:`Las Iglesias Locales. Son todas las Iglesias Locales establecidas, afiliadas a esta Asociación, con autonomía y gobierno propio.`},
                                {id:'11',texto:`Las delegaciones y representaciones : Son las creadas por las autoridades nacionales, sin autonomía y con gobierno designado por éstas.`},
                                {id:'12',texto:`Los Departamentos Nacionales representan distintas áreas de nuestra fraternidad, y todos ellos están supeditados a la autoridad del Consejo Directivo Nacional, para fomentar, promover y coordinar distintos aspectos de la Obra del Señor. Los Departamentos de la Asociación son: Misiones, Educación Cristiana, Junta de Literatura, Embajadores de Cristo, Mujeres Argentinas Cristianas en Acción, Fraternidad de Varones, Evangelismo, Misioneritas, Exploradores del Rey, Desafío Juvenil y otros por crearse en el futuro, de manera que esta enumeración no es taxativa.`},
                                {id:'C',texto:`ACTA DE AFILIACION Y COMPROMISO MINISTERIAL`},
                                {id:'',texto:`El Consejo Directivo Nacional confeccionará el Acta de Afiliación, en la cual se establecerán las condiciones generales para el funcionamiento de las Iglesias Filiales, sus derechos y obligaciones ministeriales y patrimoniales, registrándolas en la Inspección General de Justicia. Asimismo dictará un Reglamento Administrativo que deberá ser cumplido tanto por la Asociación como por las filiales, delegaciones y representaciones, y por cada uno de los miembros activos nacionales, con las credenciales de Ministro Licenciados, Ministro Licenciada y Ministro Ordenado, y adherentes regionales en el Escalafón Ministerial. Para esto el Consejo Directivo Nacional confeccionará el Acta de Compromiso Ministerial que deberán suscribir los Miembros Activos Nacionales y Miembros Adherentes Regionales en el Escalafón Ministerial, integrantes de esta Asociación, en la cual se determinarán derechos y deberes de los mismos para con la Asociación “Unión de las Asambleas de Dios”. Estas actas de Compromiso Ministerial y reglamento podrán ser registrados ante la autoridad de aplicación. Dichas actas compromiso y reglamento serán presentados para su aprobación a la Asamblea General en la primera reunión posterior a la reforma del Estatuto y todo cambio en los mismos deberá seguir igual procedimiento.`}    
                            ]},
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO IX – CONSEJO DIRECTIVO NACIONAL.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`PRIMERO- INTEGRACION.`},
                                {id:'',texto:`La Asociación será dirigida y administrada por un Consejo Directivo Nacional, integrado por los siguientes miembros: Un Presidente Nacional, un Vice-presidente Nacional, un Secretario Nacional, un Tesorero Nacional, conformando al mismo tiempo la Junta Ejecutiva Nacional. El Consejo Directivo Nacional queda integrado también por los Presidentes Regionales elegidos en cada Asamblea Regional. Los Miembros de la Junta Ejecutiva Nacional y que forman parte del Consejo Directivo Nacional también, serán elegidos entre los Ministros Ordenados y deberán ser personas de conducta intachable, reconocida capacidad y que hayan prestado su apoyo financiero a la Unión de las Asambleas de Dios. Se elegirán también dos miembros suplentes. Los cargos durarán en sus funciones cuatro años.`},
                                {id:'',texto:`SEGUNDO – ELECCIONES.`},
                                {id:'',texto:`La elección de la Junta Ejecutiva Nacional, y que forma parte también del Consejo Directivo Nacional se llevará a cabo por los miembros activos nacionales de la Fraternidad, y los delegados de las Iglesias Locales Autónomas, debidamente acreditados, que estén presentes en la Asamblea General Ordinaria. El procedimiento de la elección se hará de acuerdo a los siguientes pasos: Para las elecciones de funcionarios nacionales, se procederá de la siguiente forma:`},
                                {id:'a',texto:`Podrán ser postulados los nombres de los posibles candidatos según lista que el Consejo Directivo Nacional presentará con la totalidad de los Ministros Ordenados con credencial vigente y al día con sus obligaciones hacia la Asociación. Los candidatos deben estar presente en la Asamblea; de no ser así deben enviar al Secretario una carta explicando la causa justificada de su ausencia, y su decisión de dejar o no su nombre como candidato; la decisión final si se acepta dejar estos candidatos que no están presentes para ser nominados la tiene la misma Asamblea.`},
                                {id:'b',texto:`Se realizará primero un voto de propuesta para los cargos de Presidente y Vice-Presidente; los diez más propuestos se presentarán para que la Asamblea vote. Se elegirá en primer término el cargo de Presidente Nacional, quién deberá reunir la mitad mas uno de los votos para ser electo. En caso de no haber elección despues de la primera votación, será elegido en una nueva votación entre los dos candidatos más votados y saldrá elegido el que obtenga mayor número de votos .`},
                                {id:'c',texto:`Para el cargo de Vice-Presidente Nacional, se elegirá en segundo término de la misma propuesta anterior,quién deberá reunir la mitad mas uno de los votos para ser electo. En caso de no haber elección despues de la primera votación, será elegido de entre los dos más votados, en una nueva votación el que obtenga el mayor número de votos.`},
                                {id:'d',texto:`Para los cargos de Secretario Nacional y Tesorero Nacional, se utilizará el mismo mecanismo antes mencionado en el punto b), pero realizando un nuevo voto de propuesta.`},
                                {id:'e',texto:`Habrá un comité de escrutinio integrado por siete personas, presidido por el Secretario Nacional e integrado por dos Ministros Ordenados como mínimo, los demás integrantes deben ser Miembros Activos Nacionales, cuya función será:`},
                                {id:'1',texto:`Anotar en lugar visible los nombre de los candidatos.`},
                                {id:'2',texto:`Distribuir y recoger las boletas.`},
                                {id:'3',texto:`Contar los votos.`},
                                {id:'4',texto:`Presentar informe escrito del resultado de la votación a la Presidencia, para el anuncio oficial a la Asamblea.`},
                                {id:'5',texto:`Conservar hasta el final de la Asamblea los datos de las votaciones.`},
                                {id:'f',texto:`Cualquier ministro que acepte postular su nombre, está en la obligación de aceptar el nombramiento de que fuere objeto. Será optativo al ser electo, que el Presidente Nacional deba abandonar, ceder temporal o definitivamente, todo otro ministerio o pastorado que tuviere al momento de ser elegido. Se deja establecido que los dos nombres restantes que obtengan más votos en esta última votación por el sistema de simple mayoría, serán los miembros suplentes de la Junta Ejecutiva Nacional. El resultado se comunicará en la primera circular a los Miembros Activos Nacional y Adherentes Regionales de la Fraternidad.`},
                                {id:'',texto:`VACANTES. Las posibles vacantes de los titulares que se produjeren en el curso del mandato, se suplirán de la siguiente manera:`},
                                {id:'',texto:`El cargo de Presidente por el Vice-Presidente, y en los demás cargos la sucesión será por ejecutivo inmediato inferior, según fueron elegidos por la Asamblea Ordinaria. Si alguno de estos ministros adoptara una dedicación exclusiva a nivel nacional la Asociación por intermedio del Consejo Directivo Nacional se compromete a: `},
                                {id:'a',texto:`Asignarle una ayuda digna mensual acorde a las necesidades del Ministerio y a las posibilidades de la Fraternidad, con los límites establecidos por la Ley 24.475.`},
                                {id:'b',texto:`En caso de que cesare en su función se le otorgará un resguardo financiero por seis meses como máximo, o hasta que asuma otro pastorado o se le asigne un empleo administrativo dentro de la Oficina Central.`},
                                {id:'c',texto:`En el caso de destitución del cargo, la Asociación deberá cursar la notificación legal pertinente y de igual modo el miembro renunciante.`},
                                {id:'',texto:`TERCERO. FACULTADES Y FUNCIONES GENERALES.`},
                                {id:'1',texto:`Las Facultades y Funciones del Consejo Directivo Nacional de la Asociación son las siguientes:`},
                                {id:'a',texto:`Ejercer en general todas aquellas funciones inherentes a la dirección, administración y representación de la Asociación, quedando facultada a este respecto para resolver por sí los casos no previstos en estos Estatutos, debiendo dar cuenta a la Asamblea General de la actuaciones especiales no contempladas en dichos Estatutos.`},
                                {id:'b',texto:`Administrar la entidad, a cuyo efecto podrá realizar todas las operaciones financieras y actos legales necesarios relativos a la conservación, fomento, desarrollo y protección del patrimonio de la Asociación, debiendo en cada caso dar cuenta a la próxima Asamblea General. En caso de hipoteca o enajenación de bienes, el Consejo Directivo Nacional para poder actuar válidamente, necesitará autorización previa de una Asamblea General.`},
                                {id:'c',texto:`Convocar a las Asambleas Generales Ordinarias y Extraordinarias.`},
                                {id:'d',texto:`Llevar y mantener actualizados los libros de contabilidad y otros exigidos por las disposiciones legales en vigencia, así como registro de los miembros activos nacionales y todos los miembros adherentes regionales, con todos los detalles informativos necesarios.`},
                                {id:'e',texto:`Nombrar, suspender y/o destituir empleados y demás personal necesario para el correcto desempeño de las funciones administrativas de la entidad, así como fijar la renumeración de los mismos y contratar todos los servicios que fueren necesarios para el bien de la Fraternidad.`},
                                {id:'f',texto:`Dictar los reglamentos internos necesarios para la debida instrumentación e implementación de los presentes Estatutos, los que deberán ser aprobados previamente por la Asamblea General.`},
                                {id:'g',texto:`Reconocer a las Iglesias y Entidades de Servicio de la Asociación en sus diferentes categorías y otorgar el carácter de filial y la consecunte autonomía a las que cumplan los requisitos exigidos por la Inspección General de Justicia y el Reglamento Administrativo. `},
                                {id:'h',texto:`Expedir a los interesados las credenciales otorgadas a nivel nacional.`},
                                {id:'i',texto:`Proceder inmediatamente para reemplazar del cargo a uno de sus propios integrantes en caso de enfermedad incapacitante.`},
                                {id:'j',texto:`Proceder inmediatamente para destituir a uno de sus propios integrantes en caso de problemas morales, mala relación con su esposa o familia, deficiente imagen cristiana; con la cantidad suficiente de pruebas para corroborar cada situación. Debiendo convocar para tomar esta decisión al Cuerpo Consultivo conformado además por los Superintendentes Distritales, en el caso de un integrante de la Junta Ejecutiva, y si fuera un Presidente Regional el Cuerpo Consultivo además estará formado por los Superintendentes de la misma región.`},
                                {id:'k',texto:`Nombrar los Presidentes Nacionales de los diferentes Departamentos e Instituciones, a referencia de la próxima Asamblea, cuando sucediere una vacancia o alejamiento permanente de uno de sus directivos y escoger el resto de los componentes de las Comisiones Nacionales de los Departamentos, de las listas presentadas por los respectivos Directores Nacionales, a referencia de la próxima Asamblea. Cuando sucediere una vacancia o alejamiento permanente de un integrante de la Comisión Nacional.`},
                                {id:'l',texto:`Designar los suplentes para vacantes que se presenten en cualquiera de las comisiones regionales, para desempeñar los cargos pertinentes a referencia de la próxima Asamblea.`},
                                {id:'m',texto:`Designar los integrantes de los Comités asesores que sean indispensables a los fines de la Asociación, pudiendo delegar en tales comités parte de sus responsabilidades.`},
                                {id:'n',texto:`Aplicar sanciones disciplinarias a funcionarios, ministros y/o iglesias que infrinjan las normas de la U.A.D.. Definir el retiro disciplinario de funcionarios, ministros y/o iglesias que no hayan querido sujetarse a las normas canónicas, administrativas y económicas establecidas por la U.A.D.`},
                                {id:'ñ',texto:`Aprobar la adquisición de bienes inmuebles, su enajenación o gravamen y la constitución de garantías reales sobre ellos, y en particular todo endeudamiento.`},
                                {id:'o',texto:`Autorizar la apertura de Cuentas Corrientes Bancarias para las Iglesias Filiales, Delegaciones, Representaciones y/o Entidades de Servicio, a nombre de la Asociación y con la utilización del CUIT de la misma.`},
                                {id:'p',texto:`Autorizar al Presidente Nacional o Tesorero Nacional para gestionar o recibir créditos para atender proyectos específicos de desarrollo.`},
                                {id:'q',texto:`Decidir sobre el ejercicio de acciones judiciales, transigir o conciliar cualquier litigio que tenga la U.A.D.`},
                                {id:'r',texto:`Aprobar las modificaciones en el escalafón ministerial de quienes hayan cumplido con todos los requisitos exigidos por el reglamento pertinente para las credenciales de Ministro Licenciado, Ministro Licenciada y Ministro Ordenado.`},
                                {id:'s',texto:`Elaborar la lista de los ministros que llenen los requisitos para ocupar cargos en las regiones, distritos y secciones.`},
                                {id:'t',texto:`Establecer los porcentuales que le corresponde a cada región del fondo compensatorio de distribución entre las regiones según la extensión, cantidad de iglesias y necesidades propias de cada región. u. Confeccionar el Acta de Afiliación para que la suscriban las Iglesias y el Acta de Compromiso Ministerial para que lo firmen todos los miembros activos y adherentes en todos sus niveles.`},
                                {id:'2',texto:`Las Facultades y Funciones de la Junta Ejecutiva Nacional de la Asociación son:`},
                                {id:'a',texto:`Dirigir y orientar las actividades de la Unión de las Asambleas de Dios delegadas por el Consejo Directivo Nacional en el tiempo que media entre las reuniones del mismo.`},
                                {id:'b',texto:`Expedir su propio reglamento de trabajo interno.`},
                                {id:'c',texto:`Aprobar las plazas y niveles salariales del personal administrativo necesario para el correcto y eficiente manejo de las oficinas.`},
                                {id:'d',texto:`Fijar la asignación económica del Presidente Nacional y demás integrantes de la Junta Ejecutiva Nacional en consulta con el Consejo Directivo Nacional, y en ajuste a la Ley 24.475.`},
                                {id:'e',texto:`Estudiar y aprobar la creación de Fondos Especiales con destino específico y expedir su respectiva reglamentación de manejo.`},
                                {id:'f',texto:`Escribir, hablar, reunir a los pastores de toda la Fraternidad.`},
                                {id:'g',texto:`Nombrar, supervisar y remover a los asesores que cooperan en la tarea de la Junta Ejecutiva Nacional. En cualquier situación que el Consejo Directivo Nacional o la Junta Ejecutiva Nacional contara con un número par en sus miembros o empatar alguna votación, el voto del Presidente valdrá doble para toda decisión.`},

                            ]
                        } ,
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO X – DEBERES ESPECIFICOS DE CADA MIEMBRO DEL CONSEJO DIRECTIVO 
                            NACIONAL.`,
                            subtitulo:`Los deberes y funciones específicos de cada uno de los miembros del Consejo Directivo Nacional son los siguientes:`,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`1. Del PRESIDENTE: `},
                                {id:'a',texto:`Ejercer la representación legal de la Asociación entre los poderes públicos, autoridades y organismos de cualquier índole, así como ante Asociaciones fraternas dentro o fuera del país, cuando las circunstancias lo demanden.`},
                                {id:'b',texto:`Intervenir en todos los asuntos administrativos y/o judiciales que se susciten, relacionados con la Asociación. `},
                                {id:'c',texto:`Conferir con la firma conjunta del Secretario, Poderes Generales y/o Especiales a miembros o a terceros y/o revocarlos. Expedir poderes específicos a los Presidente Regionales o a los Superintendentes de Distrito para agilizar la realización de diligencias oficiales a nombre de la Asociación.`},
                                {id:'d',texto:`Convocar y presidir las Asambleas Generales así como las sesiones de la Junta Ejecutiva, y del Consejo Directivo Nacional de acuerdo a los Estatutos.`},
                                {id:'e',texto:`Decidir con su voto, cuyo valor será doble en este caso, los posibles empates de las votaciones de todas las reuniones cuya presidencia esté a su cargo.`},
                                {id:'f',texto:`Velar por el fiel cumplimiento de los Estatutos y Reglamentos de la Asociación.`},
                                {id:'g',texto:`Tomar las disposiciones pertinentes para ejecutar las decisiones de la Junta Ejecutiva, Consejo Directivo Nacional y Asambleas.`},
                                {id:'h',texto:`Suscribir, juntamente con el Tesorero o en su defecto, con el Secretario, toda la documentación relativa a las operaciones financieras y de manejo de fondos. Abrir cuentas bancarias a nombre de la Asociación y efectuar todas las operaciones inherentes a las mismas con la firma del Tesorero y Secretario. Para todo retiro de fondos será necesaria la firma del Presidente juntamente con cualesquiera de las otras dos firmas en forma indistinta.`},
                                {id:'i',texto:`Suscribir juntamente con el Secretario, toda la documentación y correspondencia oficial, instrumentos públicos y privados, actas pertinentes y cualquier otra documentación que fuere necesario.`},
                                {id:'j',texto:`Suspender conjuntamente con el Secretario a cualquier empleado que no cumpla con sus obligaciones dando cuenta inmediata a la Junta Ejecutiva Nacional. `},
                                {id:'k',texto:`Tomar por sí mismo decisiones en casos urgentes ordinarios, dando cuenta inmediato al Consejo Directivo Nacional, cuya autoridad es la única competente y siempre necesaria en todas las medidas y resoluciones extraordinarias.`},
                                {id:'l',texto:`Ser presidente titular de toda Asamblea General y Regional, así como de los cuerpos de la Junta Ejecutiva Nacional y del Consejo Directivo Nacional. Así también el Presidente es miembro “ex –officio” y Presidente Honorario de toda Comisión Nacional, Regional, Distrital, Seccional, Departamental y de Asesoría.`},
                                {id:'m',texto:`Supervisar que las regiones, distritos, secciones y departamentos cumplan oportunamente sus obligaciones para con la Asociación y/o delegar en el Vice-Presidente. `},
                                {id:'n',texto:`Establecer y exigir el manejo de estadísticas y presenta informes periódicamente al Consejo Directivo Nacional.`},
                                {id:'ñ',texto:`Expedir las credenciales respectivas a los funcionarios de los Comités, Institutos y Departamentos a nivel nacional.`},
                                {id:'o',texto:`Asistir, asesorar y presidir las Asambleas regionales con derecho a voz y voto y presidir las sesiones en las cuales se eligen los miembros del Consejo Regional y/o delegar en el Vice-Presidente.`},
                                {id:'p',texto:`Firmar las credenciales de Ministros Licenciados, Ministros Ordenados, credenciales de los ejecutivos nacionales conjuntamente con el Secretario.`},
                                {id:'q',texto:`Asignar funciones especiales al Vice-Presidente y a los representantes regionales como integrantes del Consejo Directivo Nacional.`},
                                {id:'',texto:`2. Del VICE PRESIDENTE:`},
                                {id:'a',texto:`Reemplazar al Presidente en casos de ausencia, ya sea accidental o permanente, incapacidad, renuncia o fallecimiento.`},
                                {id:'b',texto:`Asistir a las reuniones de la Junta Ejecutiva Nacional, Consejo Directivo Nacional y a las Asambleas Generales.`},
                                {id:'c',texto:`Llevar a cabo todas las representaciones, funciones y/o actividades que el Presidente le encomendase o delegare. d) Desempeñarse como miembro y Presidente de la Comisión de Disciplina.`},
                                {id:'',texto:`3. Del SECRETARIO:`},
                                {id:'a',texto:`Colaborar con el Presidente en todas sus responsabilidades y actividades.`},
                                {id:'b',texto:`Asistir a las reuniones de la Junta Ejecutiva, Consejo Directivo Nacional y a las Asambleas Generales.`},
                                {id:'c',texto:`Tener a su cargo el Libro de Actas Oficial, en el que se asentará y firmará juntamente con el Presidente las Actas correspondientes a cada reunión.`},
                                {id:'d',texto:`Tener a su cargo las comunicaciones y notas dirigidas y recibidas de otras instituciones, asì como cualquier otra correspondencia que el Presidente le encomendare.`},
                                {id:'e',texto:`Llevar y mantener estadísticas e información actualizada de la Obra Nacional, así como de las Iglesias de la Asociación, con la colaboración de las mismas.`},
                                {id:'f',texto:`Presentar a la Asamblea General un infome, refrendado por el Presidente, de las actividades del Consejo Directivo Nacional y del desarrollo general de la Obra.`},
                                {id:'g',texto:`Llevar y mantener actualizado un Archivo Nacional con toda la documentación y material que fuera necesario.`},
                                {id:'h',texto:`Tramitar ante las autoridades correspondientes la expedición de los Certificados de Personería Jurídica, Ficheros de Culto, impuestos y servicios. `},
                                {id:'i',texto:`Revisar semestralmente el estado de actualización de los registros de ministros e iglesias afiliadas a la Asociación para informar las anomalías al Consejo Directivo Nacional.`},
                                {id:'j',texto:`Reemplazar al Presidente Nacional en sus ausencias temporales o permanentes cuando el Vice-Presidente Nacional no pueda hacer este reemplazo por cualquier razón. `},
                                {id:'k',texto:`Presidir el Comité General de Asesoría de la Junta Ejecutiva Nacional.`},
                                {id:'',texto:`4. Del TESORERO:`},
                                {id:'a',texto:`Tener a su cargo y mantener actualizados, todos los libros contables necesarios, de acuerdo a las disposiciones legales en vigencia. Juntamente con el Presidente suscribirá todas las operaciones relativas a manejo de fondos.`},
                                {id:'b',texto:`Preparar y presentar toda clase de balances, mensuales, anuales, generales, periódicos, así como inventarios y/u otras informaciones financerias, que deberán ser consideradas, aprobadas y elevadas al Consejo Directivo Nacional o Asambleas.`},
                                {id:'c',texto:`Suscribir en conjunto con el Presidente todas las operaciones bancarias que exijan su firma. `},
                                {id:'d',texto:`Retener y ser responsable del dinero en caja para pagos de urgencia, cuya cantidad fijará previamente la Junta Ejecutiva Nacional.`},
                                {id:'e',texto:`Dar cuenta del estado económico de la entidad al Consejo Directivo Nacional, toda vez que la misma lo requiera.`},
                                {id:'f',texto:`Cada vez que sea necesario enviar cartas de reclamo de cobro a las regiones de las iglesias o de los miembros activos o adherentes del escalafón ministerial.`},
                                {id:'g',texto:`Velar por la actualización oportuna de toda documentación pertinente ante las autoridades.`},
                                {id:'h',texto:`Desempeñar cualesquiera otras funciones que le delegue el Consejo Directivo Nacional o el Presidente Nacional.`},
                                {id:'i',texto:`Desempeñarse como controlador general de caja de las regiones, departamentos y comités.`},
                                {id:'',texto:`5. De los PRESIDENTES REGIONALES:`},
                                {id:'a',texto:`Asistir a las reuniones del Consejo Directivo Nacional y a las Asambleas General, Regionales y presidir las Asambleas Distritales y Seccionales.`},
                                {id:'b',texto:`Desempeñar las comisiones y tareas que el Consejo le asigne. Además de las tareas inherentes a su responsabilidad en la región.`},
                                {id:'',texto:`6. De los MIEMBROS SUPLENTES:`},
                                {id:'',texto:`Los miembros suplentes reemplazarán a los miembros titulares de la Junta Ejecutiva Nacional, según el orden de elección, en caso de renuncia, fallecimiento, incapacidad o ausencia permanente de los mismos. Queda establecido que si el Presidente debe ser reemplazado por alguna de las causas anteriormente establecidas, podrá serlo únicamente por el Vice-Presidente. En caso de reemplazo del Vice-Presidente, podrá serlo únicamente por el Secretario. En caso de reemplazo del Secretario podrá serlo únicamente por el Tesorero. En caso de reemplazo del Tesorero, podrá serlo únicamente por el Primer Miembro Suplente y así sucesivamente el resto de los cargos. En caso de reemplazo de algún integrante de la Junta Ejecutiva Nacional, el Consejo Directivo Nacional pondrá en posesión de su cargo al miembro que corresponda, notificando por carta circular a los miembros de la Asociación e informando oficialmente a la próxima Asamblea General Ordinaria.`}
                            ] 
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XI – ACEFALIA.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Si la Junta Ejecutiva Nacional perdiere su quòrum mínimo, quedando reducido el número de sus miembros a menos de la mitad más uno, a pesar de haber sido incorporados los suplentes, el Consejo Directivo Nacional en minoría deberá convocar dentro de los treinta días a partir de la fecha de producida la acefalía, a una Asamblea Extraordinaria en la que se elegirán los miembros para ocupar los cargos vacantes. Los mismos durarán en sus funciones hasta la terminación del mandato de los cesantes.`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XII – ASAMBLEA GENERAL ORDINARIA.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Se reunirá anualmente dentro de los ciento veinte días posteriores al cierre del Ejercicio Financiero, que se efectuará el 30 de Setiembre de cada año. Podrá realizarse en todo el territorio nacional debiéndose comunicar su localización a la Inspección General de Justicia. Para que estas Asambleas sean válidas y legales se requerirá la presencia física de la mitad más uno de los miembros activos nacionales, de acuerdo al siguiente orden del día: `},
                                {id:'1',texto:`Lectura Bíblica y oración.`},
                                {id:'2',texto:`Informe del Consejo Directivo Nacional que incluirá Memoria, Inventario, Balance General y Cuenta de Ganancias y Pérdidas correspondiente al Ejercicio Anual. Estos documentos deberán remitirse a la Inspección General de Justicia. `},
                                {id:'3',texto:`Informe de Comisiones especiales.`},
                                {id:'4',texto:`Todo asunto que considere conveniente incluir el Consejo Directivo Nacional o a pedido del 10% de los miembros activos nacionales son sesenta dìas de anticipación.`},
                                {id:'5',texto:`Nombramiento de dos Ministros Ordenados concurrentes para que aprueben y firmen el Acta respectiva.`},
                                {id:'6',texto:`Nombramiento de un Ministro Ordenado y un delegado laico concurrentes, como Revisores de Cuentas, quienes revisarán la documentación presentada a la Asamblea General, firmando y efectuando un arqueo de caja.`},
                                {id:'7',texto:`Y en el año que correspondiere elección de los miembros de la Junta Ejecutiva Nacional se deberá agregar este punto en el Orden del Día en el Punto 4 del mismo. Elección de dos miembros suplentes. En caso de que a la primera reunión no asistiera el quórum mínimo de miembros, la mitad más uno de los miembros activos nacionales, necesario para sesionar legalmente, según el punto anterior, tendrá lugar una segunda reunión transcurrida media hora de la fijada en la convocatoria, con los miembros activos nacionales que concurran, cualesquiera sea su número.`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XIII – CONVOCATORIA.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`La Asamblea General Ordinaria será convocada con un mes de anticipación por medio de citación escrita que hará el Secretario. Juntamente con la convocatoria, se hará llegar a cada miembro activo nacional el Orden del Día que se tratará en la Asamblea, no pudiendo ser considerados otros asuntos que no hayan sido incluidos en el mismo.`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XIV – ASAMBLEA GENERAL EXTRAORDINARIA.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Se celebrará cada vez que sea convocada por el Consejo Directivo Nacional cuando lo estime necesario o a solicitud elevada a la misma y firmada por lo menos por el 51% de los miembros activos de la Asociación. El pedido deberá ser resuelto por el Consejo Directivo Nacional dentro de un plazo no mayor de veinte días. Se tratarán los asuntos que hubiere motivado su convocatoria y los que estime conveniente someter el Consejo Directivo Nacional. Regirán para esta Asamblea los requisitos establecidos en los artículos duodécimo y décimo tercero en lo que fueren pertinentes.`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XV – COMISION REGIONAL.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`PRIMERO. INTEGRACION.`},
                                {id:'',texto:`La Asociación a nivel regional será dirigida y administrada por la Comisión Regional que está bajo la autoridad del Consejo Directivo Nacional, integrado por los siguientes miembros: Un Presidente Regional, un Vice-Presidente Regional, un Secretario Regional, un Tesorero Regional, y un Superintendente por cada Distrito. Los miembros de la Comisión Regional serán elegidos entre los Ministros Ordenados y deberán ser personas de conducta intachable, reconocida capacidad y que hayan prestado su apoyo financiero a la Unión de las Asambleas de Dios. Se elegirán también dos miembros suplentes. Los integrantes durarán en sus funciones cuatro años. La Comisión Regional quedará integrada también por los Superintendentes elegidos en las Asambleas Distritales, de acuerdo a lo establecido en cuanto a su cantidad por cada Asamblea Regional.`},
                                {id:'',texto:`SEGUNDO – ELECCIONES:`},
                                {id:'',texto:`La elección de la Comisión Regional se llevará a cabo por los miembros activos nacionales y los adherentes del escalafón ministerial de la Fraternidad y los Delegados de las Iglesias Locales Autónomas de la región correspondiente, debidamente acreditados, que estén presentes en la Asamblea Regional Ordinaria.`},
                                {id:'',texto:`El procedimiento de la elección de funcionarios regionales, se procederá de la siguiente forma:`},
                                {id:'a',texto:`Podrán ser postulados los nombres de los posibles candidatos según lista que el Consejo Directivo Nacional presentará.\nLos candidatos deben estar presentes en la Asamblea, de no poder asistir deben enviar al Secretario Regional una carta explicando la causa justificada de su ausencia y su decisión de dejar su nombre o no como candidato. La decisión final la tomará la Asamblea, si permite postular a los candidatos no presentes.`},
                                {id:'b',texto:`Se elegirá en primer término el cargo de Presidente Regional quién deberá reunir la mitad más uno de los votos para ser electo. En caso de no haber elección después de la primera votación, será elegido en una nueva votación el que tenga mayor número de votos , entre los dos más votados.`},
                                {id:'c',texto:`Para el cargo de Vice-Presidente Regional, se elegirá en segundo término de la misma propuesta anterior, quién deberá reunir la mitad más uno de los votos para ser electo. En caso de no haber elección después de la primera votación, será elegido en un nueva votación el que obtenga el mayor número de votos, entre los dos más votados.`},
                                {id:'d',texto:`Para los cargos de Secretario Regional y Tesorero Regional se utilizará el mismo mecanismo antes mencionado en los puntos b) y c), pero presentando el nuevo voto de propuesta del Consejo Directivo Nacional.`},
                                {id:'e',texto:`Habrá un Comité de Escrutinio integrado por siete personas, presidido por el Secretario Regional e integrado por miembros activos nacionales y adherentes regionales, cuya función será:`},
                                {id:'1',texto:`Anotar en lugar visible los nombres de los candidatos.`},
                                {id:'2',texto:`Distribuir y recoger las boletas.`},
                                {id:'3',texto:`Contar los votos.`},
                                {id:'4',texto:`Presentar informe escrito del resultado de la votación a la presidencia, para el anuncio oficial a la Asamblea.`},
                                {id:'5',texto:`Conservar hasta el final de la Asamblea los datos de las votaciones.`},
                                {id:'f',texto:`Cualquier ministre que acepte postular su nombre está en la obligación de aceptar el nombramiento de que fuere objeto. Será optativo al ser electo, que el Presidente Regional deba abandonar, ceder temporariamente o definitivamente todo otro ministerio o pastorado que tuviere al momento de ser elegido. Se deja establecido que los dos nombres restantes que obtengan más votos por el sistema de simple mayoría, serán los miembros suplentes de la Comisión Regional. El resultado se comunicará en la primera circular a los Miembros Activos de la Fraternidad.`},
                                {id:'',texto:`VACANTES.`},
                                {id:'',texto:`Las posibles vacantes de los Titulares que se produjeran en el curso del mandato, se suplirá de la siguiente manera:`},
                                {id:'a',texto:`El cargo de Presidente por el Vice-Presidente; y los demás cargos por el ejecutivo regional inmediato inferior según fueron elegidos por la Asamblea Regional Ordinaria. Si alguno de estos ministros adoptara una dedicación exclusiva a nivel regional, la Asociación por intermedio de la Comisión Regional se compromete a: a) Asignarles una ayuda digna mensual acorde a las necesidades del ministro y a las posibilidades de la Fraternidad en la región correspondiente, y en ajuste a la Ley 24.475.`},
                                {id:'b',texto:`En caso que cesare su función se le otorgará un resguardo financiero por seis meses como máximo, o hasta que asuma otro pastorado o se le asigne un empleo administrativo dentro de laOficina Regional c) En el caso de cesación o destitución del cargo, la Asociación deberá cursar la notificación legal pertinente y de igual modo el miembro renunciante.`},
                                {id:'',texto:`TERCERO – ACEFALIA.`},
                                {id:'',texto:`Si la Comisión Regional perdiere su quórum mínimo, quedando reducido el mínimo de sus miembros a menos de la mitad más uno, a pesar de haber sido incorporados los suplentes, el Consejo Directivo Nacional a pedido de la Comisión Regional en minoría, deberá convocar dentro de los treinta días a partir de la fecha de producida la acefalía a una Asamblea Regional Extraordinaria en la que se elegirán los miembros para ocupar los cargos vacantes. Los mismos durarán en sus funciones hasta la terminación del mandato de los cesantes.`},
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XVI – ASAMBLEA REGIONAL ORDINARIA.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Se reunirá cada dos años dentro de los ciento veinte días posteriores al cierre del Ejercicio Financiero que se efectuará el 30 de Setiembre de cada año no debiendo coincidir nunca con el año de elección de la Junta Ejecutiva Nacional. Para que estas Asambleas sean válidas, serán presididas por el Presidente Nacional o quién éste designe y se requerirá la presencia física de la mitad más uno de los miembros activos nacionales y adherentes regionales del escalafón ministerial, de acuerdo al siguiente orden del día: `},
                                {id:'1',texto:`Lectura Bíblica y Oración.`},
                                {id:'2',texto:`Informe de la Comisión Regional que incluirá Memoria, Inventario, Balance General y Cuenta de Ganancias y Pérdidas correspondiente al Ejercicio Anual. Estos documentos deberán remitirse al Consejo Directivo Nacional para ser incluido en el Informe respectivo que se presenta en forma nacional a la Inspección General de Justicia. Los años en que no se realiza la Asamblea Regional Ordinaria la Comisión Regional deberá enviar dicho informe completo igualmente. `},
                                {id:'3',texto:`Informe de Comisiones Especiales.`},
                                {id:'4',texto:`Elección de los miembros de la Comisión Regional.`},
                                {id:'5',texto:`Elección de dos miembros suplentes.`},
                                {id:'6',texto:`Todo asunto que considere conveniente incluir la Comisión Regional con el aviso previo al Consejo Directivo Nacional o a pedido del 10% de los miembros activos nacionales residentes en la región con sesenta días de anticipación a la Región y ésta cuarenta y cinco días antes al Consejo Directivo Nacional.`},
                                {id:'7',texto:`Nombramiento de dos Ministros Ordenados concurrentes para que aprueben y firmen el Acta respectiva. 8) Nombramiento de un Ministro Ordenado y un delegado laico concurrentes, como Revisores de Cuentas, quienes revisarán la documentación presentada a la Asamblea Regional, firmando y efectuando un arqueo de caja. En caso de que a la primera reunión no asistiera el quórum mínimo de miembros, necesario para sesionar legalmente, según el punto anterior, tendrá lugar una segunda reunión transcurrida media hora de la fijada en la convocatoria, con los miembros activos nacionales y adherentes regionales que concurran, cualesquiera sea su número.`},
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XVII – CONVOCATORIA. `,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`La Asamblea Regional Ordinaria será convocada con un mes de anticipación por medio de citación escrita que hará el Secretario Regional previo acuerdo con el Consejo Directivo Nacional. Juntamente con la convocatoria, se hará llegar a cada miembro activo nacional de la región y a cada miembro adherente de la región el Orden del Día que se tratará en la Asamblea, no pudiendo ser considerados otros asuntos que no hayan sido incluidos en el mismo, excepto en el caso de un tema de urgencia en el cual se podrá abrir el punto Varios con la aprobación del 51% de los Miembros presentes.`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XVIII – ASAMBLEA REGIONAL EXTRAORDINARIA.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Se celebrará cada vez que sea convocada por el Consejo Directivo Nacional cuando lo estime necesario o a solicitud elevada a la misma por la Comisión Regional o pedido firmado por lo menos por el 51% de los Miembros Activos Nacionales de la Asociación residentes en la región correspondiente. El pedido deberá ser resuelto por el Consejo Directivo Nacional dentro de un plazo no mayor de veinte días. Se tratarán los asuntos que hubieren motivado su convocatoria y los que estime conveniente someter el Consejo Directivo Nacional. Regirán para esta Asamblea los requisitos establecidos en los artículos décimo segundo y décimo tercero en lo que fueren pertinentes. Deberá ser presidida por el Presidente Nacional o quién éste designe.`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XIX – DERECHO A VOZ Y VOTO Y DECISIONES.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Podrán ejercer el derecho a voz y voto en las Asambleas todos y cada uno de los miembros activos nacionales en las Asambleas Generales y así también los miembros adherentes regionales en las Asambleas Regionales que además de estar cumpliendo fielmente con los deberes inherentes a su Credencial, estén enviando sus diezmos a la Tesorería Central o a sus iglesias y que consten en el informe trimestral que envía la iglesia a la Sede Central. Si no han diezmado fielmente y/o si no han cumplido con sus obligaciones inherentes a su credencial, se le retendrán las mismas, pasarán a la Comisión de Disciplina. También tendrán derecho a voz y voto los delegados laicos que hayan sido fieles con sus obligaciones económicas en sus iglesias, nombrados a este solo efecto por las iglesias locales autónomas. Los mismos deberán ser personas responsables, mayores de edad y dentro de lo posible, suficientemente informados sobre el quehacer de la iglesia que representan, así como de la marcha de la Fraternidad. Para que las decisiones de cada Asamblea sean válidas se requiere la conformidad de la mayoría de los presentes, es decir la mitad más uno de los votos de los miembros presentes. Dichas decisiones deberán ser llevadas a la práctica en los plazos de tiempo indicados y por las personas o comisiones que correspondiere.`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XX – SEPARACION DE MIEMBROS.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Un miembro activo nacional o adherente puede dejar de pertenecer a la Unión de las Asambleas de Dios, por retiro voluntario, por inactividad o por expulsión. Cualquiera de estos casos, así como la posible readmisión de alguien al seno de la Fraternidad deberá ser considerado por la Comisión de Disciplina y posteriormente refrendado por el Consejo Directivo Nacional informando por carta circular a los demás miembros de la Fraternidad sobre la resolución final. La Comisión de Disciplina será integrada por los VicePresidentes Regionales siendo presidida por el Vice Presidente Nacional. En caso de que uno de los integrantes de la misma Comisión de Disciplina tuviera que ser considerado por el Presidente Regional correspondiente; en la situación que tuviera que ser reemplazado el Vice-Presidente Nacional, ocupará su lugar el Presidente Nacional quién será además el Presidente de esta Comisión. En caso que la Comisión de Disciplina tuviere número par en sus miembros, quien la presida tendrá doble voto.`}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XXI – DISOLUCION DE LA ASOCIACION.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`La Asamblea General Ordinaria no podrá decretar la disolución de la Asociación mientras haya ochenta miembros activos nacionales dispuestos a sostenerla. En caso de disolución de la Asociación, los bienes de la misma, una vez pagadas las deudas sociales, pasarán a una entidad civil, sin fines de lucro, con personería jurídica, y exenta, reconocida por la Dirección General Impositiva, de todo gravamen en el orden nacional, provincial y municipal, y con domicilio en el país; y si no la hubiera, a poder de la Nación, Provincia o Municipio. `}
                            ]
                        },
                        {
                            tituloGrande:null,
                            titulo:`ARTICULO XXII – MODIFICACION DE ESTATUTOS.`,
                            subtitulo:``,
                            grupo:1,
                            puntos:[
                                {id:'',texto:`Estos Estatutos no podrán ser modificados, suprimidos parcialmente, ampliados o adaptados a las reglamentaciones vigentes, sin el voto favorable de los dos tercios de los votos emitidos en una Asamblea convocada al efecto y constituida en primera convocatoria con la asistencia como mínimo del 51% de los socios, con derecho a voto y en segunda convocatoria con el quórum mínimo del veinte por ciento (20%).\nQueda establecido que la Asamblea General, Ordinaria y Extraordinaria, es el único cuerpo competente y con autoridad para realizar cualquiera de los cambios en los Estatutos anteriormente mencionados. Asimismo el Secretario deberá hacer conocer por escrito los cambios pertinentes a los Miembros Activos Nacional de la Asociación y a las Iglesias Autónomas de la Asociación, con tres meses de anticipación.`}
                            ]
                        }
   
        ]
}

export const afiliacion = {version:1,
    observaciones:``,
    titulo:'DECLARACIÓN DE AFILIACIÓN',
    subtitulo:``,
    fecha:'01/01/2023',
    secciones:[
        {
            tituloGrande:null,
            titulo:`Directivo Nacional de la Asociación, juntamente con el Secretario y Tesorero de la Iglesia Local creada/ reconocida como filial, declaran:`,
            subtitulo:``,
            grupo:1,
            puntos:[
                {id:'1',texto:`Que la mencionada Iglesia queda incorporada en este acto como filial de la Asociación UNIÓN DE LAS ASAMBLEAS DE DIOS, en la localidad mencionada, en razón de haber reunido y acreditado cumplir las condiciones generales estipulada en los Estatutos y Reglamento Administrativo Nacional.`},
                {id:'2',texto:`En tal carácter la Iglesia Filial elegirá sus propias autoridades internas, celebrará sus propias asambleas y umplirá con los Estatutos y Reglamentos de la Asociación.`},
                {id:'3',texto:`La Iglesia Filial dependerá y dispondrá de sus bienes y recursos propios. Los bienes inmuebles que fueran adquiridos por medio de aportes voluntarios de sus miembros, serán escriturados a nombre de la Asociación UNIÓN DE LAS ASAMBLEAS DE DIOS, de acuerdo con lo dispuesto en el art. VII de los Estatutos de la Asociación, que se ratifica en la presente.`},
                {id:'4',texto:`En lo relativo al movimiento financiero y patrimonial, las autoridades de la iglesia filial serán responsables de llevar los libros correspondientes. Asimismo cada 30 de septiembre deberá cerrar su ejercicio anual, preparado y enviando antes del 31 de octubre de cada año el Balance general e inventario detallado.`},
                {id:'5',texto:`La Filial utilizará el CUIT de la Asociación, a los efectos establecidos por la Administración Federal de Ingresos Públicos, asumiendo formalmente el compromiso de responsabilizarse en todo sentido por la coreccta utilización del CUIT. La sede Central le asignará a la Filial los puntos de emisión de comprobantes que sean necesarios, asumiendo ésta el formal compromiso de cumplimentar todas las disposiciones estatales sobre el particular.`},
                {id:'6',texto:`La Filial acepta utilizar y ser responsable de su presentación adecuada y ante quien debidamente corresponda, los reconocimientos de las exenciones que los diferentes organismos estatales han otorgado a la Asociación UNIÓN DE LAS ASAMBLEAS DE DIOS.`},
                {id:'7',texto:`Las autoridades locales de la filial asumen la responsabilidad de enviar a la Sede Central los fondos correspondientes al sostenimiento financiero de la Asociación, de acuerdo con lo previsto en los Estatutos de la misma.`},
                {id:'8',texto:`La Filial será responsable ante terceros, con todo su patrimonio, y en forma exclusiva y excluyente, liberado a la UNIÓN DE LAS ASAMBLEAS DE DIOS, de cualquier responsabilidd que pudiera presentarse como consecuencia de su funcionamiento. Las responsablilidades asumidas lo son en forma amplia, abarcando todas las que puedan generarse, ya sean fiscales, previsionales, civiles, penales o administrativas.`},
            ]}
        ]
}

export const compromiso_original = {version:1,
    observaciones:`La aceptación del presente compromiso ministerial será determinante para la renovación de las credenciales de ministro, la cual será
    indispensable para el ingreso de las asambleas que celebre la institución.`,
    titulo:'Actualización del compromiso Ministerial',
    subtitulo:'El ministro de la UAD asume los siguientes compromisos:',
    fecha:'17/07/2022',
    secciones:[{
        tituloGrande:null,
        titulo:'I. COMPROMISOS INSTITUCIONALES:',
        subtitulo:null,
        grupo:1,
        puntos:[
            {id:'1',
            texto:`Considera y acepta que se incorpora en este acto como ministro religioso de la UNIÓN DE LAS ASAMBLEAS DE DIOS,
            en razón de haber reunido y acreditado cumplir los requisitos establecidos en los Estatutos Nacionales, que se dan
            aquí por conocidos y reproducidos.`},
            {id:'2',
            texto:`Considera y acepta todas y cada una de las reglamentaciones, escritas en los Estatutos Nacionales, Reglamentos
            Administrativos, y toda otra reglamentación creada o por crearse que sea aprobada por la Asamblea General
            Nacional con o sin su consentimiento, mientras permanezca en la Asociación`},
            {id:'3',
            texto:`Considera y acepta reconocer, aceptar, apoyar y obedecer a las autoridades de la UNIÓN DE LAS ASAMBLEAS DE
            DIOS que la Asamblea correspondiente ha elegido con la guía de Dios, ya sea a nivel nacional, regional, distrital o
            seccional.
            `},
            {id:'4',
            texto:`Considera y acepta cumplir y hacer cumplir en su iglesia o ministerio todos los requisitos legales y contables exigidos
            por la Asociación y firmados en el Acta de Afiliación`},    
            {id:'5',
            texto:`Considera y acepta cumplir y hacer cumplir con el envío mensual a la Sede Central de los diezmos de su congregación
            y/o ministerio.
            `},
            {id:'6',
            texto:`Considera y acepta que ante toda discrepancia o diferencia que pudiera tener con la Asociación en el futuro lo
            dialogará solo con las autoridades pertinentes`},
            {id:'7',
            texto:`Considera y acepta poner todo el esfuerzo para que la unidad del Cuerpo de Cristo sea una realidad, primero en su
            Iglesia, luego en su Sección, Distrito y Región; para así alcanzar la verdadera unidad de la UNIÓN DE LAS ASAMBLEAS
            DE DIOS en toda la Argentina y en el mundo donde se encuentre uno de sus miembros. Aceptando a sus autoridades
            como dadas por Dios y comprometiendo su fidelidad a las mismas más allá de la opinión que pudiera tener.`},
            {id:'8',
            texto:`Considera y acepta reconocer los principios que la Biblia y los Reglamentos le enseñan que no debe transgredir y
            acepta toda corrección, disciplina y aún la separación de la UNIÓN DE LAS ASAMBLEAS DE DIOS si fallara a las
            mismas. Acepta delante de Dios que toda diferencia o controversia, sin importar el concepto que sea, será resuelto
            siempre dentro de la Fraternidad, según lo expresan las Sagradas Escrituras (Mt. 18:18-20)
            `},
            {id:'9',
            texto:`Considera y acepta trabajar con ahínco en toda responsabilidad que se le conceda dentro de la Organización.
            `},
            {id:'10',
            texto:`Considera y acepta servir a Dios como si siempre “caminara la segunda milla”, con gozo, responsabilidad, amor, fe y
            fidelidad al Señor.`},
            {id:'11',
            texto:`Considera y acepta que el espíritu contencioso, difamador y cualquier palabra o acción ofensiva y/o falta de respeto
            hacia los pares, los superiores o subalternos en el ministerio son expresiones carnales que atentan contra la unidad y
            armonía del cuerpo de Cristo y son susceptibles de sanción conforme a los procedimientos del Reglamento`},
            {id:'12',
            texto:`Considera y acepta que pertenecer a la UAD significa mantener íntegro el compromiso de colaborar con la
            organización, en especial en lo que los reglamentos señalan. Esto implica la presentación de balances, pago de
            diezmos y asistencia a sus asambleas, sean nacionales, regionales, distritales o de sección, como así también a
            cualquier otra convocatoria.
            `},
            {id:'13',
            texto:`Considera y acepta que ser parte de la Unión de las Asambleas, teniendo sus credenciales junto con los derechos,
            privilegios y responsabilidades significa no poder formar parte ni pertenecer a otra organización de iglesias.
            `},                                                                                                            
        ]},
    {
        tituloGrande:'II. COMPROMISOS ÉTICOS:',
        titulo:'1. Compromisos éticos en la familia.',
        subtitulo:null,
        grupo:2,
        puntos:[
            {id:'a',
            texto:`Considera y acepta su lugar y responsabilidad frente a su cónyuge e hijos, por lo cual se compromete en
            lo que de él dependa a tomar el tiempo para que ellos vean, acepten y amen a Dios por su intermedio con
            su ejemplo en palabra y vida. Hará de su hogar una casa de Dios, donde el Altar Familiar estará presente periódicamente.`},
            {id:'b',
            texto:`Considera y acepta que Dios creó sólo dos géneros (Hombre y Mujer) por lo que cualquier otra opción en
            identidad y/o práctica y/o elección sexual es artificiosa, antinatural y de origen pecaminoso, salvo
            anomalías genéticas comprobables por la ciencia médica.`},
            {id:'c',
            texto:`Considera y acepta que el matrimonio es de carácter vitalicio, y consiste en sólo dos personas de distinto
            género (Hombre y Mujer) que se unen para ser una sola carne, por lo que todo intento de separarlo
            carece de la aprobación divina. Asimismo, toda unión matrimonial que surja luego de una separación de
            hecho y/o divorcio vincular amerita el retiro de credenciales de la institución Unión de las Asambleas de
            Dios.
            `}, 
            {id:'d',
            texto:`Considera y acepta que el vínculo matrimonial se cultiva en un ambiente de amor y comprensión.
            Cualquier desarmonía afecta la estabilidad de la familia, la seguridad en los hijos y la eficiencia en el
            ministerio. El matrimonio demanda la exclusividad del uno para con el otro, por lo que cualquier vínculo
            sentimental o erótico hacia alguien ajeno al propio matrimonio o de un matrimonio ajeno es considerado
            adulterio.
            `}, 
            {id:'e',
            texto:`Considera y acepta que todo maltrato o daño que ocasione el cónyuge de un género al otro, sea físico,
            verbal o económico, como así también actitudes despóticas poseen un carácter antibíblico y descalifica a
            cualquiera que ostente el ministerio. En caso que el ministro incurra en dichas acciones, su caso será
            presentado a la Comisión de Disciplina para su tratamiento y resolución.`}, 
            {id:'f',
            texto:`Considera y acepta como ministro que cuando atraviese una crisis personal, familiar, económica o de
            salud debe informar a sus superiores a fin de otorgarles la oportunidad de asistirlos, proveyéndoles de
            medios y oportunidades de resolución de sus crisis, previniendo así cualquier situación de mayor
            envergadura o gravedad.
            `}, 
            {id:'g',
            texto:`Considera y acepta que las relaciones sexuales están santificadas en el matrimonio. Cualquier relación
            sexual fuera del lecho matrimonial se halla catalogada como adulterio o fornicación`}, 
            {id:'h',
            texto:`Considera y acepta que el uso de la pornografía es tan pecaminoso como cualquier relación sexual fuera
            del matrimonio.
            `}
        ]
    },
    {
        tituloGrande:null,
        titulo:'2. Compromisos éticos en la sociedad.',
        subtitulo:null,
        grupo:2,
        puntos:[
            {id:'a', 
            texto:`Considera y acepta que el carácter irascible, contencioso y autoritario de un ministro corresponden a la
            propia carnalidad del que lo demuestra y producen serias interferencias en el ejercicio del ministerio,
            dejando un mal ejemplo para sucesivas generaciones ministeriales.`},
            {id:'b',
            texto:`Considera y acepta que la palabra de un pastor debe corresponder en un ciento por ciento con la verdad
            y la realidad. La honestidad más que una virtud debe ser parte de la naturaleza de un siervo de Dios.
            Cualquier falta a la verdad es mentira, y por lo tanto pecado.`},
            {id:'c',
            texto:`Considera y acepta que todo ministro con credenciales que aspira al activismo partidario político debe
            abstenerse de ejercer liderazgo ministerial mientras ejerza esa función, para evitar así que en su radio de
            influencia ejerza voluntaria o involuntariamente algún tipo de proselitismo u ocasionar conflictos que
            atenten contra la comunión de los santos y la predicación del evangelio.
            `},
            {id:'d',
            texto:`Considera y acepta que la ingesta imprudente del alcohol como así también el consumo de toda
            substancia psicotrópica sin la debida prescripción médica, dañan el buen testimonio, desacreditan el
            ministerio y tienen efectos nocivos sobre el cristiano.
            `},
            {id:'e',
            texto:`Considera y acepta que todo trato, lujurioso, erótico o bien que afecte la integridad sexual de personas
            incapaces de defenderse, en especial menores de edad, no sólo es un grave delito que debe denunciarse,
            sino un pecado con serias consecuencias sobre las víctimas, el victimario mismo y su propio entorno.
            Asimismo, cualquier tipo de insinuación o acoso sexual valiéndose de la jerarquía eclesiástica que ostenta
            un ministro es condenable desde el punto de vista legal, ministerial y cristiano.`},
            {id:'f',
            texto:`Considera y acepta que el uso de las redes sociales aunque son de uso personal y privado, todas las
            publicaciones que se realizan en ellas son de recepción pública, por ende, la exposición de comentarios de
            índole político partidario, de lenguaje inapropiado, y/o imágenes indecorosas u obscenas, como así también los comentarios que se emiten con el fin de ofender, denigrar o insultar directa o indirectamente
            a una persona física o institución, serán tomadas como una falta a este compromiso moral de un
            ministro.
            `}
        ]
    },
    {
        tituloGrande:null,
        titulo:'3. Compromisos éticos en la administración del dinero.',
        subtitulo:null,
        grupo:2,
        puntos:[
            {id:'a',
            texto:`Considera y acepta llevar una vida ordenada en su administración financiera personal.
            Comprometiéndose a cumplir con todos los requisitos legales que sean necesarios. No manteniendo
            deudas que excedan sus posibilidades reales. Procurar aún con esfuerzo tener alguna obra social o
            servicio médico permanente que lo beneficie junto a su familia. Como así también adoptar algún sistema
            de aportes previsionales.
            `},
            {id:'b',
            texto:`Considera y acepta que el manejo de las finanzas de la iglesia es sagrado, y nunca un ministro de
            Jesucristo debe disponer de ellas para su propio beneficio, por lo cual todo préstamo del pastor hacia la
            iglesia o viceversa será hecho de manera prolija, mediante un acuerdo escrito bajo el consentimiento de,
            al menos dos oficiales de la iglesia.`},
            {id:'c',
            texto:`Considera y acepta que la morosidad reiterada o habitual son señal de desorden y generan un mal
            testimonio que daña cualquier función ministerial.
            `},
            {id:'d',
            texto:`Considera y acepta que un ministro del evangelio, debe ser cuidadoso y estar atento al origen de los
            fondos recibidos en la congregación, velando por la integridad que debe haber en un siervo de Dios y
            teniendo en cuenta los riesgos que conlleva cualquier posible ilícito.
            `}
        ]
    },
    {
        tituloGrande:null,
        titulo:'III. COMPROMISOS DOCTRINALES:',
        subtitulo:`Considera y acepta, creer, vivir, predicar, y enseñar todas y cada una de las doctrinas pertenecientes al canon de la
        Asociación detalladas a continuación:`,
        grupo:3,
        puntos:[
            {id:'1', 
            texto:`Las Sagradas Escrituras como la Palabra inspirada e infalible de Dios, superior a la conciencia y la razón (2 Timoteo
                3:15-16)`},
            {id:'2',
            texto:`El Único Dios verdadero, manifestado en tres personas: El Padre, el Hijo y el Espíritu Santo como la Deidad adorable.
            (1 Juan 5:7; Mateo 28:19; Marcos 12:29; 2 Corintios 13:14).
            `},
            {id:'3',
            texto:`La Deidad del Señor Jesucristo y su encarnación sobrenatural. (1 Juan 5:7; Mateo 28:19; Marcos 12:29; 2 Corintios
                13:14).`},
            {id:'4',
            texto:`Los ángeles como mensajeros y ministradores de Dios. (Éxodo 23:20, Jueces 2:1, Lucas 2:11, Hechos 7:38, Hebreos
                1:14).
                `},
            {id:'5',
            texto:`La Creación divina del Universo y del hombre, la santidad original, su caída y su redención. (Génesis 1:1,26, 2:17,
                3:1-7, Romanos 5:12-21).`},
            {id:'6',
            texto:`La Salvación por medio de la fe en la obra redentora de Jesucristo. (Lucas 24:47, Juan 3:3-16, Romanos 10:13-15,
                Efesios 2:8, Tito 2:11)`},
            {id:'7',
            texto:`La Santificación por la obra de Jesucristo, del Espíritu Santo y de la Biblia. (Juan 17:17, Romanos 12:1-2, 1ª
                Tesalonicenses 5:23, Hebreos 12:14, 13:12, 1ª Pedro 1:15-16).
                `},
            {id:'8',
            texto:`El Bautismo en el Espíritu Santo, como la Promesa del Padre, con la señal física inicial de hablar en otras lenguas.
            (Lucas 24:49, Juan 1:33, Hechos 2:4, 10:46, 1ª Corintios 12:4.10.28)`},
            {id:'9',
            texto:`La Sanidad Divina por la obra redentora de Cristo (Is. 53:4-5, Mateo 8:16-17, 1ª Pedro 2).`},
            {id:'10',
            texto:`El bautismo en aguas por inmersión y la Cena del Señor como ordenanzas de Cristo. (Mateo 28:19, Lucas 22:14-16,
                Romanos 6:4, 1ª Corintios 11:23-26, 2ª Pedro 1:4).
                `},
            {id:'11',
            texto:`La Iglesia como el Cuerpo Místico de Cristo; Los Ministerios divinamente ordenados y su misión evangelizadora.
            (Mateo 28:19-20, Marcos 3:13,14,, 16:15-20; Romanos 1:1; Efesios 1:22-23; 4:11-12; Hebreos 5:4; 12:23).`},
            {id:'12',
            texto:`La Resurrección de los redimidos y el arrebatamiento de la Iglesia antes de la tribulación. (Romanos 8:23; 1ª
                Tesalonicenses 1:10; 4:16-17; Tito 2:13)`},
            {id:'13',
            texto:`La Segunda Venida visible de Jesucristo para establecer su Reino Milenial en la tierra. (Zacarías 14:5; Mateo
                24:27-30; Romanos 11:26-27; 2ª Tesalonicenses 1:;7; Apocalipsis 20).
                `},
            {id:'14',
            texto:`El Lago de Fuego como castigo eterno para los impíos, Satanás y los ángeles caídos. (Apocalipsis 19:20; 20:10-15).`},
            {id:'15',
            texto:`El cielo como morada eterna de los salvos (Juan 14:2; 1ª Cor. 2:9-10; Apocalipsis 21:22).
            `},
            {id:'16',
            texto:`Los Cielos Nuevos y la Tierra Nueva (1ª Pedro 3:13; Apocalipsis 21:2).
            `},
        ]},
    {   tituloGrande:null,
        titulo:'Declaraciones finales',
        subtitulo:null,
        grupo:4,
        puntos:[
            {id:'', 
            texto:`El presente Compromiso Ministerial tiene vigencia y valor porque el firmante es un fiel creyente, buen siervo
            de Dios y digno ejemplo de un Ministro del Evangelio perteneciente a la UNIÓN DE LAS ASAMBLEAS DE DIOS.
            `},
            {id:'',
            texto:`Declaro mi acuerdo con los puntos arriba redactados y me comprometo a cumplirlos y a que, si experimentase
            algún conflicto en concepto, conducta, o alguna forma de pensar con éstos, podré no recibir o tener que
            entregar mis credenciales a mis autoridades hasta la aclaración o resolución del conflicto.
            `},
            {id:'',
            texto:`Declaro que los compromisos asumidos los realizo en pleno uso de mis facultades físicas y mentales, sin que
            medie coacción alguna, y no implicando los mismos ningún agravio o discriminación alguna hacia mi persona ni
            menoscabo de mis derechos`},
            {id:'',
            texto:`Cualquier transgresión o falta de cumplimento al presente compromiso firmado, derivará en un llamado de
            atención, una posible sanción y hasta la pérdida de forma temporal o definitiva las credenciales de esta
            organización.`},
            {id:'',
            texto:`La aceptación de la presente acta será determinante para la renovación de las credenciales de ministro, la cual será
            indispensable para el ingreso de las asambleas que celebre la institución.`},
        ]}]
}